import React, { useState } from "react";
import { Helmet } from "react-helmet";

const SchoolManagement = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>
          E-Learning App Development for School Management in Ahmedabad
        </title>
        <meta
          name="description"
          content="Professional e-learning app development for school management in Ahmedabad. Mannix Infotech Pvt. Ltd. creates interactive and engaging educational apps."
        />
        <meta
          name="keyword"
          content="E-learning app development services in Ahmedabad |
   Educational app development company in Ahmedabad |
     School management app development services |
    Expert e-learning app development services in Ahmedabad |
Top-rated e-learning app development company in Ahmedabad|
   E-learning app development services for educational institutions |
Ahmedabad e-learning app development experts|
    Educational app development solutions for schools|	
	E-learning app development company in Ahmedabad with expertise in mobile app development|
    E-learning app development services for online courses"
        />

        <link rel="canonical" href="https://www.mannix.in/schoolmanagement" />
      </Helmet>

      <section className="banner-content-1 pb-5">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className="position-absolute top-banner-bg"
          viewBox="0 0 1920 761"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
            </defs>
            <clipPath id="SVGID_2_">
              <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
            </clipPath>
            <g className="banner-bg-asw">
              <path
                className="st1"
                d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                        c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
              ></path>
              <path
                className="st2"
                d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                        c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
              ></path>
              <path
                className="st1"
                d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
              ></path>
              <path
                className="st2"
                d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
              ></path>
              <circle className="st1" cx="1536.8" cy="661.3" r="56.2"></circle>
              <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
            </g>
          </g>
        </svg>
        <div className="py-5 container">
          <div className="row align-items-center justify-content-around mt-4">
            <div className="col-lg-6">
              <div className="">
                <h1 className="head-title head-title-without-line m-0 py-3">
                  E-learning Application Development Services
                </h1>
                <p className="m-0 py-3">
                  About 30 to 35% of websites on the Internet, including dozens
                  of Fortune 500 companies. The powerful website builder and
                  robust content management opstem make the platform optimal
                  source so that about 54 out of 100 companies of Inc.5000 have
                  Wordpress integrated websites. Whether your goal is to
                  leverage your SEO social media strategies or open-source
                  platform for greater flexibility, freedom and overall higher
                  quality development. Easy to customize, it is an open-source
                  program that dominates over 20% of self-hosted websites.
                  Wordpress receives about 20 billion pages every month and is
                  still expanding. We implement best practices and build
                  feature-rich, highly functional websites that help owners
                  leverage their business to new heights. From small startups,
                  large corporations, B2B, B2C, we provide Wordpress solutions
                  that are fast and optimized for top-notch performances. Our
                  result drives project management is capable of offering
                  seamless communication and yield advantages in the advanced
                  corporate, internet-facing and hybrid environments.
                </p>
              </div>
            </div>
            <div className="col-lg-5 prod-section2">
              <div className="">
                <form
                  className="register-form"
                  id="register-form"
                  method="post"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="form_type"
                    id="form_type"
                    value="Android App Development"
                  />
                  <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <label className="w-100">
                          <span>Name</span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div className="w-100">
                        <label className="w-100">
                          <span>Email</span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email id *"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label className="w-100">
                        <span>Contact</span>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Contact No *"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Country</span>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Message</span>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter your message *"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        name="submit"
                        className="submit btn w-100 g-recaptcha-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section_e_learn clearfix mt-5 mb-5">
        <div className="dot-right clearfix">
          <div className="container">
            <h3 className="main_head_elearn text-center">
              Top Online E-learning Application Development Services
            </h3>
            <p className="main_head_elearn_p text-center">
              Mannix Infotech provides E-Learning app development solutions that
              features user app for IOS &amp; humanoid platforms, net
              applications and code development services. From eLearning mobile
              app style to development and deployment, we offer an encompassing
              academic app solutions for college students and teachers, aboard
              an e Learning web app solutions for secured admin panel. Boost
              your business sales and range of registered students with our
              leading e-Learning mobile app development services.
            </p>
          </div>
        </div>
      </section>

      <section className="best_cross_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="best-cr-img">
                {" "}
                <img
                  src="../Images/E-learningApplicationDevelopment.png"
                  alt="E-learningApplicationDevelopment"
                  className="img-fluid"
                />{" "}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="best_cr_text">
                <div className="theme-title">
                  {" "}
                  <h6>
                    <strong>E-Learning App Admin Panel</strong>
                  </h6>
                  <p>
                    A fully integrated &amp; secure admin panel for managing all
                    business aspects of your E-Learning requirements.
                  </p>
                </div>
              </div>
              <div>
                <ul className="list-style-blt">
                  <li>Dashboard Panel</li>
                  <li>Category Management</li>
                  <li>User Management</li>
                  <li>Order Management</li>
                  <li>Coupon Management</li>
                  <li>Content Management</li>
                  <li>Push Notification</li>
                  <li>Staff Management</li>
                  <li>Role Management</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="best_cross_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="best_cr_text">
                <div className="theme-title">
                  {" "}
                  <h6>
                    <strong>E-Learning App For Teachers</strong>
                  </h6>
                </div>
              </div>
              <div>
                <ul className="list-style-blt">
                  <li>Registartion</li>
                  <li>Manage Account Settings And Profile</li>
                  <li>Schedule Live classNamees</li>
                  <li>Push Notification</li>
                  <li>View Reviews And Feedback</li>
                  <li>Answer Queries Of Students</li>
                  <li>Interact To Students Via Text, Audio, Or Video</li>
                  <li>Post Assignments And Deadlines For Students</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="best-cr-img">
                {" "}
                <img
                  src="../Images/LearningApp.png"
                  alt="E-LearningApp"
                  className="img-fluid"
                />{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="company-offer2 theme_sec">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <div className="theme-title theme_web">
                  <h3 className="Head_color">
                    A Best E-learning App Development Company Provides You
                  </h3>
                  <p style={{ color: "white" }}>
                    Being a putative E-Learning app development company in
                    India, handling world shoppers has helped us perceive the
                    assorted views that require to be thought-about for making
                    top-notch E-Learning app solutions. rent best-in-className
                    E-Learning app developers from Aglowid that bring forward
                    the subsequent edges to your online-Learning app project –
                  </p>
                  <ul className="list-style-blt">
                    <li> Quick Technical Support</li>
                    <li> A Team Of 50+ developers. </li>
                    <li> Packages that do burn a hole in your pocket.</li>
                    <li> Complete walk around of your project.</li>
                    <li> On or before time delivery.</li>
                  </ul>
                  <p style={{ color: "white" }}>
                    Simply, contact North American country nowadays and receive
                    budget friendly eLearning app development services from us.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="theme-title theme_solution">
                  <h4 className="Head_color">
                    Why to develop an E-Learning Apps?
                  </h4>
                  <p>
                    With the ever-growing call for for on-call for apps,
                    E-Learning apps have emerge as broadly popular. The
                    schooling area is hastily moving in the direction of a web
                    Learning structure. The want for powerful E-Learning app
                    improvement offerings is clear to live in advance of the
                    various rising competition withinside the field. Here are a
                    few advantages of E-Learning apps you would possibly need to
                    consider -
                  </p>
                  <ul className="list-style-blt">
                    <li> Easy Student &amp; Teacher Management</li>
                    <li> Hassle-free Subscription Management</li>
                    <li> Enhance Users Loyalty</li>
                    <li> Can be Personalized to Users.</li>
                    <li>Boost Sales &amp; Visibility</li>
                    <li> Seamless Subscription Module</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SchoolManagement;
