import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = "919664053983";
    const message = "Hello! Can I Get More Information About Your Business!";
    const encodedMessage = encodeURIComponent(message);

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    window.open(whatsappUrl, "_blank");
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    requirement: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_hgumumr",
        "template_du90xea",
        e.target,
        "vSEIQtm7Ln61vu_Tu"
      )
      .then((result) => {
        // console.log(result.formData);
        alert("Message sent successfully!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          country: "",
          requirement: "",
        });
      })
      .catch((error) => {
        console.error(error.text);
        alert("Failed to send message. Please try again later.");
      });
  };

  return (
    <>
      <Helmet>
        <title>
          Contact Us for Web Design & Marketing in Ahmedabad | Mannix Infotech
          solutions Pvt. Ltd.
        </title>
        <meta
          name="description"
          content="Contact us for expert web design and marketing services in Ahmedabad. Mannix Infotech offers tailored solutions to enhance your online presence and business growth."
        />
        <meta
          name="keyword"
          content="Contact Mannix Infotech Solutions Pvt Ltd for IT Services in Ahmedabad | Get in Touch with Mannix Infotech Solutions Pvt Ltd for Digital Marketing | Reach Out to Mannix Infotech Solutions Pvt Ltd for Web Development Inquiries |Mannix Infotech Solutions Pvt Ltd Contact Information for E-Commerce Solutions |Contact Mannix Infotech Solutions Pvt Ltd for Custom Software Development|Mannix Infotech Solutions Pvt Ltd Customer Support and Contact Details|How to Contact Mannix Infotech Solutions Pvt Ltd for Business Consulting|Mannix Infotech Solutions Pvt Ltd Address and Phone Number for IT Services|Contact Mannix Infotech Solutions Pvt Ltd for Cloud Computing and AI Solutions "
        />

        <link rel="canonical" href="https://www.mannix.in/contact" />
      </Helmet>

      <div className="mb-5">
        <div className="" style={{ position: "relative" }}>
          <section className="tp text-white">
            <img
              src="../ContactBanner.jpg"
              alt="ContactBanner"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
            <div className="">
              <h1 className="text-center">
                <b style={{ fontSize: "55px" }}>Contact Us</b>
              </h1>
              <h3
                className="text-center"
                style={{ fontSize: "27px", margin: "15px 0 25px" }}
              >
                <b>We are just a click away</b>
              </h3>
            </div>
          </section>
          <div
            className="container bg-white border rounded text-center shadow position-relative p-2"
            style={{ width: "90%", bottom: "150px", zIndex: 1 }}
          >
            <div className="ops_contactUs_happy_answer_content p-3">
              <span>
                <img
                  className="lazyloaded"
                  style={{ filter: "brightness(0.5)" }}
                  height="50"
                  width="50"
                  src="../contacticon.png"
                  alt="contacticon"
                />
              </span>
              <h2>
                We will be happy to answer your questions
                <br /> <span className="fs-4">Let's Talk</span>
              </h2>
            </div>
          </div>
          <div className="text-center">
            <button
              className="px-5 p-3 border-0 rounded position-relative fs-5"
              style={{
                top: "-160px",
                backgroundColor: "rgb(232, 65, 24)",
                color: "white",
              }}
              onClick={handleWhatsAppClick}
            >
              Chat With Us <i className="fa-brands fa-whatsapp fs-2 p-2"></i>
            </button>
          </div>
        </div>

        <div className="container" style={{ marginTop: "-80px" }}>
          <div className="row">
            <div className="col">
              <div className="bg-light p-5 mx-2">
                <form className="bg-light" onSubmit={handleSubmit}>
                  <h2>Get in Touch With Us</h2>
                  <div className="mb-3 mt-5">
                    <label
                      htmlFor="exampleInputName"
                      className="form-label fw-bold"
                    >
                      Your Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName"
                      placeholder="Enter Name"
                      aria-describedby="emailHelp"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label fw-bold"
                    >
                      Your Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Enter Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPhone"
                      className="form-label fw-bold"
                    >
                      Your Contact No
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="exampleInputPhone"
                      placeholder="Enter phone no"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputCountry"
                      className="form-label fw-bold"
                    >
                      Your Country
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputCountry"
                      placeholder="Enter country name"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputRequirement"
                      className="form-label fw-bold"
                    >
                      Your Requirements
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputRequirement"
                      placeholder="Enter your requirements"
                      name="requirement"
                      value={formData.requirement}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn"
                    style={{
                      color: "white",
                      background: "rgb(232, 65, 24)",
                      marginTop: "10px",
                      width: "50%",
                      letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
            <div className="col">
              <div className="">
                <div style={{ width: "100%", marginTop: "15px" }}>
                  <iframe
                    title="Google Map"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14685.140189616688!2d72.533273!3d23.050009!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e8548f42c4dd9%3A0x4d896da64b285250!2sMannix%20Infotech%20Solutions%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1718107571504!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    style={{ border: "0", overflowX: "hidden" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
                <div className="p-1 bg-light d-flex justify-content-around p-3">
                  <div className="text-center bg-white">
                    <h3
                      className="p-1 text-center"
                      style={{
                        fontSize: "16px",
                        backgroundImage:
                          "linear-gradient(to right, #db150e, #f6e58d)",
                        color: "white",
                      }}
                    >
                      Sales Enquiries
                    </h3>
                    <div className="p-2">
                      <a
                        className=""
                        href="mailto:info@mannix.in"
                        style={{ textDecoration: "none", color: "black" }}
                        title="mailto"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../sales.png"
                          width="40px"
                          alt="Sales Icon"
                          style={{ filter: "brightness(0.5)" }}
                        />

                        <p className="m-1">info@mannix.in</p>
                      </a>
                      <a
                        href="tel:+91 9664053983"
                        className="text-decoration-none text-dark"
                        title="phone"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p>+91 9664053983</p>
                      </a>
                    </div>
                  </div>
                  <div className="text-center bg-white">
                    <h3
                      className="p-1 text-center"
                      style={{
                        fontSize: "16px",
                        backgroundImage:
                          "linear-gradient(to right, #db150e, #f6e58d)",
                        color: "white",
                      }}
                    >
                      Job Enquiries
                    </h3>
                    <div className="p-2">
                      <a
                        className=""
                        href="mailto:hr@mannix.in"
                        style={{ textDecoration: "none", color: "black" }}
                        title="mailto"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../job.png"
                          width="50px"
                          alt="job Icon"
                          style={{ filter: "brightness(0.5)" }}
                        />
                        <p className="m-1">hr@mannix.in</p>
                      </a>
                      <a
                        href="tel:+91 9664053983"
                        className="text-decoration-none text-dark"
                        title="phone"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p>+91 9664053983</p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="bg-light d-flex justify-content-center gap-3 fs-5 p-3">
                  <div>
                    <ul className="d-flex flex-wrap gap-3">
                      <li style={{ listStyle: "none" }}>
                        <a
                          href="https://www.facebook.com/profile.php?id=61558304945053"
                          target="_blank"
                          rel="noreferrer"
                          title="Facebook"
                          style={{
                            padding: "9px 8px",
                            display: "block",
                            fontSize: "17px",
                            fontWeight: "300",
                            color: "#fff",
                            borderRadius: "10px",
                            background: "#2980b9",
                            width: "36px",
                            height: "36px",
                          }}
                        >
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <a
                          href="https://x.com/mediatech09?t=x-kv93jEG5y0aqezPQ02qw&s=08"
                          target="_blank"
                          rel="noreferrer"
                          title="Twitter"
                          style={{
                            padding: "9px 8px",
                            display: "block",
                            fontSize: "17px",
                            fontWeight: "300",
                            color: "#fff",
                            borderRadius: "10px",
                            background: "#2980b9",
                            width: "36px",
                            height: "36px",
                          }}
                        >
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                      </li>

                      <li style={{ listStyle: "none" }}>
                        <a
                          href="https://www.instagram.com/mannixinfotech09/?fbclid=IwAR30ORrOy8gHLBnmw9Nys9CZNYznRUYLyiAXihrlzLH63aVD_y96syb0jJo"
                          target="_blank"
                          rel="noreferrer"
                          title="Instagram"
                          style={{
                            padding: "9px 8px",
                            display: "block",
                            fontSize: "17px",
                            fontWeight: "300",
                            color: "#fff",
                            borderRadius: "10px",
                            background: "#2980b9",
                            width: "36px",
                            height: "36px",
                          }}
                        >
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <a
                          href="https://www.linkedin.com/company/mannix-infotech-solutions-pvt-ltd/"
                          target="_blank"
                          rel="noreferrer"
                          title="LinkedIn"
                          style={{
                            padding: "9px 8px",
                            display: "block",
                            fontSize: "17px",
                            fontWeight: "300",
                            color: "#fff",
                            borderRadius: "10px",
                            background: "#2980b9",
                            width: "36px",
                            height: "36px",
                          }}
                        >
                          <i className="fa-brands fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
