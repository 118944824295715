import React from "react";
import { Helmet } from "react-helmet";

const Productslowmotionvideography = () => {
  return (
    <>
      <Helmet>
        <title>Best eCommerce Slow Motion Videography in Ahmedabad</title>
        <meta
          name="description"
          content="Stunning slow-motion videography for e-commerce and corporate needs in Ahmedabad, India. Serving the UK, US, Canada, and Australia with top-notch services."
        />
        <meta
          name="keyword"
          content="eCommerce slow motion videography services Ahmedabad |
 best slow motion product videography for eCommerce |
top slow motion videography for online store Ahmedabad |
 professional product slow motion video Ahmedabad |
creative slow motion eCommerce videography Ahmedabad | 
high-quality slow motion product videos Ahmedabad |
affordable eCommerce slow motion videography Ahmedabad |
custom slow motion videos for eCommerce Ahmedabad |
eCommerce product slow motion video experts Ahmedabad |
best slow motion videography for eCommerce products Ahmedabad"
        />

        <link
          rel="canonical"
          href="https://www.mannix.in/product-slow-motion-videography"
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <p className="fs-2 fw-bold mt-5">We’re Creative Studio</p>
            <p className=" fs-5">
              Based in New Delhi, India, we specialize in creative commercial
              photography and product styling, focusing on unique and playful
              concepts.
            </p>
            <p className="fs-5">
              In today's competitive digital landscape, captivating visuals are
              essential to capture your audience's attention. With consumers
              constantly bombarded with imagery, ads, and content, every brand
              has a story to tell. We make sure, yours is the one being heard!
            </p>
            <p className="fs-5">
              So, If you lack the props, creative styling skills, or simply the
              time, we bring our expertise to the table to make your visuals
              shine. Equipped with a studio brimming with props, top-notch gear,
              and diverse backdrops, we're prepared to transform your creative
              visions into reality.
            </p>
          </div>
          <div className="col-md-6 pt-5">
            <video
              controls
              autoPlay
              loop
              muted
              playsInline
              preload="metadata"
              className="video"
              style={{ height: "500px", width: "100%", pointerEvents: "none" }}
            >
              <source src="/Images/adversting.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div>
          <p className="fs-3 text-center m-3 mt-5">Our services</p>

          <div className="row d-flex">
            <div
              className="col-12 col-md-6 mb-4 d-flex"
              style={{ width: "36rem" }}
            >
              <div className=" p-4 h-100  bg-light">
                <p className="fs-5 font-semibold">Product Photography</p>
                <div className="border-top border-dark w-25 mt-2"></div>

                <p className="pt-3">
                  From conventional product photography to innovative e-commerce
                  visuals, we animate your products through advanced set styling
                  and professional retouching.
                </p>
                <h6 className="wptb-item--count text-outline">01</h6>
              </div>
            </div>

            <div
              className="col-12 col-md-6 mb-4 d-flex"
              style={{ width: "36rem" }}
            >
              <div className=" p-4 h-100 bg-light">
                <p className="fs-5 font-semibold">Food Photography</p>
                <div className="border-top border-dark w-25 mt-2"></div>
                <p className="pt-3">
                  Delight your audience's senses with our captivating food and
                  beverage photography. Whether you're presenting culinary
                  masterpieces for a menu, cookbook, or social media platform,
                  our skilled team excels at making food appear utterly
                  irresistible.
                </p>
                <h6 className="wptb-item--count text-outline">02</h6>
              </div>
            </div>

            <div
              className="col-12 col-md-6 mb-4 d-flex"
              style={{ width: "36rem" }}
            >
              <div className=" p-4 h-100 bg-light">
                <p className="fs-5 font-semibold">Model Photography</p>
                <div className="border-top border-dark w-25 mt-2"></div>
                <p className="pt-3">
                  Our model photography services encapsulate the essence of
                  beauty, meticulously crafted to highlight your products with a
                  human touch. From selecting models to coordinating with hair
                  and makeup artists, we guarantee perfection in every detail.
                </p>
                <h6 className="wptb-item--count text-outline">03</h6>
              </div>
            </div>

            <div
              className="col-12 col-md-6 mb-4 d-flex"
              style={{ width: "36rem" }}
            >
              <div className=" p-4 h-100 bg-light">
                <p className="fs-5 font-semibold">
                  Videography and Stop Motion
                </p>
                <div className="border-top border-dark w-25 mt-2"></div>
                <p className="pt-3">
                  From lighting to styling, we create captivating videos and
                  stop motions that highlight the beauty and functionality of
                  your products.
                </p>
                <h6 className="wptb-item--count text-outline">04</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Productslowmotionvideography;
