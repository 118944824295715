import React, { useState } from "react";
import { Helmet } from "react-helmet";

const DyanamicDevlopment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>
          {" "}
          Dynamic Website Development in Ahmedabad | Mannix Infotech Pvt. Ltd.
        </title>
        <meta
          name="description"
          content="Explore dynamic website development services in Ahmedabad with Mannix Infotech Pvt. Ltd.. We design interactive and scalable websites to enhance user engagement and functionality.
"
        />
        <meta
          name="keywords"
          content="Dynamic website development services in Ahmedabad | Best dynamic website development company in Ahmedabad | Interactive website development agency | Scalable website design and development services | Dynamic website development for business in Ahmedabad | Professional dynamic website development services |Expert dynamic website development company in Ahmedabad | Dynamic website development solutions in Ahmedabad | Top-rated dynamic website development services in Ahmedabad | Custom dynamic website development company in Ahmedabad 
"
        />
        <link
          rel="canonical"
          href="https://www.mannix.in/dyanamic-website-development-services"
        />
      </Helmet>
      <div className="hire-dedicated services mt-0">
        <section className="banner-content-1">
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            className="position-absolute top-banner-bg"
            viewBox="0 0 1920 761"
          >
            <g>
              <defs>
                <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
              </defs>
              <clipPath id="SVGID_2_">
                <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
              </clipPath>
              <g className="banner-bg-asw">
                <path
                  className="st1"
                  d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                        c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
                ></path>
                <path
                  className="st2"
                  d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                        c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
                ></path>
                <path
                  className="st1"
                  d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
                ></path>
                <path
                  className="st2"
                  d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
                ></path>
                <circle
                  className="st1"
                  cx="1536.8"
                  cy="661.3"
                  r="56.2"
                ></circle>
                <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
              </g>
            </g>
          </svg>
          <div className="py-5 container">
            <div className="row align-items-center justify-content-around mt-4">
              <div className="col-lg-6">
                <div className="">
                  <h1 className="head-title head-title-without-line m-0 py-3">
                    Dynamic Web Design Service
                  </h1>
                  <p className="m-0 py-3">
                    We at Mannix Infotech Solutions Pvt.Ltd., offers high end
                    dynamic websites to our clients at most affordable price. To
                    understand the user- friendly website with complete and
                    additional functionality, we provide custom and Creative
                    Website Dynamic Design Services. We create the best
                    customizable interface sites by using advanced programming
                    languages like PHP, ASP.net, etc. Our skilled group of web
                    developers is proficient in creating and maintaining
                    websites for many industry sectors.
                  </p>
                  <p className="m-0 py-3">
                    Our dynamic website services let you effectively access the
                    control for making all types of changes such as simple to
                    manage, profoundly adaptable, full site control upheld by
                    all web indexes. No matter, what’s your business? Dynamic
                    website is one of the best choices available in the market
                    when it comes to developing your business online presence.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 prod-section2">
                <div className="">
                  <form
                    className="register-form"
                    id="register-form"
                    method="post"
                    action=""
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="hidden"
                      name="form_type"
                      id="form_type"
                      value="Android App Development"
                    />
                    <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                      <div className="d-flex">
                        <div className="w-100 mr-2">
                          <label className="w-100">
                            <span>Name</span>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Name *"
                              className="form-control"
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                        <div className="w-100">
                          <label className="w-100">
                            <span>Email</span>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email id *"
                              className="form-control"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <label className="w-100">
                          <span>Contact</span>
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="Contact No *"
                            className="form-control"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Country</span>
                          <input
                            type="text"
                            name="country"
                            id="country"
                            placeholder="Enter your Country"
                            className="form-control"
                            value={formData.country}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Message</span>
                          <textarea
                            name="message"
                            id="message"
                            placeholder="Enter your message *"
                            className="form-control"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </label>
                      </div>

                      <div className="">
                        <button
                          type="submit"
                          name="submit"
                          className="submit btn w-100 g-recaptcha-btn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="hire-dedicated services pt_90">
        <div className="container">
          <div className="company-offer2">
            <div className="container pb-5">
              <div className="theme-title padd_lr">
                <h3>Dynamic Web Design: Key of Success</h3>
                <p className="text-justify">
                  With the Full Functionally Website, you can easily get the
                  straightforward approach at once that how to implement updates
                  across multiple pages. If you are assuming that you need to
                  roll out the improvement to your site's navigation, you have
                  to do simply roll out the improvement at one place and as
                  result, it would reflect every one of the pages which contain
                  the document. And all this happens with the help of Dynamic
                  Website. And you can also manage and post a wide range of
                  products and services without any specific knowledge and
                  details. Custom powerful websites are valued broadly because
                  of the great extensible of provisions. Huge numbers of
                  companies are working with Dynamic Web Designs because it
                  provides independent management, supported search engines that
                  are very cost-effective, and open a wide range of modules in
                  the form of blogs, e-commerce, secure login, forum, articles,
                  SEO elements, etc.
                </p>
              </div>
            </div>
          </div>
        </div>

        <section className="pt-5 pb-90">
          <div className="hire-dedicated-2 container">
            <div className="pb-4 text-center">
              <h2 className="head-title">
                Why Choose Dynamic Web Design Services?
              </h2>
            </div>
            <div className="row">
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-bars"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">User Friendly Website Design</h2>
                      <p className="m-0 py-2">
                        Web designing is a common part of being online, every
                        single thing you will do on the net will be done through
                        the web browser and one of the main reasons for website
                        success will be its usability. The user-friendliness is
                        done keeping this in mind, the easy accessibility and
                        navigation of a website should also be sufficient.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-bars"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fab fa-searchengin"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">
                        Easy to Manage and Find Information
                      </h2>
                      <p className="m-0 py-2">
                        Search for your specific keywords on Google, Yahoo,
                        Bing, and MSN and you will see thousands of entries that
                        fit your search criteria. This is the one-stop shop for
                        any kind of online search or research.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fab fa-searchengin"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-child"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">
                        Supported Search Engine Optimized
                      </h2>
                      <p className="m-0 py-2">
                        The key to a successful and long-term search engine
                        optimization success is to optimize the overall website
                        to become more beneficial and user-friendly for
                        visitors.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-child"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-chart-line"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">Social Media Integration</h2>
                      <p className="m-0 py-2">
                        Online marketing is now being used in multiple ways like
                        social media marketing and Google analytics. They are
                        much more interactive and are coming up with online
                        marketing tools as the internet revolution has advanced
                        so far.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-chart-line"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DyanamicDevlopment;
