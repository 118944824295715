import React, { useState } from "react";
import { Helmet } from "react-helmet";

const EcommerceDevelopment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>
          E-commerce Development Services in Ahmedabad | Mannix Infotech Pvt.
          Ltd.
        </title>
        <meta
          name="description"
          content="Top e-commerce development services in Ahmedabad by Mannix Infotech Pvt. Ltd. We build secure, scalable online stores to enhance your business’s digital presence.
"
        />
        <meta
          name="keywords"
          content="E-commerce development services in Ahmedabad | Best e-commerce web development company in Ahmedabad | Secure e-commerce development services | Scalable e-commerce website development agency | Custom e-commerce platform development services | Expert e-commerce development services in Ahmedabad | Top-rated e-commerce web development services in Ahmedabad | E-commerce website development for business | Online store development services in Ahmedabad | Ahmedabad e-commerce development experts
"
        />
        <link
          rel="canonical"
          href="https://www.mannix.in/e-commerce-development-services"
        />
      </Helmet>
      <div className="hire-dedicated services mt-0">
        <section className="banner-content-1">
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            className="position-absolute top-banner-bg"
            viewBox="0 0 1920 761"
          >
            <g>
              <defs>
                <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
              </defs>
              <clipPath id="SVGID_2_">
                <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
              </clipPath>
              <g className="banner-bg-asw">
                <path
                  className="st1"
                  d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                        c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
                ></path>
                <path
                  className="st2"
                  d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                        c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
                ></path>
                <path
                  className="st1"
                  d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
                ></path>
                <path
                  className="st2"
                  d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
                ></path>
                <circle
                  className="st1"
                  cx="1536.8"
                  cy="661.3"
                  r="56.2"
                ></circle>
                <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
              </g>
            </g>
          </svg>
          <div className="py-5 container">
            <div className="row align-items-center justify-content-around mt-4">
              <div className="col-lg-6">
                <div className="">
                  <h1 className="head-title head-title-without-line m-0 py-3">
                    Ecommerce Website Development
                  </h1>
                  <p className="m-0 py-3">
                    We contribute to higher client satisfaction, a larger
                    audience, and higher revenues. We guarantee that an
                    excellent, strong approach and the best cutting-edge
                    development technologies are used in the creation of your
                    website.
                  </p>
                  <p className="m-0 py-3">
                    In this cutthroat industry, every company needs to stay on
                    the cutting edge. It requires skilled, enterprise-grade,
                    bespoke web and mobile app development services for
                    e-commerce. At Mannix Infotech Solutions Pvt.Ltd., we
                    leverage cutting-edge technologies in conjunction with our
                    expertise in e-commerce development to identify the critical
                    needs of clients in a variety of global industries.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 prod-section2">
                <div className="">
                  <form
                    className="register-form"
                    id="register-form"
                    method="post"
                    action=""
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="hidden"
                      name="form_type"
                      id="form_type"
                      value="Android App Development"
                    />
                    <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                      <div className="d-flex">
                        <div className="w-100 mr-2">
                          <label className="w-100">
                            <span>Name</span>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Name *"
                              className="form-control"
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                        <div className="w-100">
                          <label className="w-100">
                            <span>Email</span>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email id *"
                              className="form-control"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <label className="w-100">
                          <span>Contact</span>
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="Contact No *"
                            className="form-control"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Country</span>
                          <input
                            type="text"
                            name="country"
                            id="country"
                            placeholder="Enter your Country"
                            className="form-control"
                            value={formData.country}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Message</span>
                          <textarea
                            name="message"
                            id="message"
                            placeholder="Enter your message *"
                            className="form-control"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </label>
                      </div>

                      <div className="">
                        <button
                          type="submit"
                          name="submit"
                          className="submit btn w-100 g-recaptcha-btn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="cont-desc-bx">
        <img className="left-img" src="../Images/leftdot.png" alt="leftdot" />
        <img
          className="right-img"
          src="../Images/rightdot.png"
          alt="rightdot"
        />
        <div className="container">
          <div className="desk-list-bx">
            <div className="hed-cent-bx">
              <h2 className="head-center fw-bold">
                E-commerce development services
              </h2>
            </div>
            <p className="text-justify">
              We contribute to higher client satisfaction, a larger audience,
              and higher revenues. We guarantee that an excellent, strong
              approach and the best cutting-edge development technologies are
              used in the creation of your website.
            </p>
            <p className="text-justify">
              In this cutthroat industry, every company needs to stay on the
              cutting edge. It requires skilled, enterprise-grade, bespoke web
              and mobile app development services for e-commerce. At Mannix
              Infotech Solutions Pvt.Ltd., we leverage cutting-edge technologies
              in conjunction with our expertise in e-commerce development to
              identify the critical needs of clients in a variety of global
              industries.
            </p>
          </div>
        </div>
      </section>
      <section className="why_choose_online why_choose_blockchain pt-5">
        <div className="container">
          <div className="why_choose_title why_choose_us fw-bold">
            What our e-commerce web development offers
          </div>
          <p className="huge_text">
            A wide range of e-commerce website development services make us a
            unique choice of clients.
          </p>
          <div className="row">
            <div className="col-sm-3">
              <div className="choose_food">
                <h3>Strategy and Consulting for E-Commerce</h3>
                <p className="text-justify">
                  Uncertain About How To Get Your Company Into The Internet
                  Domain? In order to provide you with the most individualized
                  solution for your e-commerce business, we also offer strategy
                  and consulting services for e-commerce development.
                </p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="choose_food">
                <h3>Personalized e-commerce design</h3>
                <p className="text-justify">
                  Our skilled UI/UX designers combine creativity, innovation,
                  conversion, and content to produce masterpieces that will make
                  your online business stand out from the competition and
                  provide the greatest possible user experience.
                </p>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="choose_food">
                <h3>Development of E-Commerce Websites</h3>
                <p className="text-justify">
                  Our e-commerce website solutions are designed to improve the
                  speed, dependability, security, and scalability of online
                  enterprises. With years of experience, we have developed
                  e-commerce websites that cater to both consumer and industry
                  trends.
                </p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="choose_food">
                <h3>Optimization of e-commerce websites</h3>
                <p className="text-justify">
                  Just creating an e-commerce website is insufficient. To
                  maximize the benefits of the online store, optimization is
                  essential. Our e-commerce website building services provide
                  optimal performance through top-notch enhancements.
                </p>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="choose_food">
                <h3>Migration of e-commerce platforms</h3>
                <p className="text-justify">
                  Do you not like the e-commerce platform you are using now? We
                  can assist you with moving your e-commerce website to the most
                  effective platform possible, ensuring zero downtime and
                  seamless integration.
                </p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="choose_food">
                <h3>Tailored transportation options</h3>
                <p className="text-justify">
                  Require adaptable methods of payment? Do you wish to provide
                  framing and quick payment options? Immediately integrate your
                  most well-liked payment gateway and expand to meet increasing
                  demand.
                </p>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="choose_food">
                <h3>Better order fulfillment options</h3>
                <p className="text-justify">
                  With our excellent order management systems, you may enjoy
                  error-free order management. We ensure that your e-commerce
                  website functions properly even under stress, whether it be
                  with regard to product returns or customized product delivery
                  demands.
                </p>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="choose_food">
                <h3>Cloud solutions and scalable architecture</h3>
                <p className="text-justify">
                  One location wasn't intended for your e-commerce enterprise.
                  With our eCommerce website building services, you can easily
                  expand your business, whether it's a B2B or B2C online store,
                  over time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="prod-custom-main text-center">
        <div className="container">
          <div className="hed-cent-bx">
            <h2 className="head-center">
              BENEFITS OF ECOMMERCE DEVELOPMENT SERVICES{" "}
            </h2>{" "}
          </div>
          <div className="row">
            <div className="col-lg-4 mx-auto">
              <div className="custom-bx  ">
                <h4 className="">Make e-commerce site mobile-friendly</h4>
                <p className="text-justify">
                  Your ecommerce site can be made very responsive so that it can
                  be easily used on tablets, smartphones and other types of
                  mobile devices.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mx-auto">
              <div className="custom-bx">
                <h4>Easily manage content</h4>
                <p className="text-justify">
                  These days, there are easy-to-use content management opstems.
                  You don’t need to have any programming knowledge to manage the
                  content on your e-commerce site.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4 mx-auto">
              <div className="custom-bx  ">
                <h4>Higher rank in search engine results</h4>
                <p className="text-justify">
                  For a business to be successful, its existence must be known
                  to potential customers. There are effective strategies for
                  making your e-commerce site optimized for search engines.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mx-auto">
              <div className="custom-bx  ">
                <h4>Have a unique appearance</h4>
                <p className="text-justify">
                  Your e-commerce site shouldn’t look like most e-commerce
                  sites. If so, It gets lost in the crowd. Customised web design
                  can make it unique and get noticed.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mx-auto">
              <div className="custom-bx  ">
                <h4>Improved capacity</h4>
                <p className="text-justify">
                  It is possible to increase the ability of the e-commerce site
                  to handle multiple transactions as the business grows. This
                  will result in increased income and profits.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mx-auto">
              <div className="custom-bx  ">
                <h4>Update to the latest technology</h4>
                <p className="text-justify">
                  Don’t continue to use old technology on your ecommerce site.
                  By updating to the latest technology, your site can benefit
                  from superior performance, features and functionality.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cont-desc-bx text-center pt-5 ">
        <img
          className="left-img"
          src="../Images/leftdot.png"
          alt="Mannix Infotech Solutions Pvt.Ltd. E-commerce Development Solutions"
        />
        <img
          className="right-img"
          src="../Images/rightdot.png"
          alt="Mannix Infotech Solutions Pvt.Ltd. E-commerce Development Solutions"
        />
        <div className="container">
          <div className="hed-cent-bx">
            <h2 className="head-center fw-bold">
              Improve Your Growth With Our Multifaceted E-commerce Development
              Solutions
            </h2>
          </div>
          <div className="admin-inner-bx">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <ul className="admin-lft-bx sml-head">
                  <li>
                    <h5 className="fw-bold">Attractive UI / UX</h5>
                    <p className="text-justify">
                      Design interactive UI / UX pages that communicate
                      effectively with our clients.
                    </p>
                  </li>
                  <li>
                    <h5 className="fw-bold">Agile Approach</h5>
                    <p className="text-justify">
                      Transformative Agile Development Culture Delivers Maximum
                      Growth.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                <div className="admin-cent-img-bx">
                  <img
                    className="img-fluid e-image"
                    src="../Images/E-commerceDevelopmentSolutions.png"
                    alt="E-commerceDevelopmentSolutions"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <ul className="admin-rgh-bx sml-head">
                  <li>
                    <h5 className="fw-bold">Rich Featured</h5>
                    <p className="text-justify">
                      Equip our web-based e-commerce solutions with dynamic
                      functionality and functionality to perform complex tasks
                      simpler and faster.
                    </p>
                  </li>
                  <li>
                    <h5 className="fw-bold">Effective Tech-Stack</h5>
                    <p className="text-justify">
                      Use the latest trends and technologies to create a
                      fundamentally sound web application architecture.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EcommerceDevelopment;
