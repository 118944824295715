// import logo from './logo.svg';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./Common/Footer";
import Header from "./Common/Header";
import Home from "./Scrren.js/Home";
import Data from "./Data/data";

import ContactUs from "./Scrren.js/ContactUs";
import AboutUs from "./Scrren.js/AboutUs";
import Internship from "./Scrren.js/Internship";
import OurGallary from "./Scrren.js/OurGallary";
import Career from "./Scrren.js/Career";
import Blog from "./Scrren.js/Blog";
import Business from "./Scrren.js/Business";
import Portfolio from "./Scrren.js/Portfolio";
import Performance from "./Scrren.js/Performance";
import Blog1 from "./Scrren.js/Blog1";
import Blog2 from "./Scrren.js/Blog2";
import Blog3 from "./Scrren.js/Blog3";
import Blog4 from "./Scrren.js/Blog4";
import Blog5 from "./Scrren.js/Blog5";
import AndroidAppDevelopment from "./Scrren.js/App Development/AndroidAppDevelopment";
import IOSAppDevelopment from "./Scrren.js/App Development/IOSAppDevelopment";
import CrowdfundingAppDevelopment from "./Scrren.js/App Development/CrowdfundingAppDevelopment";
import HybridAppDevelopment from "./Scrren.js/App Development/HybridAppDevelopment";
import CrossPlatformAppDevelopment from "./Scrren.js/App Development/CrossPlatformAppDevelopment";
import WordPressDevlopment from "./Scrren.js/WebDevlopment/WordPressDevlopment";
import StaticDevlopment from "./Scrren.js/WebDevlopment/StaticDevlopment";
import DyanamicDevlopment from "./Scrren.js/WebDevlopment/DyanamicDevlopment";
import DrupalDevlopment from "./Scrren.js/WebDevlopment/DrupalDevlopment";
import ClassifiedDevlopment from "./Scrren.js/WebDevlopment/ClassifiedDevlopment";
import SericeLandingDevlopment from "./Scrren.js/WebDevlopment/SericeLandingDevlopment";
import EcommerceDevelopment from "./Scrren.js/WebDevlopment/EcommerceDevlopment";
import LogoDesign from "./Scrren.js/Graphic Design/LogoDesign";
import BrochureDesign from "./Scrren.js/Graphic Design/BrochureDesign";
import FlyerDesign from "./Scrren.js/Graphic Design/FlyerDesign";
import PackagingDesign from "./Scrren.js/Graphic Design/PackagingDesign";
import UIDesign from "./Scrren.js/Graphic Design/UIDesign";
import CRMSoftwareDevelopment from "./Scrren.js/Software Development/CRMSoftwareDevelopment";
import ERPManagment from "./Scrren.js/Software Development/ERPManagment";
import HRMDevelopment from "./Scrren.js/Software Development/HRMDevelopment";
import LMSManagment from "./Scrren.js/Software Development/LMSManagment";
import InvoiceDevelopment from "./Scrren.js/Software Development/InvoiceDevelopment";
import HotelManagment from "./Scrren.js/Software Development/HotelManagment";
import MobileAppMarketing from "./Scrren.js/Digital Marketing/MobileAppMarketing";
import DigitalMarketing from "./Scrren.js/Digital Marketing/DigitalMarketing";
import ITConsultancy from "./Scrren.js/Digital Marketing/ITConsultancy";
import SeoSMOService from "./Scrren.js/Digital Marketing/SeoSMOService";
import SchoolManagement from "./Scrren.js/SchoolManagement";
import FAQ from "./Scrren.js/FAQ";
import Ecommerceproductphotography from "./Scrren.js/Videography/Ecommerceproductphotography";
import Productphotographynormal from "./Scrren.js/Videography/Productphotographynormal";
import Productphotographywithcontent from "./Scrren.js/Videography/Productphotographywithcontent";
import Productslowmotionvideography from "./Scrren.js/Videography/Productslowmotionvideography";
import VideoGrapghySlowMotion from "./Scrren.js/Videography/VideoGrapghySlowMotion";

function App() {
  return (
    <div className="App">
      <BrowserRouter className="">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <Home services={Data.service} layout={Data.layout[1]} />
                <Footer />
              </>
            }
          />
          <Route
            path="/about-us"
            element={
              <>
                <Header />
                <AboutUs /> <Footer />
              </>
            }
          />
          <Route
            path="/internship"
            element={
              <>
                <Header />
                <Internship /> <Footer />
              </>
            }
          />
          <Route
            path="/gallery"
            element={
              <>
                <Header />
                <OurGallary /> <Footer />
              </>
            }
          />

          <Route
            path="contact"
            element={
              <>
                <Header />
                <ContactUs /> <Footer />
              </>
            }
          />

          <Route
            path="/schoolmanagement"
            element={
              <>
                <Header />
                <SchoolManagement /> <Footer />
              </>
            }
          />
          <Route
            path="/career"
            element={
              <>
                <Header />
                <Career /> <Footer />
              </>
            }
          />

          <Route
            path="/portfolio"
            element={
              <>
                <Header />
                <Portfolio /> <Footer />
              </>
            }
          />
          <Route path="/performance" element={<Performance />} />
          <Route
            path="/blog"
            element={
              <>
                <Header />
                <Blog />
                <Footer />
              </>
            }
          />
          <Route
            path="/Graphic-Design-Blog"
            element={
              <>
                <Header />
                <Blog1 />
                <Footer />
              </>
            }
          />
          <Route
            path="/online-marketing-services-blog"
            element={
              <>
                <Header />
                <Blog2 />
                <Footer />
              </>
            }
          />
          <Route
            path="/mobile-app-development-blog"
            element={
              <>
                <Header />
                <Blog3 />
                <Footer />
              </>
            }
          />
          <Route
            path="/web-development-blog"
            element={
              <>
                <Header />
                <Blog4 />
                <Footer />
              </>
            }
          />
          <Route
            path="/digital-marketing-agency-blog"
            element={
              <>
                <Header />
                <Blog5 />
                <Footer />
              </>
            }
          />

          <Route
            path="/business"
            element={
              <>
                <Header />
                <Business /> <Footer />
              </>
            }
          />
          <Route
            path="/android-app-development-services"
            element={
              <>
                <Header />
                <AndroidAppDevelopment /> <Footer />
              </>
            }
          />
          <Route
            path="/ios-app-development-services"
            element={
              <>
                <Header />
                <IOSAppDevelopment /> <Footer />
              </>
            }
          />
          <Route
            path="/crowdfunding-app-development-services"
            element={
              <>
                <Header />
                <CrowdfundingAppDevelopment /> <Footer />
              </>
            }
          />
          <Route
            path="/hybrid-app-development-services"
            element={
              <>
                <Header />
                <HybridAppDevelopment /> <Footer />
              </>
            }
          />

          <Route
            path="/cross-platform-app-development-services"
            element={
              <>
                <Header />
                <CrossPlatformAppDevelopment /> <Footer />
              </>
            }
          />
          <Route
            path="/wordpress-website-development-services"
            element={
              <>
                <Header />
                <WordPressDevlopment /> <Footer />
              </>
            }
          />
          <Route
            path="/static-development-services"
            element={
              <>
                <Header />
                <StaticDevlopment /> <Footer />
              </>
            }
          />
          <Route
            path="/dyanamic-website-development-services"
            element={
              <>
                <Header />
                <DyanamicDevlopment /> <Footer />
              </>
            }
          />
          <Route
            path="/drupal-web-development-services"
            element={
              <>
                <Header />
                <DrupalDevlopment /> <Footer />
              </>
            }
          />
          <Route
            path="/classified-website-development-services"
            element={
              <>
                <Header />
                <ClassifiedDevlopment /> <Footer />
              </>
            }
          />
          <Route
            path="/landingpage-website-development-services"
            element={
              <>
                <Header />
                <SericeLandingDevlopment /> <Footer />
              </>
            }
          />
          <Route
            path="/e-commerce-development-services"
            element={
              <>
                <Header />
                <EcommerceDevelopment /> <Footer />
              </>
            }
          />
          <Route
            path="/logo-design-services"
            element={
              <>
                <Header />
                <LogoDesign /> <Footer />
              </>
            }
          />
          <Route
            path="/brochure-design-services"
            element={
              <>
                <Header />
                <BrochureDesign /> <Footer />
              </>
            }
          />
          <Route
            path="/flyer-design-services"
            element={
              <>
                <Header />
                <FlyerDesign /> <Footer />
              </>
            }
          />
          <Route
            path="/packaging-design-services"
            element={
              <>
                <Header />
                <PackagingDesign /> <Footer />
              </>
            }
          />
          <Route
            path="/ui-design-services"
            element={
              <>
                <Header />
                <UIDesign /> <Footer />
              </>
            }
          />
          <Route
            path="/crm-software-development-services"
            element={
              <>
                <Header />
                <CRMSoftwareDevelopment /> <Footer />
              </>
            }
          />
          <Route
            path="/erp-management-software-services"
            element={
              <>
                <Header />
                <ERPManagment /> <Footer />
              </>
            }
          />
          <Route
            path="/hrm-development-services"
            element={
              <>
                <Header />
                <HRMDevelopment /> <Footer />
              </>
            }
          />
          <Route
            path="/lms-management-software-services"
            element={
              <>
                <Header />
                <LMSManagment /> <Footer />
              </>
            }
          />
          <Route
            path="/invoice-software-development-services"
            element={
              <>
                <Header />
                <InvoiceDevelopment /> <Footer />
              </>
            }
          />
          <Route
            path="/hotelmanagement-software-services"
            element={
              <>
                <Header />
                <HotelManagment /> <Footer />
              </>
            }
          />
          <Route
            path="/mobile-app-marekting-and-advertising-services"
            element={
              <>
                <Header />
                <MobileAppMarketing /> <Footer />
              </>
            }
          />
          <Route
            path="/digital-marketing-services"
            element={
              <>
                <Header />
                <DigitalMarketing /> <Footer />
              </>
            }
          />

          <Route
            path="/it-consultancy-services"
            element={
              <>
                <Header />
                <ITConsultancy /> <Footer />
              </>
            }
          />

          <Route
            path="/seo-smo-service-provider-services"
            element={
              <>
                <Header />
                <SeoSMOService /> <Footer />
              </>
            }
          />
          <Route
            path="/ecommerce-product-photography"
            element={
              <>
                <Header />
                <Ecommerceproductphotography /> <Footer />
              </>
            }
          />
          <Route
            path="/product-photography-normal"
            element={
              <>
                <Header />
                <Productphotographynormal /> <Footer />
              </>
            }
          />
          <Route
            path="/product-photography-with-content"
            element={
              <>
                <Header />
                <Productphotographywithcontent /> <Footer />
              </>
            }
          />
          <Route
            path="/product-slow-motion-videography"
            element={
              <>
                <Header />
                <Productslowmotionvideography /> <Footer />
              </>
            }
          />
          <Route
            path="/videoGrapghy-slow-motion"
            element={
              <>
                <Header />
                <VideoGrapghySlowMotion /> <Footer />
              </>
            }
          />
          <Route
            path="/faq"
            element={
              <>
                <Header />
                <FAQ /> <Footer />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
