import React, { useState } from "react";
import { Helmet } from "react-helmet";

const LMSManagment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>
          Best LMS Management Software Services in Ahmedabad | Mannix Infotech
          Pvt. Ltd.
        </title>
        <meta
          name="description"
          content="Get the best LMS management software services in Ahmedabad with Mannix Infotech Pvt. Ltd. We offer tailored solutions to enhance your learning management systems."
        />
        <meta
          name="keywords"
          content="LMS management software services in Ahmedabad | Best LMS management software company in Ahmedabad | Customized LMS software design services | LMS solutions development agency in Ahmedabad | Expert LMS management software services in Ahmedabad | Top-rated LMS management software services in Ahmedabad | LMS software development for online courses | Ahmedabad LMS management software experts | LMS solutions for educational institutions | LMS management software company in Ahmedabad with expertise in Moodle "
        />

        <link
          rel="canonical"
          href="https://www.mannix.in/lms-management-software-services"
        />
      </Helmet>
      <section className="banner-content-1">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className="position-absolute top-banner-bg"
          viewBox="0 0 1920 761"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
            </defs>
            <clipPath id="SVGID_2_">
              <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
            </clipPath>
            <g className="banner-bg-asw">
              <path
                className="st1"
                d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                            c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
              ></path>
              <path
                className="st2"
                d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                            c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
              ></path>
              <path
                className="st1"
                d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
              ></path>
              <path
                className="st2"
                d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
              ></path>
              <circle className="st1" cx="1536.8" cy="661.3" r="56.2"></circle>
              <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
            </g>
          </g>
        </svg>
        <div className="py-5 container">
          <div className="row align-items-center justify-content-around mt-4">
            <div className="col-lg-6">
              <div className="">
                <h1 className="head-title head-title-without-line m-0 py-3">
                  LMS software Development
                </h1>
                <p className="m-0 py-3">
                  With years of experience in e-learning, Mannix Infotech
                  Solutions Pvt.Ltd. can build and implement a unique LMS
                  software development solution for your partners, customers,
                  and staff. With highly skilled eLearning professionals on
                  staff, we can assist you at any point in the LMS software
                  development lifecycle. It matters a lot what kind of
                  impression your brand makes in person!
                </p>
              </div>
            </div>
            <div className="col-lg-5 prod-section2">
              <div className="">
                <form
                  className="register-form"
                  id="register-form"
                  method="post"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="form_type"
                    id="form_type"
                    value="Android App Development"
                  />
                  <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <label className="w-100">
                          <span>Name</span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div className="w-100">
                        <label className="w-100">
                          <span>Email</span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email id *"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label className="w-100">
                        <span>Contact</span>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Contact No *"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Country</span>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Message</span>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter your message *"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        name="submit"
                        className="submit btn w-100 g-recaptcha-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="breadwrap clearfix mt-5">
        <div className="container fw-bold pt-5 pb-3">
          {" "}
          LMS SOFTWARE DEVELOPMENT
        </div>
      </section>
      <section className="inner-indent clearfix">
        <div className="dot-right clearfix">
          <div className="container">
            <p className="text-justify">
              With years of experience in e-learning, Mannix Infotech Solutions
              Pvt.Ltd. can build and implement a custom LMS software development
              solution for your partners, clients, or staff. Having highly
              skilled eLearning professionals on staff, we can assist you at any
              point in the LMS software development lifecycle:
            </p>
            <ul className="list-style-blt">
              <li>
                Data-driven advice combined with in-person and remote visits
              </li>
              <li> Custom component development and LMS enhancement.</li>
              <li> creating an e-learning system from the ground up.</li>
              <li> smooth interactions with outside software.</li>
              <li>
                Continuous system enhancement and comprehensive quality control.
              </li>
              <li>Complete support for L1, L2, and L3 IT.</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="what-we-do">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="theme-title text-left">
                <h2 className="static_css">School Management Software</h2>
                <p className="text-justify">
                  School ERP software has the potential to be a reliable and
                  effective educational ERP system that is coupled with
                  cutting-edge modules to change teaching and learning and
                  digitize the day-to-day operations of educational
                  establishments. The Smart School ERP software, also referred
                  to as a school management system, streamlines a variety of
                  intricate administrative and non-administrative operations,
                  such as online admission, online fee management, exam
                  administration, assessments, scheduling, and
                  teaching-learning. Enhancing productivity, efficiency, and
                  return on investment are just a few advantages of the school
                  management system.
                </p>
                <p className="text-justify">
                  Educational establishments utilize school administration
                  software as a data management tool to oversee and maintain all
                  student records. It is specifically made to oversee every
                  aspect of school and institution administration. Numerous
                  modules are included in it to assist instructors and staff in
                  managing student activities, including daily attendance,
                  scheduling, managing school records, managing expenses,
                  managing student assessments, and more.
                </p>
                <h2 className="static_css mt-5">
                  Vision Of Our School Management Services
                </h2>
                <p className="text-justify">
                  Superior institutional functionality is provided by Mannix
                  Infotech Solutions Pvt.Ltd. aspires to expand and emerge as a
                  prominent player in the education industry.Our objective is to
                  establish a robust network of educational establishments so
                  that they may interact and exchange information on a single
                  platform. Education management ERP software from Mannix
                  Infotech Solutions Pvt.Ltd. promotes the highest moral
                  standards and advances societal goals by utilizing
                  cutting-edge technologies to deliver excellent educational
                  services to students without requiring technical or geographic
                  assistance.Furthermore, the professional development team at
                  our organization strives to build lasting relationships with
                  businesses and significant connections with educational
                  institutions globally.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="company-offer3 ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="theme-title">
                {" "}
                <strong>ADVANTAGE OF SCHOOL MANAGEMENT SERVICES -</strong>
                <ul className="list-style-blt">
                  <li>Save the time of teachers and administrators.</li>
                  <li>Close the communication gap.</li>
                  <li>Human resources management.</li>
                  <li>Data on the lifespan of students.</li>
                  <li>Schedule management.</li>
                  <li>Collection of fees.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LMSManagment;
