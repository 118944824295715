import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";

const Performance = () => {
  const initialFormData = {
    name: "",
    phone: "",
    Companyname: "",
    Address: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleWhatsAppClick = (e) => {
    e.preventDefault();
    const { name, Companyname, phone, Address } = formData;
    const whatsappMessage = `
     Name: ${name}
    Phone: ${phone}
    Companyname: ${Companyname}
    Address: ${Address}`;
    const whatsappLink = `https://wa.me/+919664053983?text=${encodeURIComponent(
      whatsappMessage
    )}`;
    window.open(whatsappLink, "_blank");
  };
  const [openForm, setOpenForm] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const formRef = [useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        openForm !== null &&
        formRef[openForm].current &&
        !formRef[openForm].current.contains(event.target)
      ) {
        setOpenForm(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openForm]);

  // const toggleForm = () => {
  //   setShowForm(!showForm);
  // };

  const toggleForm = (index) => {
    setOpenForm((prevOpenForm) => (prevOpenForm === index ? null : index));
    setShowForm(!showForm);
  };
  const [openItem, setOpenItem] = useState(null);

  const handleToggle = (id) => {
    setOpenItem((prevOpenItem) => (prevOpenItem === id ? null : id));
  };

  const accordionData = [
    {
      question:
        "What results can you expect when working with Mannix Infotech Solutions Pvt.Ltd.?",
      answer:
        "We get this question a lot but as long as you have prior sales, social proof and a proven business model we can use our proven Paid Ad Campaign Structure to bring your business more leads, sales, and decrease your ad costs by up to 30%.",
    },
    {
      question: "How long does it take to start seeing progress?",
      answer:
        "Our on-boarding process has been streamlined over the years to make sure you can have your system ready with ads running within 72 hours. That first week will show us progress and the data we need to piggy back the best audiences, creatives and offer.",
    },
    {
      question: "Is retargeting expensive? Why not just email or whatsapp?",
      answer:
        "Retargeting is actually a lot cheaper since we have had more touch points with that potential buyer to convince them to be a buyer with a no-brainer offer/ad. Email or whatsapp is great and we pride ourselves on running successful email and whatsapp campaigns but having retargeting ads as well can pull in 2x the buyers. Who would say no to 2x more buyers??",
    },
    {
      question:
        "Do you do more than just Facebook ads or do you do Instagram as well?",
      answer:
        "Yes, we do more than just Facebook. We run ads on multiple platforms such as Instagram, Google, Snapchat, YouTube and more!",
    },
    {
      question:
        "What do I need to work with Mannix Infotech Solutions Pvt.Ltd.?",
      answer:
        "We work with businesses of all sizes, but in order to start with us we would first need to know more about your business, your costs, and current performance of your systems and ads which we can go over on the phone with you when you get started with us below.",
    },
    {
      question:
        "Who is Mannix Infotech Solutions Pvt.Ltd. and what results have you gotten before?",
      answer:
        "Over the past 5 years, Mannix Infotech Solutions Pvt.Ltd. has scaled 217+ businesses that we still work with to this very day and have generated our clients well over 8 Figures from their Paid Ad Campaigns alone.",
    },
    {
      question:
        "What If I'm in an industry Mannix Infotech Solutions Pvt.Ltd. hasn't worked with before?",
      answer:
        "After working with various niches and verticals from Ecom, Health, Fitness, Coaching, Consulting, Beauty, Decor, Home, Real Estate, Local Businesses, Service Based Businesses, Course Creators and Many More B2B/B2C Businesses... We Are Confident In Our Paid Ads Campaign Structure and Have Seen This Work Across SO Many Industries and Verticals That We Know No Matter What Business You Run, We Can Improve The Performance of Your Paid Ads and brings you more results!",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Experts in Performance Marketing Agency in Ahmedabad, India
        </title>
        <meta
          name="description"
          content="Experts in performance marketing agency in Ahmedabad, India. Mannix Infotech Pvt. Ltd. delivers results-driven marketing strategies to maximize your ROI."
        />
        <meta
          name="keyword"
          content="Performance marketing services in Ahmedabad |
   Performance marketing company in Ahmedabad |
     Expert performance marketing services in Ahmedabad |
    Top-rated performance marketing agency in Ahmedabad |
Performance marketing services for startups|
 Ahmedabad performance marketing experts |
Performance marketing solutions for businesses|
  Performance marketing agency in Ahmedabad with expertise in PPC|	
	Performance marketing services for lead generation|
   ROI-driven marketing strategies provider"
        />
        <link rel="canonical" href="https://www.mannix.in/performance" />
      </Helmet>

      <div className="bg-dark">
        <div className="mt-0 performance" style={{ backgroundColor: "black" }}>
          <section className="section-2">
            <div className="container text-white ">
              <div className="text-center program-section-1 pt-5">
                <h2>Our ROI Based Digital Marketing Services Include:</h2>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-4 col-sm-12 col-lg-4">
                  <div className="program-section">
                    <p>
                      <i className="fas fa-solid fa-check"></i> Search Engine
                      Optimization
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 col-lg-4">
                  <div className="program-section">
                    <p>
                      <i className="fas fa-solid fa-check"></i> Social Media
                      Optimization
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 col-lg-4">
                  <div className="program-section">
                    <p>
                      <i className="fas fa-solid fa-check"></i> In Depth
                      Audience Targeting
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 col-lg-4 program-section-row">
                  <div className="program-section">
                    <p>
                      <i className="fas fa-solid fa-check"></i> States &amp;
                      Country Wise Targeting
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 col-lg-4">
                  <div className="program-section program-section-mobile ">
                    <p>
                      <i className="fas fa-solid fa-check"></i> Local Seo/Google
                      My Business
                    </p>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12 col-lg-5">
                  <div className="program-section">
                    <p>
                      <i className="fas fa-solid fa-check"></i> Online
                      Reputation Management (ORM)
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3">
                  <div className="program-section">
                    <p>
                      <i className="fas fa-solid fa-check"></i> Personalization
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3 program-section-row">
                  <div className="program-section">
                    <p>
                      <i className="fas fa-solid fa-check"></i> Content
                      Marketing
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3">
                  <div className="program-section program-section-mobile ">
                    <p>
                      <i className="fas fa-solid fa-check"></i> Email Marketing
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 col-lg-4">
                  <div className="program-section program-section-mobile ">
                    <p>
                      <i className="fas fa-solid fa-check"></i> Multivariate
                      Marketing
                    </p>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12 col-lg-5">
                  <div className="program-section program-section-mobile program-section-row">
                    <p>
                      <i className="fas fa-solid fa-check"></i> Pay-per-click
                      (PPC) Advertising
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3">
                  <div className="program-section program-section-mobile ">
                    <p>
                      <i className="fas fa-solid fa-check"></i> Native
                      Advertising
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3">
                  <div className="program-section program-section-mobile ">
                    <p>
                      <i className="fas fa-solid fa-check"></i> Display
                      Advertising
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 col-lg-4">
                  <div className="program-section program-section-mobile program-section-row">
                    <p>
                      <i className="fas fa-solid fa-check"></i> Conversion Rate
                      Optimization
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3">
                  <div className="program-section program-section-mobile ">
                    <p>
                      <i className="fas fa-solid fa-check"></i> Ads Funnel
                      Creation
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3">
                  <div className="program-section program-section-mobile ">
                    <p>
                      <i className="fas fa-solid fa-check"></i> Sales Funnel
                      Creation
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 col-lg-3">
                  <div className="program-section program-section-mobile program-section-row">
                    <p>
                      <i className="fas fa-solid fa-check"></i> Market Analysis
                    </p>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12 col-lg-4">
                  <div className="margin-top-2 margin-medium hidden text-center text-white relative">
                    <button
                      className="cta-angel-investing pm w-button position-relative"
                      onClick={() => toggleForm(0)}
                    >
                      Apply Now
                    </button>
                    {openForm === 0 && (
                      <div
                        ref={formRef[0]}
                        className="position-absolute start-50 translate-middle bg-white p-5 shadow "
                        style={{
                          zIndex: 2,
                          width: "300px",
                          height: "300px",
                          borderRadius: "5px",
                          top: "100%",
                          left: "50%",
                          transform: "translate(-50%, 0)",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                        onClick={(e) => e.stopPropagation()} // This will prevent the form from closing
                      >
                        <form onSubmit={handleWhatsAppClick}>
                          <div className="row m-2">
                            <div className="col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Your name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="row m-2">
                            <div className="col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Company name"
                                name="Companyname"
                                value={formData.Companyname}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="row m-2">
                            <div className="col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Contact Number"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>

                          <div className="row m-2">
                            <div className="col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Company Address"
                                name="Address"
                                value={formData.Address}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>

                          <div className="d-flex justify-content-center">
                            <button
                              type="submit"
                              className="p-2 bg-danger border-0 text-light rounded"
                            >
                              Send Message
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section-2 text-white">
            <div className="container">
              <div className="text-center week-section">
                <h2>Our Roadmap Would Be </h2>
                <h3>(Complete Company and Client Analysis)</h3>
              </div>
              <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="hero-mentor_component pm">
                  <div className="hero-mentor-info-highlight pm-gradient">
                    <p>2 Days</p>
                  </div>
                  <div className="hero-mentor-details pm">
                    <div className="hero-h4">
                      <h4> Groundwork</h4>
                    </div>
                  </div>
                  <div className="collapsible-menu">
                    <div className="div-block-367 full">
                      <div>
                        <ul className="list-8">
                          <li className="list-item-7">
                            We learn the ins-and-outs of your brand identity,
                            compute a thorough SWOT matrix{" "}
                          </li>
                          <li className="list-item-7">
                            {" "}
                            Create ideal buyer personas, construct a unique
                            sales funnel{" "}
                          </li>
                          <li className="list-item-7">
                            Define your key performance indicators (KPIs) and
                            help you establish SMART and CLEAR goals to guide
                            your social media campaign.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="hero-mentor_component pm">
                  <div className="hero-mentor-info-highlight pm-gradient">
                    <p>3 Days</p>
                  </div>
                  <div className="hero-mentor-details pm">
                    <div className="hero-h4">
                      <h4>Aesthetics</h4>
                    </div>
                  </div>
                  <div className="collapsible-menu">
                    <div className="div-block-367 full">
                      <div>
                        <ul className="list-8">
                          <li className="list-item-7">
                            {" "}
                            Consistency is key to a compelling social media
                            presence. Maintaining your brand’s aesthetic across
                            all social media platforms enhances your
                            credibility.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="hero-mentor_component pm">
                  <div className="hero-mentor-info-highlight pm-gradient">
                    <p>4 Days</p>
                  </div>
                  <div className="hero-mentor-details pm">
                    <div className="hero-h4">
                      <h4>Setup</h4>
                    </div>
                  </div>
                  <div className="collapsible-menu">
                    <div className="div-block-367 full">
                      <div>
                        <ul className="list-8">
                          <li className="list-item-7">
                            {" "}
                            Your personalized social media management package
                            includes advertising services across Facebook and
                            Instagram.A dedicated account partner from ensures
                            that your social media accounts are in tune with
                            industry best practices
                          </li>
                          <li className="list-item-7">
                            {" "}
                            They also implement Google analytics and Facebook’s
                            Tracking Pixel on your website to differentiate
                            traffic sources, track conversions, establish
                            rule-based goals and measure your KPIs
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="hero-mentor_component pm">
                  <div className="hero-mentor-info-highlight pm-gradient">
                    <p>5 Days</p>
                  </div>
                  <div className="hero-mentor-details pm">
                    <div className="hero-h4">
                      <h4>Research</h4>
                    </div>
                  </div>
                  <div className="collapsible-menu">
                    <div className="div-block-367 full">
                      <div>
                        <ul className="list-8">
                          <li className="list-item-7">
                            {" "}
                            Our marketing experts develop empathy maps and
                            perform a comprehensive competitor analysis to
                            identify accurately hone in on your brand’s target
                            audience and unique selling propositions (USPs).
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="hero-mentor_component pm">
                  <div className="hero-mentor-info-highlight pm-gradient">
                    <p>2 Days</p>
                  </div>
                  <div className="hero-mentor-details pm">
                    <div className="hero-h4">
                      <h4>Targeting Mechanism</h4>
                    </div>
                  </div>
                  <div className="collapsible-menu">
                    <div className="div-block-367 full">
                      <div className="row">
                        <div className="col--md-12 col-lg-12 col-sm-12">
                          <ul className="list-8">
                            <li className="list-item-7">
                              Our advertising experts use empathy maps in
                              congruence with ideal buyer personas.
                            </li>
                            <li className="list-item-7">
                              we identify the ideal social media platforms for
                              you to target, types of ads to serve your target
                              audience and the targeting mechanisms best suited
                              to connect with them seamlessly.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="hero-mentor_component pm">
                  <div className="hero-mentor-info-highlight pm-gradient">
                    <p>2 Days</p>
                  </div>
                  <div className="hero-mentor-details pm">
                    <div className="hero-h4">
                      <h4>Campaign Audit</h4>
                    </div>
                  </div>
                  <div className="collapsible-menu">
                    <div className="div-block-367 full">
                      <div className="row">
                        <div className="col--md-12 col-lg-12 col-sm-12">
                          <ul className="list-8">
                            <li className="list-item-7">
                              Before launching your social media ad campaign,
                              our technical experts conduct a panoramic social
                              media audit to ensure that your creatives,
                              captions and targeting settings are tuned just
                              right to guarantee that no foundational errors
                              affect the effectiveness of your ad campaign.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="hero-mentor_component pm">
                  <div className="hero-mentor-info-highlight pm-gradient">
                    <p>3 Days</p>
                  </div>
                  <div className="hero-mentor-details pm">
                    <div className="hero-h4">
                      <h4>Campaign Deployment and Optimization </h4>
                    </div>
                  </div>
                  <div className="collapsible-menu">
                    <div className="div-block-367 full">
                      <div>
                        <ul className="list-8">
                          <li className="list-item-7">
                            We’ll launch your campaign by deploying ad creative
                            given by you and ad copy crafted by us .
                          </li>
                          <li className="list-item-7">
                            The performance of each ad will be constantly
                            monitored by your account partner, subjected to
                            rigorous assessment, using methods such as A/B
                            testing and evaluated against your KPIs, and
                            optimized to improve their impact.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="hero-mentor_component pm">
                  <div className="hero-mentor-info-highlight pm-gradient">
                    <p>8 Days</p>
                  </div>
                  <div className="hero-mentor-details pm">
                    <div className="hero-h4">
                      <h4>Reporting</h4>
                    </div>
                  </div>
                  <div className="collapsible-menu">
                    <div className="div-block-367 full">
                      <div className="row">
                        <div className="col--md-12 col-lg-12 col-sm-12">
                          <ul className="list-8">
                            <li className="list-item-7">
                              We generate concise progress reports on a
                              bi-weekly basis that let you track numbers related
                              to click-through-rates,subscriptions, conversions,
                              purchases and other key factors that measure the
                              performance of your advertising strategy with
                              ease.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="hero-mentor_component pm">
                  <div className="hero-mentor-info-highlight pm-gradient">
                    <p>BEYOND 4 WEEKS…</p>
                  </div>
                  <div className="hero-mentor-details pm">
                    <div className="hero-h4">
                      <h4>Now you are all set to scale up your sales</h4>
                    </div>
                  </div>
                  <div className="collapsible-menu">
                    <div className="div-block-367 full">
                      <div>
                        <ul className="list-8" type="none">
                          <li className="list-item-7">
                            In Simple way : Invest 100 rupees in your business
                            and get a return of 500 rupees after your
                            benchmarking...
                            <br />
                            Get Positive P;L always{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center mt-5 ">
                <div className="col-md-4 col-sm-12 col-lg-4">
                  <div className="margin-top-2 margin-medium hidden text-center text-white relative">
                    <button
                      className="cta-angel-investing pm w-button position-relative"
                      onClick={() => toggleForm(1)}
                    >
                      Apply Now
                    </button>
                    {openForm === 1 && (
                      <div
                        ref={formRef[1]}
                        className="position-absolute start-50 translate-middle bg-white p-5 shadow "
                        style={{
                          zIndex: 2,
                          width: "300px",
                          height: "300px",
                          borderRadius: "5px",
                          top: "100%",
                          left: "50%",
                          transform: "translate(-50%, 0)",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}
                        onClick={(e) => e.stopPropagation()} // This will prevent the form from closing
                      >
                        <form onSubmit={handleWhatsAppClick}>
                          <div className="row m-2">
                            <div className="col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Your name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="row m-2">
                            <div className="col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Company name"
                                name="Companyname"
                                value={formData.Companyname}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="row m-2">
                            <div className="col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Contact Number"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>

                          <div className="row m-2">
                            <div className="col">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Company Address"
                                name="Address"
                                value={formData.Address}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>

                          <div className="d-flex justify-content-center">
                            <button
                              type="submit"
                              className="p-2 bg-danger border-0 text-light rounded"
                            >
                              Send Message
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section-2">
            <div className="container">
              <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="roadmap-heading text-center">
                  <h2 className="text-white mt-5">
                    By the end of our 1 month Benchmarking
                    <br /> you'll have a clear idea on how to
                  </h2>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col--md-6 col-lg-6 col-sm-12">
                  <div className="roadmap-inner-div">
                    <p>
                      <i className="fas fa-solid fa-check"></i>Generate more
                      revenue with low budget{" "}
                    </p>
                  </div>
                </div>
                <div className="col--md-6 col-lg-6 col-sm-12">
                  <div className="roadmap-inner-div">
                    <p>
                      <i className="fas fa-solid fa-check"></i>How to go from 0
                      to 1000 app downloads{" "}
                    </p>
                  </div>
                </div>
                <div className="col--md-6 col-lg-6 col-sm-12">
                  <div className="roadmap-inner-div">
                    <p>
                      <i className="fas fa-solid fa-check"></i>Increase your
                      client based from 0 to 20x in few months
                    </p>
                  </div>
                </div>
                <div className="col--md-6 col-lg-6 col-sm-12">
                  <div className="roadmap-inner-div">
                    <p>
                      <i className="fas fa-solid fa-check"></i>Scale marketing
                      spends while maintaining a positive ROI{" "}
                    </p>
                  </div>
                </div>
                <div className="col--md-6 col-lg-6 col-sm-12">
                  <div className="roadmap-inner-div">
                    <p>
                      <i className="fas fa-solid fa-check"></i>Get yourself
                      ready for fund raising
                    </p>
                  </div>
                </div>
                <div className="col--md-6 col-lg-6 col-sm-12">
                  <div className="roadmap-inner-div">
                    <p>
                      <i className="fas fa-solid fa-check"></i>Get traction and
                      go through the product lifecycle journey{" "}
                    </p>
                  </div>
                </div>
                <div className="col--md-6 col-lg-6 col-sm-12">
                  <div className="roadmap-inner-div div-icon">
                    <p>
                      <i className="fas fa-solid fa-check"></i>Become a
                      profitable company by our zero to hero marketing
                      strategies
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section-2">
            <div className="container">
              <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="text-center">
                  <h2 className="text-white mt-5">How Are We Heading?</h2>
                </div>
                <div className="img_div d-flex justify-content-center">
                  <img src="../performance.png" alt="performance" width="70%" />
                </div>
              </div>
            </div>
          </section>

          <section className="section-2 faqs_top text-white">
            <div className="container">
              <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="text-center mobile-htag">
                  <h2>FAQs: Here’s everything you may ask...</h2>
                </div>
              </div>
              <div className="card-body">
                <div className="flex flex-column   faq-section">
                  <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-12 col-sm-12">
                      <div id="accordion">
                        {accordionData.map((item, index) => (
                          <div key={index} className="card card-accod">
                            <div
                              className="card-header car-dec-1"
                              id={`heading-${index + 1}`}
                            >
                              <h5 className="mb-0">
                                <a
                                  href="/"
                                  className={
                                    openItem === index ? "" : "collapsed"
                                  }
                                  role="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleToggle(index);
                                  }}
                                  aria-expanded={openItem === index}
                                  aria-controls={`collapse-${index + 1}`}
                                >
                                  {item.question}
                                </a>
                              </h5>
                            </div>
                            <div
                              id={`collapse-${index + 1}`}
                              className={`collapse ${
                                openItem === index ? "show" : ""
                              }`}
                              data-parent="#accordion"
                              aria-labelledby={`heading-${index + 1}`}
                            >
                              <div className="card-body card-bodyy-1">
                                {item.answer}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-lg-4">
                      <div className="margin-top-2 margin-medium hidden text-center text-white relative">
                        <button
                          className="cta-angel-investing pm w-button position-relative"
                          onClick={() => toggleForm(2)}
                        >
                          Apply Now
                        </button>
                        {openForm === 2 && (
                          <div
                            ref={formRef[2]}
                            className="position-absolute start-50 translate-middle bg-white p-5 shadow "
                            style={{
                              zIndex: 2,
                              width: "300px",
                              height: "300px",
                              borderRadius: "5px",
                              top: "100%",
                              left: "50%",
                              transform: "translate(-50%, 0)",
                              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                            }}
                            onClick={(e) => e.stopPropagation()} // This will prevent the form from closing
                          >
                            <form onSubmit={handleWhatsAppClick}>
                              <div className="row m-2">
                                <div className="col">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Your name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="row m-2">
                                <div className="col">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Company name"
                                    name="Companyname"
                                    value={formData.Companyname}
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="row m-2">
                                <div className="col">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Contact Number"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                              </div>

                              <div className="row m-2">
                                <div className="col">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Company Address"
                                    name="Address"
                                    value={formData.Address}
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                              </div>

                              <div className="d-flex justify-content-center">
                                <button
                                  type="submit"
                                  className="p-2 bg-danger border-0 text-light rounded"
                                >
                                  Send Message
                                </button>
                              </div>
                            </form>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section-4">
            <div className="container">
              <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="footer-image-div text-center">
                  <div className="footer-logo-container">
                    <img src="../logo.png" alt="Balaji logo" width="400px" />
                  </div>
                  <div className="social_links">
                    <ul type="none">
                      <li>
                        <a
                          href="https://www.facebook.com/profile.php?id=61558304945053"
                          title="Facebook"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://x.com/mediatech09?t=x-kv93jEG5y0aqezPQ02qw&s=08"
                          title="Twitter"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/mannixinfotech09/?fbclid=IwAR30ORrOy8gHLBnmw9Nys9CZNYznRUYLyiAXihrlzLH63aVD_y96syb0jJo"
                          title="Instagram"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/mannix-infotech-solutions-pvt-ltd/"
                          title="Linkedin"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="row justify-content-center">
                    <div className="col--md-6 col-lg-6 col-sm-12">
                      <div className="footer-des">
                        <p className="fs-6">
                          For any support, please mail to{" "}
                          <a
                            href="mailto:info@mannix.in"
                            style={{ textDecoration: "none", color: "black" }}
                            title="mailto"
                            target="_blank"
                            rel="noreferrer"
                          >
                            info@mannix.in
                          </a>{" "}
                          Our support team will get back to you within 24 hours.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-block-284">
              <p className="fs-4">
                ©️ 2024 Mannix Infotech Solutions Pvt.Ltd. All rights reserved.
              </p>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Performance;
