import React from "react";
import { Helmet } from "react-helmet";

const Productphotographywithcontent = () => {
  return (
    <>
      <Helmet>
        <title>
          Best Product Photography in Ahmedabad | Stunning Pictures & Images
        </title>
        <meta
          name="description"
          content="Capture stunning product images with expert photography in Ahmedabad, India. Get high-quality pictures and engaging content. Serving the UK, US, Canada, and Australia."
        />
        <meta
          name="keyword"
          content="best product photography services Ahmedabad|
 high-quality product images Ahmedabad |
expert product photography with content Ahmedabad |
professional product photo services Ahmedabad |
affordable product photography Ahmedabad | 
product photography with engaging content Ahmedabad|
custom product photography services Ahmedabad |
top product image services Ahmedabad |
best product photography for online stores Ahmedabad |
high-resolution product photography Ahmedabad"
        />

        <link
          rel="canonical"
          href="https://www.mannix.in/product-photography-with-content"
        />
      </Helmet>
      <div className="container-fluid p-0 m-0">
        <div className="">
          <div className="col-12 mb-3">
            <div className="bg-light p-3 fs-5 rounded text-center text-muted">
              <div className="bg-danger p-2 text-white mx-auto rounded custom-width">
                Advanced Services
              </div>

              <div className="p-2 mt-3 rounded border mx-auto w-75">
                <h1 className=" fw-bold text-black text-center">
                  Lifestyle Product Photography Services
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row ">
          <div className="col-12 col-md-6">
            <img
              src="./Images/LSP.png"
              alt="Lifestyle Product Photography Services"
              className="img-fluid"
            />
          </div>
          <div className="col-12 col-md-6">
            <div className="d-flex flex-column items-center">
              <p className="fs-4">
                Lifestyle Product Photography By{" "}
                <span className="text-danger">Mannix Infotech Solutions!</span>
              </p>
              <p className="pt-4">
                At Mannix Infotech Solutions!,we offer a range of product
                photography services, including options for lifestyle product
                photography.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-md-block d-none m-2 mt-5">
        {/* Grid for the next two rows with two images per row */}
        <div className="d-flex justify-content-center flex-wrap gap-2">
          <img
            className="img-fluid rounded"
            src="./Images/LSP1.png"
            alt="Lifestyle Product Photography Services"
          />
          <img
            className="img-fluid rounded"
            src="./Images/LSP2.png"
            alt="Lifestyle Product Photography Services"
          />
          <img
            className="img-fluid rounded"
            src="./Images/LSP3.png"
            alt="Lifestyle Product Photography Services"
          />
          <img
            className="img-fluid rounded"
            src="./Images/LSP4.png"
            alt="Lifestyle Product Photography Services"
          />
          <img
            className="img-fluid rounded"
            src="./Images/LSP5.png"
            alt="Lifestyle Product Photography Services"
          />
        </div>
      </div>
      <div className="d-md-none m-2">
        {/* First row with one full-width image */}
        <div className="mb-4">
          <img
            className="img-fluid rounded w-100"
            src="./Images/LSP1.png"
            alt="Lifestyle Product Photography Services"
          />
        </div>

        {/* Grid for the next two rows with two images per row */}
        <div className="row g-2">
          <div className="col-6">
            <img
              className="img-fluid rounded"
              src="./Images/LSP2.png"
              alt="Lifestyle Product Photography Services"
            />
          </div>
          <div className="col-6">
            <img
              className="img-fluid rounded"
              src="./Images/LSP3.png"
              alt="Lifestyle Product Photography Services"
            />
          </div>
          <div className="col-6">
            <img
              className="img-fluid rounded"
              src="./Images/LSP4.png"
              alt="Lifestyle Product Photography Services"
            />
          </div>
          <div className="col-6">
            <img
              className="img-fluid rounded"
              src="./Images/LSP5.png"
              alt="Lifestyle Product Photography Services"
            />
          </div>
        </div>
      </div>
      <div className="mt-5 bg-light text-center">
        <p className="  p-2 ">
          <span className="fs-md-5 fs-4">
            {" "}
            We Retouch your Product Into A Stock Photo Realistically
          </span>
          <br />
          Our Lifestyle Composites look real without all the production and
          expense of a real photoshoot.
        </p>
        <p className="" style={{ fontSize: "17px" }}></p>
      </div>
      <div className="container bg-light">
        <div className="row p-md-5  rounded">
          <div className="col-12 col-md-6">
            <p className="fs-3 font-bold mt-5">
              What is a Lifestyle Composite?
            </p>
            <p className="pt-4">
              This is a lifestyle photo that is created using an image of your
              product and a stock image.
            </p>
            <p className="pt-3">
              First, our knowledgeable photographers shoot your product with
              lighting that matches the stock image.
            </p>
            <p className="pt-3">
              Then, our retouchers blend it into a stock image with a little bit
              of Photoshop magic.
            </p>
            <p className="pt-3">
              The end result is a lifestyle image of your product that can be
              used for a variety of content purposes.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img
              src="./Images/LSP6.png"
              alt="Lifestyle Product Photography Services"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="mt-5 bg-light text-center mb-5">
        <p className="  p-2 ">
          <span className="fs-md-5 fs-4">
            {" "}
            Lifestyle Composites vs Traditional Lifestyle Production
          </span>
        </p>
        <p className="" style={{ fontSize: "17px" }}></p>
      </div>

      <div className="container">
        <div className="row text-center">
          <div className="col-md-6">
            <div className="mb-3 w-100">
              <img
                src="./Images/LC.png"
                alt="Lifestyle Composites"
                className="img-fluid rounded border w-100"
              />
            </div>
            <div className="border rounded p-2 w-100 mb-4">
              <h2 className="h4">Lifestyle Composites</h2>
              <p>Starting At $150</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3 w-100">
              <img
                src="./Images/LS.png"
                alt="Traditional Lifestyle Shoot"
                className="img-fluid rounded border w-100"
              />
            </div>
            <div className="border rounded p-2 w-100">
              <h2 className="h4">Traditional Lifestyle Shoot</h2>
              <p>$2,000-$10,000</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container border rounded p-4">
        <div className="row">
          <div className="col-md-12">
            <div className="p-3">
              <p>
                A{" "}
                <strong>
                  <span style={{ color: "orangered" }}>
                    traditional lifestyle photoshoot
                  </span>
                </strong>{" "}
                for your product will usually require a large amount of{" "}
                <span style={{ color: "orangered" }}>
                  <strong>time, prep,</strong>
                </span>{" "}
                and a{" "}
                <span style={{ color: "orangered" }}>
                  <strong>full team of specialists</strong>
                </span>{" "}
                for traditional production.
              </p>
              <p>
                At POW, our{" "}
                <span style={{ color: "orangered" }}>
                  <strong>Lifestyle Composites</strong>
                </span>{" "}
                are a fraction of the time and price required for traditional
                freelance production.{" "}
                <span style={{ color: "orangered" }}>
                  <strong>
                    You don’t need to shop around for a team of creative
                    specialists.
                  </strong>
                </span>{" "}
                We have them all with us, at our studio!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container bg-light">
        <div className="row p-md-5  rounded">
          <div className="col-12 col-md-6">
            <img
              src="./Images/LSP6.png"
              alt="Lifestyle Product Photography Services"
              className="img-fluid"
            />
          </div>
          <div className="col-12 col-md-6">
            <p className="fs-md-1  fs-4 font-bold ">
              More Affordable Than Traditional Lifestyle Photography
            </p>
            <p className="pt-md-4 pt-2">
              Our creative team has designed a specialty service that uses stock
              imagery to create lifestyle photos for all different types of
              products, at a fraction of the time and cost of traditional
              production.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 bg-light text-center">
        <p className="  p-2 ">
          <span className="fs-md-5 fs-4"> Get Creative!</span>
          <br />
          <span>
            We love creating fun shots with stock images of pedestals, holiday
            setups, and surreal scenes. Here are a few of our favorites!
          </span>
        </p>
        <p className="" style={{ fontSize: "17px" }}></p>
      </div>
      <div className="container">
        <div className="row g-3 m-24">
          {" "}
          {/* First column with 9/12 width */}
          <div className="col-12 col-md-8">
            <img
              className="w-100 mb-3 rounded" // Margin bottom to add space below
              style={{ height: "320px", objectFit: "cover" }}
              src="./Images/PS1.png"
              alt="Lifestyle Product"
            />
          </div>
          {/* Second column with 3/12 width */}
          <div className="col-12 col-md-4">
            <img
              className="w-100 mb-3 rounded" // Margin bottom to add space below
              style={{ height: "320px", objectFit: "cover" }}
              src="./Images/CP9.png"
              alt="Lifestyle Product"
            />
          </div>
          {/* Third column with 3/12 width */}
          <div className="col-12 col-md-4">
            <img
              className="w-100 rounded"
              style={{ height: "320px", objectFit: "cover" }}
              src="./Images/CP10.png"
              alt="Lifestyle Product"
            />
          </div>
          {/* Fourth column with 9/12 width */}
          <div className="col-12 col-md-8">
            <img
              className="w-100 rounded"
              style={{ height: "320px", objectFit: "cover" }}
              src="./Images/PS5.png"
              alt="Lifestyle Product"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Productphotographywithcontent;
