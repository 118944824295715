import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const [isNavbarOpen] = useState(false);
  const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const [isProductsDropdownOpen, setIsProductsDropdownOpen] = useState(false);

  const handleWhatsAppClick = () => {
    const phoneNumber = "919664053983";
    const message = "Hello! Can I Get More Information About Your Business!";
    const encodedMessage = encodeURIComponent(message);

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    window.open(whatsappUrl, "_blank");
  };
  const location = useLocation();

  useEffect(() => {
    const navLinks = document.querySelectorAll(".nav-link");
    navLinks.forEach((link) => {
      if (link.getAttribute("href") === location.pathname) {
        link.classList.add("active");
      } else {
        link.classList.remove("active");
      }
    });
  }, [location]);

  const openNav = () => {
    document.getElementById("mySidebar").style.width = "400px";
    document.getElementById("main").style.marginLeft = "400px";
  };

  const closeNav = () => {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
  };
  const toggleCompanyDropdown = () => {
    setIsCompanyDropdownOpen(!isCompanyDropdownOpen);
  };

  const toggleServicesDropdown = () => {
    setIsServicesDropdownOpen(!isServicesDropdownOpen);
  };
  const toggleProductsDropdown = () => {
    setIsProductsDropdownOpen(!isProductsDropdownOpen);
  };
  document.querySelectorAll(".has-megamenu").forEach((item) => {
    item.addEventListener("mouseenter", () => {
      item.querySelector(".dropdown-menu").classList.add("show");
    });
    item.addEventListener("mouseleave", () => {
      item.querySelector(".dropdown-menu").classList.remove("show");
    });
  });

  return (
    <>
      <div className="sticky-top">
        <div className="sticky-top">
          {/* ----------------------------------------------//Header1 */}
          <div className="text-dark " style={{ backgroundColor: "#e4d06a" }}>
            <div className="container d-flex justify-content-around align-items-center flex-wrap mb-0">
              <div className="d-flex gap-3 main">
                <div className="d-flex align-items-center">
                  <i className="fa-solid fa-phone-volume p-1 border border-0 rounded"></i>
                  <a
                    href="tel:+91 9664053983"
                    className="text-decoration-none text-dark"
                    title="phone"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="mb-0 fw-bold">+91 9664053983</p>
                  </a>
                </div>
                <div className="d-flex align-items-center">
                  <a
                    className="d-flex"
                    href="mailto:info@mannix.in"
                    style={{ textDecoration: "none", color: "black" }}
                    title="mailto"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-solid fa-envelope p-1 border border-0 rounded"></i>
                    <p className="mb-0 fw-bold">info@mannix.in</p>
                  </a>
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <i className="fa-brands fa-monero p-1 border border-0 rounded"></i>
                <p className="mb-0 fw-bold">
                  Mannix Infotech Solutions Pvt. Ltd.
                </p>
              </div>
              <div className="mt-3">
                <ul className="list-unstyled d-flex gap-3 flex-wrap justify-content-center">
                  <li>
                    <a
                      href="https://www.instagram.com/mannixinfotech09/?fbclid=IwAR30ORrOy8gHLBnmw9Nys9CZNYznRUYLyiAXihrlzLH63aVD_y96syb0jJo"
                      aria-label="Instagram"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i
                        className="fa-brands fa-instagram"
                        style={{ fontSize: "20px", color: "black" }}
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/profile.php?id=61558304945053"
                      aria-label="facebook"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i
                        className="fa-brands fa-facebook-f"
                        style={{ fontSize: "20px", color: "black" }}
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://x.com/mediatech09?t=x-kv93jEG5y0aqezPQ02qw&s=08"
                      aria-label="twitter"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i
                        className="fa-brands fa-twitter"
                        style={{ fontSize: "20px", color: "black" }}
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/mannix-infotech-solutions-pvt-ltd/"
                      aria-label="linkedin"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i
                        className="fa-brands fa-linkedin-in"
                        style={{ fontSize: "20px", color: "black" }}
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className=" mt-3">
                <a
                  href="/contact"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <div
                    style={{
                      borderBottom: "50px solid #2980b9",
                      borderLeft: "100px solid transparent",
                      borderRight: "0px solid transparent",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0",
                    }}
                  >
                    <span
                      style={{ marginBottom: "-50px", filter: "brightness(1)" }}
                    >
                      <img
                        src="../Images/estimateicon.png"
                        alt="estimateicon"
                        width="30px"
                      />
                    </span>
                    <span
                      className="p-2 text-white fw-bold"
                      style={{ marginBottom: "-50px" }}
                    >
                      ESTIMATE YOUR PROJECT
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>

          {/*----------------------------------------------- //Header2 */}
          <div
            className="bg-white border border-top-0 border-bottom-2 p-0"
            style={{ height: "90px" }}
          >
            <nav
              className="navbar navbar-expand-lg navbar-light"
              style={{ backgroundColor: "#0000", position: "relative" }}
            >
              <div className="container-fluid">
                <a className="navbar-brand px-1" href="/">
                  <img
                    src="./logo.png"
                    alt="Mannix logo"
                    height="70px"
                    width="235px"
                  />
                </a>

                <button
                  className={`navbar-toggler ${
                    isNavbarOpen ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={openNav}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className={`collapse navbar-collapse ${
                    isNavbarOpen ? "show" : ""
                  }`}
                >
                  <ul className="navbar-nav mx-auto  mb-lg-0 fw-bold custom-dropdown-menu   custom-margin">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle text-dark  pb-4 pt-4"
                        href="/company"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Company
                      </a>
                      <ul
                        className="dropdown-menu  bg-dark"
                        aria-labelledby="navbarDropdownMenuLink"
                        style={{ marginTop: "6px" }}
                      >
                        <li style={{ fontSize: "15px" }}>
                          <a
                            className="dropdown-item fw-bold text-white "
                            href="/about-us"
                          >
                            About Us
                          </a>
                        </li>
                        <li style={{ fontSize: "15px" }}>
                          <a
                            className="dropdown-item fw-bold text-white"
                            href="/internship"
                          >
                            Internship
                          </a>
                        </li>
                        <li style={{ fontSize: "15px" }}>
                          <a
                            className="dropdown-item fw-bold text-white"
                            href="/gallery"
                          >
                            Our Gallery
                          </a>
                        </li>
                        <li style={{ fontSize: "15px" }}>
                          <a
                            className="dropdown-item fw-bold text-white"
                            href="/blog"
                          >
                            Blog
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item dropdown has-megamenu">
                      <a
                        className="nav-link dropdown-toggle  pb-4 pt-4"
                        href="/"
                        data-bs-toggle="dropdown"
                        target="_blank"
                      >
                        Services
                      </a>
                      <div
                        className="dropdown-menu megamenu bg-dark text-white p-5"
                        style={{ marginTop: "6px", minWidth: "100%" }}
                        role="menu"
                      >
                        <div className="row d-flex flex-wrap gap-5 justify-content-between">
                          <div className="col-12 col-md-4 col-lg-2 mb-3">
                            <h6
                              className="p-2 fs-6 fw-bold text-center"
                              style={{
                                backgroundColor: "#eb4d4b",
                                borderRadius: "5px",
                              }}
                            >
                              WEB DEVELOPMENT
                            </h6>
                            <ul className="list-unstyled">
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/wordpress-website-development-services"
                                >
                                  Wordpress Website Development
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/static-development-services"
                                >
                                  Static Website Development
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/dyanamic-website-development-services"
                                >
                                  Dynamic Website Development
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/drupal-web-development-services"
                                >
                                  Drupal Web Development
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/classified-website-development-services"
                                >
                                  Classified Website Development
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/landingpage-website-development-services"
                                >
                                  Landing Page Website Development
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/e-commerce-development-services"
                                >
                                  E-Commerce Development
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="col-12 col-md-4 col-lg-2 mb-3">
                            <h6
                              className="p-2 fs-6 fw-bold text-center"
                              style={{
                                backgroundColor: "#eb4d4b",
                                borderRadius: "5px",
                              }}
                            >
                              APP DEVELOPMENT
                            </h6>
                            <ul className="list-unstyled">
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/android-app-development-services"
                                >
                                  Android App Development
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/ios-app-development-services"
                                >
                                  IOS App Development
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/crowdfunding-app-development-services"
                                >
                                  Crowdfunding App Development
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/hybrid-app-development-services"
                                >
                                  Hybrid App Development
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/cross-platform-app-development-services"
                                >
                                  Cross-Platform App Development
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="col-12 col-md-4 col-lg-2 mb-3">
                            <h6
                              className="p-2 fs-6 fw-bold text-center"
                              style={{
                                backgroundColor: "#eb4d4b",
                                borderRadius: "5px",
                              }}
                            >
                              SOFTWARE DEVELOPMENT
                            </h6>
                            <ul className="list-unstyled">
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/crm-software-development-services"
                                >
                                  CRM Software Development
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/hrm-development-services"
                                >
                                  HRM Development
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/erp-management-software-services"
                                >
                                  ERP Management Software
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/lms-management-software-services"
                                >
                                  LMS Management Software
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/invoice-software-development-services"
                                >
                                  Invoice Software Development
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/hotelmanagement-software-services"
                                >
                                  Hotel Management Software
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="col-12 col-md-4 col-lg-2 mb-3">
                            <h6
                              className="p-2 fs-6 fw-bold text-center"
                              style={{
                                backgroundColor: "#eb4d4b",
                                borderRadius: "5px",
                              }}
                            >
                              DIGITAL MARKETING
                            </h6>
                            <ul className="list-unstyled">
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/mobile-app-marekting-and-advertising-services"
                                >
                                  Mobile App Marketing And Advertising
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/seo-smo-service-provider-services"
                                >
                                  Seo/SMO Service Provider
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/it-consultancy-services"
                                >
                                  IT Consultancy
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/digital-marketing-services"
                                >
                                  Digital Marketing Services
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="col-12 col-md-4 col-lg-2 mb-3">
                            <h6
                              className="p-2 fs-6 fw-bold text-center"
                              style={{
                                backgroundColor: "#eb4d4b",
                                borderRadius: "5px",
                              }}
                            >
                              GRAPHIC DESIGNING
                            </h6>
                            <ul className="list-unstyled">
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/logo-design-services"
                                >
                                  Logo Design
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/flyer-design-services"
                                >
                                  Flyer Design
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/brochure-design-services"
                                >
                                  Brochure Design
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/packaging-design-services"
                                >
                                  Packaging Design
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/ui-design-services"
                                >
                                  UI Design
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="col-12 col-md-4 col-lg-2 mb-3">
                            <h6
                              className="p-2 fs-6 fw-bold text-center"
                              style={{
                                backgroundColor: "#eb4d4b",
                                borderRadius: "5px",
                              }}
                            >
                              Videography
                            </h6>
                            <ul className="list-unstyled">
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/videoGrapghy-slow-motion"
                                >
                                  Slow motion videography
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/product-slow-motion-videography"
                                >
                                  Product slow motion videography for e-commerce
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/product-photography-normal"
                                >
                                  Product photography normal
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/product-photography-with-content"
                                >
                                  Product photography with content
                                </a>
                              </li>
                              <li style={{ fontSize: "14px" }}>
                                <a
                                  className="dropdown-item fw-bold text-white"
                                  href="/ecommerce-product-photography"
                                >
                                  Ecommerce product photography
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link text-dark  pb-4 pt-4"
                        href={"/performance"}
                      >
                        Performance Marketing
                      </a>
                    </li>
                    <li className="nav-item dropdown ">
                      <a
                        className="nav-link dropdown-toggle text-dark  pb-4 pt-4"
                        href="/"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Our Products
                      </a>
                      <ul
                        className="dropdown-menu  bg-dark"
                        aria-labelledby="navbarDropdownMenuLink"
                        style={{ marginTop: "6px" }}
                      >
                        <li style={{ fontSize: "15px" }}>
                          <a
                            className="dropdown-item fw-bold text-white"
                            href="/schoolmanagement"
                          >
                            School Management System
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link text-dark  pb-4 pt-4"
                        href={"/portfolio"}
                      >
                        Portfolio
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link text-dark  pb-4 pt-4"
                        href={"/career"}
                      >
                        Career
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link text-dark  pb-4 pt-4"
                        href={"/business"}
                      >
                        Business-Developer
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link text-dark  pb-4 pt-4"
                        href={"/faq"}
                      >
                        FAQ
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link text-dark  pb-4 pt-4"
                        href={"/contact"}
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>

          <div id="mySidebar" className="sidebar ">
            <Link
              // href="javascript:void(0)"
              className="closebtn"
              onClick={closeNav}
            >
              &times;
            </Link>
            <a href="/" className="text-white fs-5">
              Home
            </a>
            <div className="dropdown">
              <Link
                // href="javascript:void(0)"
                className="dropdown-toggle text-white fs-5"
                onClick={toggleCompanyDropdown}
              >
                Company
              </Link>
              {isCompanyDropdownOpen && (
                <div
                  className="dropdown-container p-1"
                  style={{ backgroundColor: "#444" }}
                >
                  <a href="/about-us" className="text-white fs-5 px-5">
                    About Us
                  </a>
                  <a href="/internship" className="text-white fs-5 px-5">
                    Internship
                  </a>
                  <a href="/gallery" className="text-white fs-5 px-5">
                    Our Gallery
                  </a>
                  <a href="/blog" className="text-white fs-5 px-5">
                    Blog
                  </a>
                </div>
              )}
            </div>
            <div className="dropdown">
              <Link
                // href="javascript:void(0)"
                className="dropdown-toggle text-white fs-5"
                onClick={toggleServicesDropdown}
              >
                Services
              </Link>
              {isServicesDropdownOpen && (
                <div
                  className="dropdown-container p-2"
                  style={{ backgroundColor: "#444" }}
                >
                  <ul className="m-0 p-0" style={{ listStyle: "none" }}>
                    <h6
                      className="text-white fs-5 p-2"
                      style={{ backgroundColor: "red", width: "365px" }}
                    >
                      Web Development
                    </h6>
                    <div className="text-white " style={{ listStyle: "none" }}>
                      <li style={{ fontSize: "10px" }}>
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/wordpress-website-development-services"
                          style={{ fontSize: "15px" }}
                        >
                          Wordpress Website Development
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/static-development-services"
                          style={{ fontSize: "15px" }}
                        >
                          Static Website Development
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        <a
                          className="dropdown-item fw-bold text-white "
                          href="/dyanamic-website-development-services"
                          style={{ fontSize: "15px" }}
                        >
                          Dynamic Website Development
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/drupal-web-development-services"
                          style={{ fontSize: "15px" }}
                        >
                          Drupal Web Development
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/classified-website-development-services"
                          style={{ fontSize: "15px" }}
                        >
                          Classified Website Development
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/landingpage-website-development-services"
                          style={{ fontSize: "15px" }}
                        >
                          Landing Page Website Development
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/e-commerce-development-services"
                          style={{ fontSize: "15px" }}
                        >
                          E-Commerce Development
                        </a>
                      </li>
                    </div>
                  </ul>
                  <ul className="m-0 p-0">
                    <h6
                      className="text-white fs-5 p-2"
                      style={{ backgroundColor: "red", width: "365px" }}
                    >
                      App Development
                    </h6>
                    <div className="text-white " style={{ listStyle: "none" }}>
                      <li style={{ fontSize: "5px" }}>
                        <a
                          className="dropdown-item fw-bold text-white "
                          href="/android-app-development-services"
                          style={{ fontSize: "15px" }}
                        >
                          Android App Development
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        {" "}
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/ios-app-development-services"
                          style={{ fontSize: "15px" }}
                        >
                          IOS App Development
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        <a
                          className="dropdown-item fw-bold text-white "
                          href="/crowdfunding-app-development-services"
                          style={{ fontSize: "15px" }}
                        >
                          Crowdfunding App Development
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        {" "}
                        <a
                          className="dropdown-item fw-bold text-white "
                          href="/hybrid-app-development-services"
                          style={{ fontSize: "15px" }}
                        >
                          Hybrid App Development
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        <a
                          className="dropdown-item fw-bold text-white "
                          href="/cross-platform-app-development-services"
                          style={{ fontSize: "15px" }}
                        >
                          {" "}
                          Cross-Platform App Development
                        </a>
                      </li>
                    </div>
                  </ul>

                  <ul className="m-0 p-0">
                    <h6
                      className="text-white fs-5 p-2"
                      style={{ backgroundColor: "red", width: "365px" }}
                    >
                      Software Development
                    </h6>
                    <div className="text-white " style={{ listStyle: "none" }}>
                      <li style={{ fontSize: "15px" }}>
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/crm-software-development-services"
                          style={{ fontSize: "15px" }}
                        >
                          CRM Software Development
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        {" "}
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/hrm-development-services"
                          style={{ fontSize: "15px" }}
                        >
                          HRM Development
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/erp-management-software-services"
                          style={{ fontSize: "15px" }}
                        >
                          ERP Management Software
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/lms-management-software-services"
                          style={{ fontSize: "15px" }}
                        >
                          LMS Management Software
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/invoice-software-development-services"
                          style={{ fontSize: "15px" }}
                        >
                          Invoice Software Development
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/hotelmanagement-software-services"
                          style={{ fontSize: "15px" }}
                        >
                          Hotel Management Software
                        </a>
                      </li>
                    </div>
                  </ul>

                  <ul className="m-0 p-0">
                    <h6
                      className="text-white fs-5 p-2"
                      style={{ backgroundColor: "red", width: "365px" }}
                    >
                      Digital Marketing
                    </h6>
                    <div className="text-white " style={{ listStyle: "none" }}>
                      <li style={{ fontSize: "15px" }}>
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/mobile-app-marekting-and-advertising-services"
                          style={{ fontSize: "15px" }}
                        >
                          Mobile App Marketing And Advertising
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/seo-smo-service-provider-services"
                          style={{ fontSize: "15px" }}
                        >
                          Seo/SMO Service Provider
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        {" "}
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/it-consultancy-services"
                          style={{ fontSize: "15px" }}
                        >
                          IT Consultancy
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/digital-marketing-services"
                          style={{ fontSize: "15px" }}
                        >
                          Digital Marketing Services
                        </a>
                      </li>
                    </div>
                  </ul>

                  <ul className="m-0 p-0">
                    <h6
                      className="text-white fs-5 p-2"
                      style={{ backgroundColor: "red", width: "365px" }}
                    >
                      Graphic Designing
                    </h6>
                    <div className="text-white " style={{ listStyle: "none" }}>
                      <li style={{ fontSize: "15px" }}>
                        {" "}
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/logo-design-services"
                          style={{ fontSize: "15px" }}
                        >
                          Logo Design
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        {" "}
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/flyer-design-services"
                          style={{ fontSize: "15px" }}
                        >
                          Flyer Design
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        {" "}
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/brochure-design-services"
                          style={{ fontSize: "15px" }}
                        >
                          Brochure Design
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        {" "}
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/packaging-design-services"
                          style={{ fontSize: "15px" }}
                        >
                          Packaging Design
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        {" "}
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/ui-design-services"
                          style={{ fontSize: "15px" }}
                        >
                          UI Design
                        </a>
                      </li>
                    </div>
                  </ul>
                  <ul className="m-0 p-0">
                    <h6
                      className="text-white fs-5 p-2"
                      style={{ backgroundColor: "red", width: "365px" }}
                    >
                      Videography
                    </h6>
                    <div className="text-white " style={{ listStyle: "none" }}>
                      <li style={{ fontSize: "15px" }}>
                        {" "}
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/videoGrapghy-slow-motion"
                          style={{ fontSize: "15px" }}
                        >
                          Slow motion videography
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        {" "}
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/product-slow-motion-videography"
                          style={{ fontSize: "15px" }}
                        >
                          Product slow motion videography for e-commerce
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        {" "}
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/product-photography-normal"
                          style={{ fontSize: "15px" }}
                        >
                          Product photography normal
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        {" "}
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/product-photography-with-content"
                          style={{ fontSize: "15px" }}
                        >
                          Product photography with content
                        </a>
                      </li>
                      <li style={{ fontSize: "15px" }}>
                        {" "}
                        <a
                          className="dropdown-item fw-bold text-white"
                          href="/ecommerce-product-photography"
                          style={{ fontSize: "15px" }}
                        >
                          Ecommerce product photography
                        </a>
                      </li>
                    </div>
                  </ul>
                </div>
              )}
            </div>
            <div className="dropdown">
              <Link
                // href="javascript:void(0)"
                className="dropdown-toggle text-white fs-5"
                onClick={toggleProductsDropdown}
              >
                Our Products
              </Link>
              {isProductsDropdownOpen && (
                <div
                  className="dropdown-container p-1"
                  style={{ backgroundColor: "#444" }}
                >
                  <a href="/schoolmanagement" className="text-white fs-5 px-5">
                    School Management System
                  </a>
                </div>
              )}
            </div>
            <a href="/performance" className="text-white fs-5">
              Performance Marketing
            </a>
            <a href="/portfolio" className="text-white fs-5">
              Portfolio
            </a>
            <a href="/career" className="text-white fs-5">
              Career
            </a>
            <a href="/business" className="text-white fs-5">
              Business-Developer
            </a>
            <a href="/faq" className="text-white fs-5">
              FAQ
            </a>
            <a href="/contact" className="text-white fs-5">
              Contact
            </a>
          </div>

          <div id="main"></div>
        </div>

        {/* ----------------------------------------------//Sidebar Sticky Icon */}
        <div className="sticky-icon" style={{ zIndex: 2 }}>
          <a
            href="https://www.instagram.com/mannixinfotech09/?fbclid=IwAR30ORrOy8gHLBnmw9Nys9CZNYznRUYLyiAXihrlzLH63aVD_y96syb0jJo"
            className="Instagram"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-instagram"></i>Instragram{" "}
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61558304945053"
            className="Facebook"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-facebook-f"> </i> Facebook{" "}
          </a>

          <a
            href="https://www.linkedin.com/company/mannix-infotech-solutions-pvt-ltd/"
            className="LinkedIn"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-linkedin-in"></i> LinkedIn{" "}
          </a>
          <a
            href="https://whatsapp.com/.com/"
            className="Watshapp"
            target="_blank"
            rel="noreferrer"
            onClick={handleWhatsAppClick}
          >
            <i className="fa-brands fa-whatsapp"> </i> WhatsApp{" "}
          </a>
        </div>
      </div>
    </>
  );
};

export default Header;
