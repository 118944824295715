import React, { useState } from "react";
import { Helmet } from "react-helmet";

const ERPManagment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>
          Best School Management Services in Ahmedabad | Mannix Infotech Pvt.
          Ltd.
        </title>
        <meta
          name="description"
          content="Top school management services in Ahmedabad with Mannix Infotech Pvt. Ltd. We offer innovative solutions to manage and optimize school operations effectively."
        />
        <meta
          name="keywords"
          content="School management services in Ahmedabad | Best school management software company in Ahmedabad | Innovative school management system design services | School management solutions development agency in Ahmedabad | Expert school management services in Ahmedabad | Top-rated school management services in Ahmedabad | School management software development for educational institutions | Ahmedabad school management software experts | School management solutions for K-12 schools | School management software company in Ahmedabad with expertise in PowerSchool"
        />

        <link
          rel="canonical"
          href="https://www.mannix.in/erp-management-software-services"
        />
      </Helmet>
      <section className="banner-content-1">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className="position-absolute top-banner-bg"
          viewBox="0 0 1920 761"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
            </defs>
            <clipPath id="SVGID_2_">
              <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
            </clipPath>
            <g className="banner-bg-asw">
              <path
                className="st1"
                d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                            c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
              ></path>
              <path
                className="st2"
                d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                            c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
              ></path>
              <path
                className="st1"
                d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
              ></path>
              <path
                className="st2"
                d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
              ></path>
              <circle className="st1" cx="1536.8" cy="661.3" r="56.2"></circle>
              <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
            </g>
          </g>
        </svg>
        <div className="py-5 container">
          <div className="row align-items-center justify-content-around mt-4">
            <div className="col-lg-6">
              <div className="">
                <h1 className="head-title head-title-without-line m-0 py-3">
                  ERP Software Development
                </h1>
                <p className="m-0 py-3">
                  There comes a point at which business owners are unable to
                  manage every problem on their own. High-level management and
                  extensive experience in the relevant field are required for
                  supply chain, accounting, risk management, and alternative
                  operations. This raises the need for new positions and
                  experienced employees.
                </p>
              </div>
            </div>
            <div className="col-lg-5 prod-section2">
              <div className="">
                <form
                  className="register-form"
                  id="register-form"
                  method="post"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="form_type"
                    id="form_type"
                    value="Android App Development"
                  />
                  <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <label className="w-100">
                          <span>Name</span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div className="w-100">
                        <label className="w-100">
                          <span>Email</span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email id *"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label className="w-100">
                        <span>Contact</span>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Contact No *"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Country</span>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Message</span>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter your message *"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        name="submit"
                        className="submit btn w-100 g-recaptcha-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="hire-dedicated services mt-5">
        <section>
          <div className="hire-dedicated-2 container py-4">
            <div className="pb-4 text-center">
              <h2 className="head-title">Modules of ERP Software</h2>
            </div>
            <div className="row">
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-bars"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">
                        Inventory and warehouse management
                      </h2>
                      <p className="m-0 py-2">
                        The experimental reports pertaining to non-capitalized
                        stock and assets are generated by this module. The state
                        of the record's finances and availability chain must be
                        ascertained. This module reduces complexity, tracks the
                        stock account, and provides an effective work flow.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-bars"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fab fa-searchengin"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">
                        Customer relationship management
                      </h2>
                      <p className="m-0 py-2">
                        In addition to offering great customer service and
                        boosting productivity, this module gives access to the
                        company's revenue performance.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fab fa-searchengin"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-child"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">Human Resource Management</h2>
                      <p className="m-0 py-2">
                        Basic HR functions like time tracking and profile
                        filtering are automated by this module. A laborious task
                        is completed by it. Self-service portals facilitate
                        communication and expedite the approval process, hence
                        increasing operational efficiency. Additionally,
                        performance management tracking gets simple.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-child"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-user-friends"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">
                        {" "}
                        Accounting and financial management
                      </h2>
                      <p className="m-0 py-2">
                        {" "}
                        This module assists you in maintaining process
                        organization and productivity in your firm. It tracks
                        earnings and automates financial transactions.
                        Periodically, it creates personalized reports to
                        efficiently handle company information.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-user-friends"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="why_choose_online why_choose_blockchain">
        <div className="container">
          <div className="why_choose_title why_choose_us">
            Why is ERP software crucial for your business?
          </div>
          <br />
          <div className="row">
            <div className="col-sm-4">
              <div className="choose_food choose_food_2">
                <h3>EFFICIENCY</h3>
                <p className="text-justify">
                  You'll be able to get rid of tedious tasks with the help of
                  our ERP development firm. The usage of ERP reduces the need
                  for manual data entry. The system facilitates data collection
                  by streamlining corporate procedures in this way.
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="choose_food choose_food_2">
                <h3>SECURITY</h3>
                <p className="text-justify">
                  Improved data constraints result from improved data
                  correctness, consistency, and security with a new operating
                  system. To protect data privacy, software testing and quality
                  assurance are part of the corporate software development
                  process.
                </p>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="choose_food choose_food_2">
                <h3>CUSTOMER SERVICE</h3>
                <p className="text-justify">
                  When you get in touch with us for ERP application development,
                  we offer an infrastructure that makes it much simpler for
                  salespeople and customer support to engage with clients and
                  allows you to experience excellent customer service.
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="choose_food choose_food_2">
                <h3>INTEGRATED INFORMATION</h3>
                <p className="text-justify">
                  You can access all the information in one location and
                  eliminate the need to split the data up into multiple
                  databases by using our ERP solutions. In this manner, the data
                  is current and consistent.
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="choose_food choose_food_2">
                <h3>REPORTS</h3>
                <p className="text-justify">
                  Your company can simply respond to complex data demands with
                  improved reporting capabilities. Developers of ERP software
                  make sure that users can run their reports independently of IT
                  support.
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="choose_food choose_food_2">
                <h3>REDUCED OPERATING COSTS</h3>
                <p className="text-justify">
                  Yes, among the most obvious advantages of putting an ERP in
                  place are lower operating costs. These include lower costs for
                  production, advertising, and marketing, as well as lower costs
                  for inventory control.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="company-offer3">
        <div className="container">
          <div className="theme-title">
            {" "}
            <strong>
              What makes Mannix Infotech Solutions Pvt.Ltd. the best ERP
              Software Development Company?
            </strong>
            <p className="text-justify">
              For many years, Mannix Infotech systems Pvt.Ltd. has offered ERP
              systems. We are regarded as among the top developers of ERP
              software. You have come to the correct place if you're searching
              for cutting-edge features in your ERP software. We provide
              solutions that can help you improve the efficiency of your
              business operations and are simple to incorporate into your
              existing HRIS. The most valuable asset of every firm is its
              resource pool, which must be properly managed with patience and
              effort. With ERP application development professionals at your
              disposal, we guarantee that your resources are never squandered.
            </p>
            <p className="text-justify">
              Here we share some distinctive points that make us the best in the
              market:
            </p>
            <ul className="list-style-blt">
              <li>Personalized advice.</li>
              <li>On-time delivery.</li>
              <li>Expert team.</li>
              <li>End-to-end services.</li>
              <li>Best price.</li>
              <li>Easy customizations.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ERPManagment;
