import React, { useState } from "react";
import { Helmet } from "react-helmet";

const AndroidAppDevelopment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>Top Mobile App Development Services in Ahmedabad</title>
        <meta
          name="description"
          content="Discover top mobile app development services in Ahmedabad with Mannix Infotech Pvt. Ltd. We create innovative and user-friendly mobile apps tailored to your business needs.
"
        />
        <meta
          name="keywords"
          content="Mobile app development services in Ahmedabad | Best mobile app development company in Ahmedabad | Innovative mobile app design services | User-friendly mobile app development agency | Custom mobile app development company | Expert mobile app development services in Ahmedabad | Top-rated mobile app development services in Ahmedabad | Mobile app development for business growth | Ahmedabad mobile app development experts | Mobile app solutions for business needs
"
        />
        <link
          rel="canonical"
          href="https://www.mannix.in/android-app-development-services"
        />
      </Helmet>
      <div className="hire-dedicated services mt-0">
        <section className="banner-content-1">
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            className="position-absolute top-banner-bg"
            viewBox="0 0 1920 761"
          >
            <g>
              <defs>
                <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
              </defs>
              <clipPath id="SVGID_2_">
                <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
              </clipPath>
              <g className="banner-bg-asw">
                <path
                  className="st1"
                  d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                        c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
                ></path>
                <path
                  className="st2"
                  d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                        c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
                ></path>
                <path
                  className="st1"
                  d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
                ></path>
                <path
                  className="st2"
                  d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
                ></path>
                <circle
                  className="st1"
                  cx="1536.8"
                  cy="661.3"
                  r="56.2"
                ></circle>
                <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
              </g>
            </g>
          </svg>
          <div className="py-5 container">
            <div className="row align-items-center justify-content-around mt-4">
              <div className="col-lg-6">
                <div className="">
                  <h1 className="head-title head-title-without-line m-0 py-3">
                    Android App Development
                  </h1>
                  <p className="m-0 py-3">
                    The best mobile applications are those that work well and
                    offer incredible features. A modern, user-friendly method of
                    integrating technology for mobile app development that
                    increases audience omnipresence is through Android App
                    Development Companies. With their expertise and talented
                    developers, Mannix Infotech Solutions Pvt.Ltd. can produce
                    powerful, captivating, high-performing Android app solutions
                    that include all the newest features and technologies. Our
                    team comprises top industry experts who provide
                    comprehensive customisation and integration services to
                    transform concepts into reality.
                  </p>
                  <p className="m-0 py-3">
                    We have years of experience in a variety of industries and
                    can create and execute feature-rich, scalable, and
                    captivating mobile apps that work seamlessly on a variety of
                    platforms.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 prod-section2">
                <div className="">
                  <form
                    className="register-form"
                    id="register-form"
                    method="post"
                    action=""
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="hidden"
                      name="form_type"
                      id="form_type"
                      value="Android App Development"
                    />
                    <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                      <div className="d-flex">
                        <div className="w-100 mr-2">
                          <label className="w-100">
                            <span>Name</span>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Name *"
                              className="form-control"
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                        <div className="w-100">
                          <label className="w-100">
                            <span>Email</span>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email id *"
                              className="form-control"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <label className="w-100">
                          <span>Contact</span>
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="Contact No *"
                            className="form-control"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Country</span>
                          <input
                            type="text"
                            name="country"
                            id="country"
                            placeholder="Enter your Country"
                            className="form-control"
                            value={formData.country}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Message</span>
                          <textarea
                            name="message"
                            id="message"
                            placeholder="Enter your message *"
                            className="form-control"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </label>
                      </div>

                      <div className="">
                        <button
                          type="submit"
                          name="submit"
                          className="submit btn w-100 g-recaptcha-btn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="android-app- mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="android-app-img">
                <video
                  className="main-video resource-retina img-fluid "
                  src="../Images/WhatsApp Video 2.mp4"
                  type="video/mp4"
                  loop="true"
                  autoplay="true"
                  muted="true"
                  playsinline="true"
                ></video>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="android-app-text pt-5">
                <h2>Android App Development </h2>
                <br />
                <p className="text-justify">
                  Mobile applications that seamlessly function and should
                  proffer amazing functionalities are pre-eminent. Android App
                  Development is a futuristic friendly way of weaving technology
                  for&nbsp; mobile app development that boosts the omnipresence
                  of the audience. Mannix Infotech Solutions Pvt.Ltd. experience
                  and skilled developers can create robust, engaging,
                  high-performance, android app solutions that come with all
                  advanced features and technologies. We have in-house best
                  talents from the industry who proffer end-to-end customization
                  and integration services to bring ideas into reality.&nbsp;
                  <br />
                  With years of experience in diverse industries, we can craft
                  and implement engaging, scalable, and feature-rich mobile apps
                  that can easily run across multiple platforms.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services-android-app d-block">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="services-android-app-title">
                <div className="section-title pb-5">
                  <h2 className="android_title">
                    Android App Development Services
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <ul className="services-android_list">
                <li>
                  <div className="services-app-item">
                    <div className="socal-icons">
                      {" "}
                      <img
                        src="../Images/applogo1.png"
                        alt="Mannix Infotech Android application design Android App Development/applogo1"
                      />{" "}
                    </div>
                    <div className="app-item-text">
                      <h3>Design of Android applications</h3>
                      <p className="text-justify">
                        Online solutions must be able to capture users'
                        attention. Our developers offer an interactive user
                        experience and have a high level of expertise with UI
                        and UX components.{" "}
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="services-app-item">
                    <div className="socal-icons">
                      {" "}
                      <img
                        src="../Images/applogo2.png"
                        alt="Mannix Infotech Development of custom Android applications Android App Development/applogo2"
                      />{" "}
                    </div>
                    <div className="app-item-text">
                      <h3>creation of unique Android apps</h3>
                      <p className="text-justify">
                        We provide knowledgeable, individualized services for
                        developing Android applications. We develop apps using
                        our specialists in accordance with your ideas. To
                        satisfy service needs, our team assists you in
                        integrating applications with necessary capabilities.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="services-app-item">
                    <div className="socal-icons">
                      {" "}
                      <img
                        src="../Images/applogo3.png"
                        alt="Mannix Infotech Consulting the Android application Android App Development/applogo3"
                      />{" "}
                    </div>
                    <div className="app-item-text">
                      <h3>
                        The Android app Mannix Infotech Consulting Development
                        of Android Apps
                      </h3>
                      <p className="text-justify">
                        Experts on the Android app can assist you in making
                        important and crucial business decisions. With
                        suggestions and recommendations based on the newest
                        trends, cutting-edge features, and technology, we assist
                        you in launching your idea.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="services-app-item">
                    <div className="socal-icons">
                      {" "}
                      <img
                        src="../Images/applogo4.png"
                        alt="Mannix Infotech Enterprise Android Apps Android App Development/applogo4"
                      />{" "}
                    </div>
                    <div className="app-item-text">
                      <h3>Business-Use Android Applications</h3>
                      <p className="text-justify">
                        It is imperative for businesses to release their apps
                        through the Google Play Store. It enables them to reach
                        a wide audience and adapt to contemporary digital
                        trends.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="services-app-item">
                    <div className="socal-icons">
                      {" "}
                      <img
                        src="../Images/applogo1.png"
                        alt="Mannix Infotech Maintenance of Android applications Android App Development/applogo1"
                      />{" "}
                    </div>
                    <div className="app-item-text">
                      <h3>Upkeep of Android programs</h3>
                      <p className="text-justify">
                        To remain ahead of the competition in the market, we
                        assist you in maintaining Android apps using the most
                        recent and cutting-edge trends and technology.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="hire-dedicated services mt-0">
        <section>
          <div className="hire-dedicated-3 container py-4">
            <div className="pb-4 text-center">
              <h2 className="head-title">Technologies Used</h2>
            </div>
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fab fa-android"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Android SDK</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process ">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-tools"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">ADT bundle</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fab fa-cuttlefish"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Objective C</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fab fa-swift"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Swift</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-hammer"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">XCode</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-coffee"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Cocoa Touch Framework</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fab fa-app-store-ios"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">iPhone SDK</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb_90">
          <div className="hire-dedicated-3 container py-4">
            <div className="pb-4 text-center">
              <h2 className="head-title">Industries We Serve</h2>
            </div>
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-hiking"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Travel &amp; Tourism</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process ">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-sign"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Real estate property </h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-hotel"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Hotels</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-store"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Restaurants</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-id-badge"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Employment</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-shopping-cart"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">E-Commerce</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-book"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Education</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-child"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Entertainment</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-hospital-user"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Healthcare</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default AndroidAppDevelopment;
