import React, { useState } from "react";
import { Helmet } from "react-helmet";

const BrochureDesign = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>
          Best Latest Brochure Design Services in Ahmedabad | Mannix Infotech
          Pvt. Ltd.
        </title>
        <meta
          name="description"
          content="Discover the best latest brochure design services in Ahmedabad with Mannix Infotech Pvt. Ltd. We craft visually appealing brochures to showcase your products and services."
        />
        <meta
          name="keyword"
          content="Brochure design services in Ahmedabad |
  Best brochure design company in Ahmedabad |
    Brochure design services provider in Ahmedabad |
    Expert brochure design services in Ahmedabad |
Top-rated brochure design services in Ahmedabad|
Brochure design services for startups|
Ahmedabad brochure design experts|
Brochure design solutions for businesses|	
	Brochure design company in Ahmedabad with expertise in graphic design|
Brochure design services for marketing campaigns"
        />
        <link
          rel="canonical"
          href="https://www.mannix.in/brochure-design-services"
        />
      </Helmet>
      <section className="banner-content-1 pb-5">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className="position-absolute top-banner-bg"
          viewBox="0 0 1920 761"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
            </defs>
            <clipPath id="SVGID_2_">
              <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
            </clipPath>
            <g className="banner-bg-asw">
              <path
                className="st1"
                d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                            c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
              ></path>
              <path
                className="st2"
                d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                            c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
              ></path>
              <path
                className="st1"
                d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
              ></path>
              <path
                className="st2"
                d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
              ></path>
              <circle className="st1" cx="1536.8" cy="661.3" r="56.2"></circle>
              <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
            </g>
          </g>
        </svg>
        <div className="py-5 container">
          <div className="row align-items-center justify-content-around mt-4">
            <div className="col-lg-6">
              <div className="">
                <h1 className="head-title head-title-without-line m-0 py-3">
                  Brochure Design
                </h1>
                <p className="m-0 py-3">
                  We are Mannix Infotech Solutions Pvt.Ltd. offering
                  state-of-the-art brochure design services. We are an Indian
                  Creative Brochure Design company providing you with a holistic
                  range of design services. Our design competence extends to
                  areas such as designing brochures or flyers for marketing,
                  sales, etc. We believe that designing brochures is not just
                  about creativity and innovation, but also about skill. A mix
                  of all of the above and years of knowledge in the field has
                  made us a sought-after company.
                </p>
              </div>
            </div>
            <div className="col-lg-5 prod-section2">
              <div className="">
                <form
                  className="register-form"
                  id="register-form"
                  method="post"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="form_type"
                    id="form_type"
                    value="Android App Development"
                  />
                  <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <label className="w-100">
                          <span>Name</span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div className="w-100">
                        <label className="w-100">
                          <span>Email</span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email id *"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label className="w-100">
                        <span>Contact</span>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Contact No *"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Country</span>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Message</span>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter your message *"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        name="submit"
                        className="submit btn w-100 g-recaptcha-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="phone_home_sec mt-5 mb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="text-center">
                {" "}
                <img
                  src="../Images/BrochureDesign.png"
                  alt="BrochureDesign"
                  width="80%"
                />{" "}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="p_s_home_text">
                <p className="animate__animated animate__bounce fs-1">
                  {" "}
                  Brochure <span>Design</span>{" "}
                </p>
                <p className="text-justify">
                  {" "}
                  There are hundreds of logo design services that want your
                  money's worth - and some even claim to be free. But not all of
                  these services will give you a logo that looks professional.
                  You don't want a logo that appears as a child created it, and
                  you do not want to have to go through a complex and lengthy
                  process to get a final product that meets all of your
                  requirements. We provide high-quality professional logo design
                  for you at the best price.
                </p>
                <div className="pg_home_btn">
                  {" "}
                  <a href="/">Contact now</a>{" "}
                </div>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hire_gap_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="hire_gap_content">
                <h2>
                  Brochure <span>Design</span>{" "}
                </h2>
                <p className="text-justify">
                  Mannix Infotech Solutions Pvt.Ltd., which features brochure
                  design in India, wholeheartedly advocates smart design. Our
                  designs are a complete set. They include the perfect layout,
                  effective delivery of the business message, and observing the
                  needs of the target audience. Our team of designers work day
                  in and day out to determine the most appropriate theme for
                  your business needs. Contact our brochure design company and
                  obtain all the above mentioned services in one package.
                </p>
                <p className="text-justify">
                  We want to serve you the maximum amount possible. Therefore,
                  our design business brochure comes with varied designs, as
                  well as flyers, with distinctive folds and cuts, furthermore
                  as embossed brochures.
                </p>
                <div className="theme-title theme_solution">
                  <h4>Why should you hire us instead of others?</h4>
                  <ul className="list-style-blt">
                    <li> Creative minds</li>
                    <li> Brand focused</li>
                    <li> Extensive experience</li>
                    <li> Reasonable costs</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right_content">
                <h4>Our Brochure Design services include!</h4>
                <div className="hire_a_listing">
                  <ul>
                    <li>
                      <div className="hire_icons">
                        <img src="../Images/brochure1.png" alt="brochure1" />
                      </div>
                      <h5>Business Brochure Design</h5>
                      <p className="text-justify">
                        {" "}
                        A business brochure is an ideal marketing tool to help
                        you create a lasting impression of your brand and
                        improve your company's visibility in the market.
                      </p>
                    </li>
                    <li>
                      <div className="hire_icons">
                        <img src="../Images/brochure2.png" alt="brochure2" />
                      </div>
                      <h5>
                        Product Catalog <br />
                        Design
                      </h5>
                      <p className="text-justify">
                        {" "}
                        Product catalogs help you inform your target audience
                        about your product line and their features in a unique
                        and eye-catching way.
                      </p>
                    </li>
                    <li>
                      <div className="hire_icons">
                        <img src="../Images/brochure3.png" alt="brochure3" />
                      </div>
                      <h5>Design of sales Brochures</h5>
                      <p className="text-justify">
                        Notify your customers of new offers, sales or special
                        promotions related to your products or services.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="hire-dedicated services mt-0">
        <section className="">
          <div className="hire-dedicated-2 container py-4">
            <div className="pb-4 text-center">
              <h2 className="head-title">
                Different types of brochure designs
              </h2>
            </div>
            <div className="row justify-content-center">
              <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-user-circle"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">Tri-fold brochures</h2>
                      <p className="m-0 py-2">
                        As the name suggests, these brochures have 3 flaps (6
                        panels) and offer more space to present any information.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-user-circle"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-cube"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">Bifold brochures</h2>
                      <p className="m-0 py-2">
                        Bifold brochures have 2 flaps (4 panels). It looks like
                        a booklet and is quite inexpensive to print.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-cube"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-chart-area"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">Folded brochures at the door</h2>
                      <p className="m-0 py-2">
                        These brochures fold inward like a door on a large
                        billboard and are primarily used in high end marketing
                        campaigns.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-chart-area"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-palette"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">Four panel roll fold</h2>
                      <p className="m-0 py-2">
                        Commonly used for step-by-step instructions or
                        educational materials, this is a layout that folds in on
                        itself.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-palette"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-file-powerpoint"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">Z-fold brochure</h2>
                      <p className="m-0 py-2">
                        These brochures fold into a "Z" pattern and are ideal
                        for wowing readers with a stylish and unique appearance.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-file-powerpoint"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-icons"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">
                        Insert and organize brochures
                      </h2>
                      <p className="m-0 py-2">
                        Also known as pocket or folder brochures, they contain
                        spaces where inserts of relevant products can be placed.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-icons"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BrochureDesign;
