import React, { useState } from "react";
import { Helmet } from "react-helmet";

const ClassifiedDevlopment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>Classified Website Development Services in Ahmedabad</title>
        <meta
          name="description"
          content="Top classified website development services in Ahmedabad by Mannix InfotechPvt. Ltd. We build customized, feature-rich classified platforms to enhance your online business.
"
        />
        <meta
          name="keywords"
          content="Classified website development services in Ahmedabad | Best classified web development company in Ahmedabad | Custom classified website design services | Feature-rich classified website development agency | Classified platform development for online business | Expert classified website development services in Ahmedabad | Top-rated classified web development services in Ahmedabad | Customized classified website solutions | Professional classified website development company | Ahmedabad classified website development experts
"
        />
        <link
          rel="canonical"
          href="https://www.mannix.in/classified-website-development-services"
        />
      </Helmet>
      <div className="hire-dedicated services mt-0">
        <section className="banner-content-1">
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            className="position-absolute top-banner-bg"
            viewBox="0 0 1920 761"
          >
            <g>
              <defs>
                <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
              </defs>
              <clipPath id="SVGID_2_">
                <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
              </clipPath>
              <g className="banner-bg-asw">
                <path
                  className="st1"
                  d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                        c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
                ></path>
                <path
                  className="st2"
                  d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                        c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
                ></path>
                <path
                  className="st1"
                  d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
                ></path>
                <path
                  className="st2"
                  d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
                ></path>
                <circle
                  className="st1"
                  cx="1536.8"
                  cy="661.3"
                  r="56.2"
                ></circle>
                <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
              </g>
            </g>
          </svg>
          <div className="py-5 container">
            <div className="row align-items-center justify-content-around mt-4">
              <div className="col-lg-6">
                <div className="">
                  <h1 className="head-title head-title-without-line m-0 py-3">
                    Classified Website Development
                  </h1>
                  <p className="m-0 py-3">
                    A classified website is a convenient location for you to
                    post advertisements for your goods or services if you want
                    to advance your company in the field of digital marketing.
                  </p>
                  <p className="m-0 py-3">
                    At a reasonable price, Mannix Infotech Solutions Pvt. Ltd.
                    provides our global clientele with a very professional
                    classified website set.
                  </p>
                  <p className="m-0 py-3">
                    You've always considered rated websites' reach. There's
                    always an opportunity to manage a highly ranked website on
                    the internet, learn about the drawbacks of an
                    already-existing site, and get in touch with the top
                    classified website construction company.
                  </p>
                  <p className="m-0 py-3">
                    The primary benefit of online classifieds websites over
                    newspapers or magazines is their ability to draw large
                    audiences through the use of internet technology.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 prod-section2">
                <div className="">
                  <form
                    className="register-form"
                    id="register-form"
                    method="post"
                    action=""
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="hidden"
                      name="form_type"
                      id="form_type"
                      value="Android App Development"
                    />
                    <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                      <div className="d-flex">
                        <div className="w-100 mr-2">
                          <label className="w-100">
                            <span>Name</span>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Name *"
                              className="form-control"
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                        <div className="w-100">
                          <label className="w-100">
                            <span>Email</span>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email id *"
                              className="form-control"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <label className="w-100">
                          <span>Contact</span>
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="Contact No *"
                            className="form-control"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Country</span>
                          <input
                            type="text"
                            name="country"
                            id="country"
                            placeholder="Enter your Country"
                            className="form-control"
                            value={formData.country}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Message</span>
                          <textarea
                            name="message"
                            id="message"
                            placeholder="Enter your message *"
                            className="form-control"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </label>
                      </div>

                      <div className="">
                        <button
                          type="submit"
                          name="submit"
                          className="submit btn w-100 g-recaptcha-btn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="container">
        <div className="theme-title" style={{ fontSize: "18px" }}>
          {" "}
          <strong>
            Features and Benefits of having a classNameified Website –
          </strong>
          <ul className="list-style-blt">
            <li>SEO features with advanced catalog management.</li>
            <li>
              The uses of latest technologies and platforms such as Php, Java,
              WordPress, etc.
            </li>
            <li>Outstanding Promotion on top ranked websites.</li>
            <li>Generating high income and also to get more visibility.</li>
            <li>
              Front-end features such as featured ads, custom ad fields as well
              as others.
            </li>
            <li>
              The content management opstem which includes images and banner
              ads.
            </li>
            <li>Integration of payment gateway.</li>
            <li>
              Various promotions feature classNameified website development.
            </li>
            <li>
              Administration panel with user login to manage users activate /
              deactivate, modify / delete account as well as information.
            </li>
          </ul>
        </div>
      </div>
      <section className="why_choose_online why_choose_blockchain">
        <div className="container" style={{ marginBottom: "70px" }}>
          <div className="why_choose_title why_choose_us">
            Why Are classNameified Websites A Benefit For The Business Owner?
          </div>
          <div className="row">
            <div className="col-sm-3">
              <div className="choose_food-1">
                <h3>
                  <b>Put on your client’s shoes:</b>
                </h3>
                <p className="text-justify">
                  In online classNameifieds websites, shape clients
                  satisfactorily and creditably online. The touting website
                  rates and reviews the customers for buying and selling the
                  products on the sites. This creates a trustworthy framework
                  and prominent views for the customer on the online website.
                </p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="choose_food-1">
                <h3>
                  <b>A Great Marketing Channel:</b>
                </h3>
                <p className="text-justify">
                  By just having a ranked website, you can market your products
                  and reach thousands of people. It is a crowded and highly
                  competitive field, with small businesses fighting tooth and
                  nail to gain the attention of potential customers. At the risk
                  of stating the apparent, a high quality web site is crucial if
                  you would like your business to exceed its potential.
                </p>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="choose_food-1">
                <h3>
                  <b>Interacting with Your Customers:</b>
                </h3>
                <p className="text-justify">
                  Whatever your reason for moving your online classNameifieds
                  websites, you can interact with your customers anytime and
                  from anywhere. This can be through new updates, live chat,
                  email conversations, or comments on your webpage. You will be
                  available to offer your expert advice and prove your
                  credibility at all times.
                </p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="choose_food-1">
                <h3>
                  <b>A support to present your products:</b>
                </h3>
                <p className="text-justify">
                  Whatever type of products you are in; a website is a great way
                  to showcase your particular product. You can show why your
                  business is unique and provide feedback on your products. Take
                  eBay, for example - most buyers base their opinion on the
                  seller after reading recent testimonials.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="company-offer3">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="theme-title">
                {" "}
                <strong>Buyer (the one who buys the products)</strong>
                <ul className="list-style-blt">
                  <li>
                    Multiple registrations:{" "}
                    <p className="text-justify">
                      Multiple logins such as email, Facebook will cause many
                      users to frequently use your apps. Integrating this
                      functionality into your business will therefore have a
                      significant impact on it.
                    </p>
                  </li>
                  <li>
                    Advanced search and filter:
                    <p className="text-justify">
                      From the pile of products, the user can be traced using a
                      filter option such as price, distance, date, duration. So
                      users can effortlessly surf the products without any mess
                      to use your web applications.
                    </p>
                  </li>
                  <li>
                    Multiple Product Category:
                    <p className="text-justify">
                      Similar products are combined into a group with these
                      options from multiple categories, such as cars, home,
                      fashion and many more. This feature helps the user to
                      explore the product with precision.
                    </p>
                  </li>
                  <li>
                    Message carrier:{" "}
                    <p className="text-justify">
                      User can chat with reseller to show interest in products
                      and most wanted functionality for user to have friendly
                      agreements with message carrier.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="theme-title">
                {" "}
                <strong>Seller (the one who sells the products)</strong>
                <ul className="list-style-blt">
                  <li>
                    Rating and Reviews:
                    <p className="text-justify">
                      Rating and Reviews can increase sales and improve seller’s
                      quality products. One of the best feature, sellers can
                      improve their status with buyers’ favorite products.
                    </p>
                  </li>
                  <li>
                    Product Category:
                    <p className="text-justify">
                      Product Category helps the buyer to explore the product
                      accurately when the seller can combine similar products
                      into a group with these options from multiple categories
                      such as cars, home, fashion and many more. others.
                    </p>
                  </li>
                  <li>
                    Message support:
                    <p className="text-justify">
                      After the buyer’s chat, the seller can process their
                      product through this most requested message support.
                      User-friendly features make your profile bigger.
                    </p>
                  </li>
                  <li>
                    Verified Users:
                    <p className="text-justify">
                      Seller can verify all buyer profiles are created on your
                      website using Gmail or Facebook. The most reliable for the
                      seller to have healthy purchases for his products.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassifiedDevlopment;
