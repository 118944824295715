import React, { useState } from "react";
import { Helmet } from "react-helmet";

const MobileAppMarketing = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>
          Top Mobile App Marketing & Advertising Services in Ahmedabad, India
        </title>
        <meta
          name="description"
          content="Top mobile app marketing and advertising services in Ahmedabad, India. Mannix Infotech Pvt. Ltd. helps you boost app visibility and engagement with targeted strategies."
        />
        <meta
          name="keywords"
          content="Mobile app marketing services in Ahmedabad | Best mobile app marketing company in Ahmedabad | Mobile app advertising agency in Ahmedabad | Expert mobile app marketing services in Ahmedabad | Top-rated mobile app marketing services in Ahmedabad | Mobile app marketing strategy for startups | Ahmedabad mobile app marketing experts | Mobile app advertising solutions for businesses | Mobile app marketing company in Ahmedabad with expertise in Google Ads | Mobile app marketing services for e-commerce apps"
        />

        <link
          rel="canonical"
          href="https://www.mannix.in/mobile-app-marekting-and-advertising-services"
        />
      </Helmet>
      <section className="banner-content-1">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className="position-absolute top-banner-bg"
          viewBox="0 0 1920 761"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
            </defs>
            <clipPath id="SVGID_2_">
              <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
            </clipPath>
            <g className="banner-bg-asw">
              <path
                className="st1"
                d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                            c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
              ></path>
              <path
                className="st2"
                d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                            c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
              ></path>
              <path
                className="st1"
                d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
              ></path>
              <path
                className="st2"
                d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
              ></path>
              <circle className="st1" cx="1536.8" cy="661.3" r="56.2"></circle>
              <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
            </g>
          </g>
        </svg>
        <div className="py-5 container">
          <div className="row align-items-center justify-content-around mt-4">
            <div className="col-lg-6">
              <div className="">
                <h1 className="head-title head-title-without-line m-0 py-3">
                  Mobile App Marketing
                </h1>
                <p className="m-0 py-3">
                  Smartphones have replaced desktop computers in the world.
                  Nowadays, companies are presented with an increasing number of
                  daily opportunities that have the power to fundamentally alter
                  how their service or product is provided to and used by
                  potential clients. Having a mobile app is a fantastic method
                  to connect with these specific customers. Every day, thousands
                  of new apps are added to app stores, but the majority of them
                  are never downloaded and some are even removed by users after
                  just one use. The marketing plan you choose will determine how
                  well your software sells.
                </p>
              </div>
            </div>
            <div className="col-lg-5 prod-section2">
              <div className="">
                <form
                  className="register-form"
                  id="register-form"
                  method="post"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="form_type"
                    id="form_type"
                    value="Android App Development"
                  />
                  <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <label className="w-100">
                          <span>Name</span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div className="w-100">
                        <label className="w-100">
                          <span>Email</span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email id *"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label className="w-100">
                        <span>Contact</span>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Contact No *"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Country</span>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Message</span>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter your message *"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        name="submit"
                        className="submit btn w-100 g-recaptcha-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mobile_app_ma mt-5  mb-5 pb-5">
        <div className="container pt-5">
          <div className="row">
            <div className="mobile_app_ma_left">
              <h2>
                Mobile App Marketing &amp; Advertising{" "}
                <span className="heading-border"></span>
              </h2>
              <p className="text-justify">
                The world has gone from desktops to smartphones. Businesses are
                now faced with more and more opportunities every day that can
                drastically change the way their service or product is delivered
                and accessed by their potential customers. Owning a mobile app
                is a great way to reach these targeted consumers. Thousands of
                apps are launched on app stores every day, but the truth is, a
                lot of them never get downloaded and some of them even get
                deleted by users after first use. The success of your app
                depends on the selling strategy you follow.
              </p>
            </div>
            <div className="mobile_app_ma_right">
              <img
                src="./MobileAppMarketing&Advertising.png"
                width="300px"
                alt="MobileAppMarketing&Advertising"
                className="img-fluid"
              />{" "}
            </div>
          </div>
        </div>
      </section>
      <section className="mobile-app-sr">
        <div className="container">
          <div className="mobile-app-sr-outer">
            <h2>Mobile App Marketing Services </h2>
            <div className="heading-border">
              {" "}
              <span></span>{" "}
            </div>
            <ul>
              <li>
                {" "}
                <span>
                  <img src="../Images/mobile1.png" alt="mobile1" />
                </span>
                <h5>App Store Optimization</h5>
                <p className="text-justify">
                  Most of your users will use their favorite app store to find
                  the app they need or want to try. Most of them may not even
                  know the name of your app, and they will search for your app's
                  functions and features.{" "}
                </p>
              </li>
              <li>
                {" "}
                <span>
                  <img src="../Images/mobile2.png" alt="mobile2" />
                </span>
                <h5>Growth Marketing</h5>
                <p className="text-justify">
                  When you are in the mobile app business, continuous growth is
                  the only option, which means you will need to use the best
                  mobile app promotion tactics.{" "}
                </p>
              </li>
              <li>
                {" "}
                <span>
                  <img src="../Images/mobile3.png" alt="mobile3" />
                </span>
                <h5>Management of mobile advertising campaigns</h5>
                <p className="text-justify">
                  Managing monthly PPC campaigns on search engines and social
                  media websites and in-app ads can be a hugely expensive affair
                  if not closely monitored and optimized.{" "}
                </p>
              </li>
              <li>
                {" "}
                <span>
                  <img src="../Images/mobile4.png" alt="mobile4" />
                </span>
                <h5>Pre-launch app promotion</h5>
                <p className="text-justify">
                  We will create a positive buzz ahead of your mobile app launch
                  so that people are already waiting to download and install it
                  the moment your ads appear on Google Play and the Apple App
                  Store.{" "}
                </p>
              </li>
              <li>
                {" "}
                <span>
                  <img src="../Images/mobile5.png" alt="mobile5" />
                </span>
                <h5>Mobile application public relations services</h5>
                <p className="text-justify">
                  PR drills should be done before launch, after launch, and even
                  when your app has been on the list for a while. Our marketers
                  will help you get featured in various new syndication services
                  and press release websites.{" "}
                </p>
              </li>
              <li>
                {" "}
                <span>
                  <img src="../Images/mobile6.png" alt="mobile6" />
                </span>
                <h5>Market research</h5>
                <p className="text-justify">
                  What operating opstem are people using for the type of mobile
                  app you're planning to build? How are other mobile apps in a
                  similar category fair?
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="working-model">
        <div className="container">
          <div className="working-model-outer">
            <div className="working-model-left">
              <div className="working-content text-dark">
                <h2>Our working model to promote mobile application-</h2>
              </div>
              <div className="working-image">
                <img
                  src="../Images/WorkingMobileApplication.png"
                  className="img-fluid"
                  alt="WorkingMobileApplication"
                />
              </div>
            </div>
            <div className="working-model-right">
              <ul>
                <li className="active">
                  <span>
                    <img
                      src="../Images/mobile7.png"
                      className="img-fluid"
                      alt="mobile7"
                    />
                  </span>
                  <h5>Application Marketing Consulting</h5>
                  <p className="text-justify">
                    We provide application selling consulting services to help
                    companies perceive the advantages of application promotion.
                    Our unique strategy helps us create personalized marketing
                    solutions that are sure to generate positive results.
                  </p>
                </li>
                <li>
                  <span>
                    <img
                      src="../Images/mobile8.png"
                      className="img-fluid"
                      alt="mobile8"
                    />
                  </span>
                  <h5>Pre-launch app marketing</h5>
                  <p className="text-justify">
                    For the made launch of your app, we have a tendency to
                    produce a pre-launch selling campaign once auditing the app
                    and researching your target users and competitors. we have a
                    tendency to begin early, produce an internet site for your
                    app, begin blogging, connect with users through email
                    selling, and build buzz with social media.
                  </p>
                </li>
                <li>
                  <span>
                    <img
                      src="../Images/mobile8.png"
                      className="img-fluid"
                      alt="mobile8"
                    />
                  </span>
                  <h5>App Store Optimization</h5>
                  <p className="text-justify">
                    We optimize the mobile app to rank higher in the search
                    results of an app store. The higher your app ranks in an
                    exceedingly store's search results, the additional visible
                    it's to potential users.We make sure the app meets the
                    store's guidelines and ranking criteria so that it rises to
                    the top and gets more installs.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="company-offer3 pt-5">
        <div className="container">
          <div className="theme-title">
            {" "}
            <strong>WHY CHOOSE US- </strong>
            <p className="text-justify">
              Mannix Infotech Solutions Pvt.Ltd. is a household name in the
              mobile app marketing world. We have experts and marketing
              strategists who can help you execute your app campaign with
              excellence and perfection. Here are some of the reasons why you
              should consider using our app marketing:
            </p>
            <p className="text-justify">
              Here we share some distinctive points that make us the best in the
              market:
            </p>
            <ul className="list-style-blt">
              <li>
                <strong>Marketing of personalized applications:</strong>
                <p>
                  We have a team of high skilled marketers who can create a
                  scalable multi-channel marketing campaign for your iOS app,
                  Android app, or gaming app.
                </p>
              </li>
              <li>
                <strong>Expert marketing strategies:</strong>
                <p>
                  We develop ASO and localization strategies to increase the
                  number of installs and help you run viral advertising
                  campaigns to increase your ROI and bottom line.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileAppMarketing;
