import React, { useState } from "react";
import { Helmet } from "react-helmet";

const CRMSoftwareDevelopment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>Best CRM Software Development Services in Ahmedabad</title>
        <meta
          name="description"
          content="Best CRM software development services in Ahmedabad with Mannix Infotech Pvt. Ltd. We design customized CRM solutions to streamline your customer relationship management."
        />
        <meta
          name="keywords"
          content="CRM software development services in Ahmedabad | Best CRM software development company in Ahmedabad | Customized CRM software design services | CRM solutions development agency in Ahmedabad | Expert CRM software development services in Ahmedabad | Top-rated CRM software development services in Ahmedabad | CRM software development for business growth | Ahmedabad CRM software development experts | CRM solutions for enterprises | CRM software development company in Ahmedabad "
        />

        <link
          rel="canonical"
          href="https://www.mannix.in/crm-software-development-services"
        />
      </Helmet>
      <section className="banner-content-1">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className="position-absolute top-banner-bg"
          viewBox="0 0 1920 761"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
            </defs>
            <clipPath id="SVGID_2_">
              <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
            </clipPath>
            <g className="banner-bg-asw">
              <path
                className="st1"
                d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                            c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
              ></path>
              <path
                className="st2"
                d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                            c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
              ></path>
              <path
                className="st1"
                d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
              ></path>
              <path
                className="st2"
                d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
              ></path>
              <circle className="st1" cx="1536.8" cy="661.3" r="56.2"></circle>
              <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
            </g>
          </g>
        </svg>
        <div className="py-5 container">
          <div className="row align-items-center justify-content-around mt-4">
            <div className="col-lg-6">
              <div className="">
                <h1 className="head-title head-title-without-line m-0 py-3">
                  CRM Software Development
                </h1>
                <p className="m-0 py-3">
                  The main areas of expertise for Mannix Infotech Solutions
                  Pvt.Ltd. are the creation, migration, support, and deployment
                  of customized and scalable CRM systems. Our clients can
                  process customer data and interactions, benefit from a solid
                  workflow within a complicated organizational structure, take
                  advantage of large-scale automation, and manage resource and
                  staff performance by utilizing CRM software solutions. Mannix
                  Infotech Solutions Pvt.Ltd. experts and software engineers can
                  help you execute even the most ambitious strategies within
                  your firm by implementing a custom CRM solution with a focus
                  on your development objectives and essential goals. With our
                  CRM Development Company and implementation services, we can
                  assist you in achieving quantifiable business results. We are
                  always enhancing our knowledge and proficiency in CRM
                  solutions for a range of sectors and industries, such as
                  healthcare, finance, and pharmacy.
                  <br />
                </p>
              </div>
            </div>
            <div className="col-lg-5 prod-section2">
              <div className="">
                <form
                  className="register-form"
                  id="register-form"
                  method="post"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="form_type"
                    id="form_type"
                    value="Android App Development"
                  />
                  <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <label className="w-100">
                          <span>Name</span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div className="w-100">
                        <label className="w-100">
                          <span>Email</span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email id *"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label className="w-100">
                        <span>Contact</span>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Contact No *"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Country</span>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Message</span>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter your message *"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        name="submit"
                        className="submit btn w-100 g-recaptcha-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="what-we-do mt-5 mb-5" style={{ padding: "0px 15px" }}>
        <div className="container">
          <div className="theme-title text-left">
            <p className="text-justify">
              In order to improve business ties with customers, tailor the
              customer experience, and strengthen customer relationships,
              customer relationship management (CRM) systems are used to gather,
              manage, and analyze information about all customer interactions
              across numerous channels. Boost the effectiveness of corporate
              operations.
            </p>
          </div>
        </div>
      </section>
      <section className="what-we-do">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 col-sm-7">
              <div className="theme-title text-left">
                <h2>
                  Mannix Infotech Solutions Pvt.Ltd.- custom CRM development
                  service
                </h2>
                <p className="text-justify">
                  The main areas of expertise for Mannix Infotech Solutions
                  Pvt.Ltd. are the creation, migration, support, and deployment
                  of customized and scalable CRM systems. Our clients can
                  process customer data and interactions, benefit from a solid
                  workflow within a complicated organizational structure, take
                  advantage of large-scale automation, and manage resource and
                  staff performance by utilizing CRM software solutions.
                  Focusing on your essential objectives and growth requirements,
                  Mannix Infotech Solutions Pvt.Ltd. A custom CRM solution can
                  be implemented by consultants and software engineers to assist
                  you in carrying out even the most ambitious objectives inside
                  your company. With our CRM creation and implementation
                  services, we help you achieve measurable business results
                  across a range of sectors and industries, such as pharmacy,
                  finance, and healthcare. We are constantly expanding our
                  expertise and skills in these areas.
                </p>
                <br />
                <h2>Pharmaceutical CRM</h2>
                <p className="text-justify">
                  CRM for the pharmaceutical sector provides the
                  customer-centric services you want to increase your success in
                  the fast-paced market. Pharma CRM coordinates optimal
                  technique productivity, tracks investments, expedites the
                  development of your newest medication and coverings, and
                  complies with legal requirements.
                </p>
                <br />
                <h2>CRM Healthcare</h2>
                <p className="text-justify">
                  Throughout the customer service lifecycle, from the initial
                  point of contact to subscription, sales and marketing,
                  registration, and service delivery, healthcare CRM systems
                  provide a smooth experience. devotion to customers and
                  healthcare.
                </p>
              </div>
            </div>
            <div className="col-sm-5 col-md-5">
              <div className=" imgae-1">
                {" "}
                <img
                  src="../Images/CRMdevelopmentservice.png"
                  alt="CRMdevelopmentservice"
                  className="img-fluid"
                />{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="company-offer3">
        <div className="container">
          <div className="theme-title">
            <strong>Customer relationship management for finance</strong>
            <p className="text-justify mt-2">
              By using a personalized approach, information integration
              services, and marketing solutions from the retail investment and
              insurance business sectors, CRM software for finance enables your
              organization to revolutionize the way it handles all of its
              important information and improve client engagement.
            </p>
            <p className="text-justify">
              Benefits of Adopting Custom CRM Development Services.
            </p>
            <ul className="list-style-blt">
              <li>
                Your business will stand out from the crowd along with your
                distinctive solutions.
              </li>
              <li>
                It provides the ability to upgrade or change your business needs
                at any time.
              </li>
              <li>You can scale your opstem with growing business demands.</li>
              <li>
                A personalized customer management solution allows you to pay
                for what you need and use.
              </li>
              <li>
                You need less training on the platform because you already know
                what is in it.
              </li>
              <li>
                Customized customer relationship management platforms are more
                secure because you can apply all the high-end security
                mechanisms you want.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="company-offer3 ">
        <div className="container">
          <div className="theme-title">
            <strong>
              How Does Custom CRM Software Fit Your Business Needs?
            </strong>
            <ul className="list-style-blt">
              <li>
                The CRM opstem is a software tool that conforms to your unique
                needs for customer management, controlling business operations
                and maintaining a solid return on investment (ROI).
              </li>
              <li>
                We develop CRM software applications that organize entire
                volumes of information inside a organisation and so translate it
                into profitable interactions.. A custom CRM opstem connects all
                data sources together to meet customer expectations with an
                emphasis on analytical information when closing deals and
                estimating revenue growth.
              </li>
              <li>
                A Mannix Infotech Solutions Pvt.Ltd. personalised CRM
                development service dedicated to your privacy and most maximum
                security, therefore we offer extremely reliable solutions you'll
                trust.
              </li>
              <li>
                Our customers enjoy enhanced productivity and added efficiency
                benefits through custom CRM software solutions when paired with
                advanced real-time analytics and mobile capabilities.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRMSoftwareDevelopment;
