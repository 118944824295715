import React, { useState } from "react";
import { Helmet } from "react-helmet";

const DrupalDevlopment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title> top 5 Drupal Web Development Services in Ahmedabad</title>
        <meta
          name="description"
          content="Explore the top 5 Drupal web development services in Ahmedabad with Mannix Infotech Pvt. Ltd.. We offer expert solutions for robust, scalable, and customized Drupal websites.
"
        />
        <meta
          name="keywords"
          content="Drupal web development services in Ahmedabad | Best Drupal development company in Ahmedabad | Custom Drupal website development services | Scalable Drupal web development agency | Robust Drupal website design and development | Expert Drupal web development services in Ahmedabad | Top-rated Drupal development services in Ahmedabad | Customized Drupal web development solutions | Professional Drupal website development company | Ahmedabad Drupal web development experts"
        />
        <link
          rel="canonical"
          href="https://www.mannix.in/drupal-web-development-services"
        />
      </Helmet>
      <div className="hire-dedicated services mt-0">
        <section className="banner-content-1">
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            className="position-absolute top-banner-bg"
            viewBox="0 0 1920 761"
          >
            <g>
              <defs>
                <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
              </defs>
              <clipPath id="SVGID_2_">
                <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
              </clipPath>
              <g className="banner-bg-asw">
                <path
                  className="st1"
                  d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                        c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
                ></path>
                <path
                  className="st2"
                  d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                        c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
                ></path>
                <path
                  className="st1"
                  d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
                ></path>
                <path
                  className="st2"
                  d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
                ></path>
                <circle
                  className="st1"
                  cx="1536.8"
                  cy="661.3"
                  r="56.2"
                ></circle>
                <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
              </g>
            </g>
          </svg>
          <div className="py-5 container">
            <div className="row align-items-center justify-content-around mt-4">
              <div className="col-lg-6">
                <div className="">
                  <h1 className="head-title head-title-without-line m-0 py-3">
                    Drupal Web Development Services
                  </h1>
                  <p className="m-0 py-3">
                    Complex solutions are required for modern websites, and
                    Drupal can satisfy these requirements. It is among the
                    greatest systems for creating and developing sizable
                    websites and online apps. Numerous capabilities provided by
                    the open-source CMS can increase the effectiveness of your
                    websites. Drupal is the preferred content management system
                    for large-scale digital projects, hosting more than a
                    million websites on its platform.
                  </p>
                  <p className="m-0 py-3">
                    At Mannix Infotech Solutions Pvt.Ltd., we strive to provide
                    a full end-to-end Drupal webs solution using agile
                    development methodology and a highly flexible engagement
                    model. We are a group of enthusiastic techies with the
                    knowledge, expertise, and command of the Drupal online
                    Development framework needed to create reliable online
                    applications and modules. We are incredibly skilled at
                    translating your brand's image and values and organizing
                    multilingual, feature-rich themes. We combine our creative
                    development solution with our experience adapting,
                    transferring, and constructing sites.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 prod-section2">
                <div className="">
                  <form
                    className="register-form"
                    id="register-form"
                    method="post"
                    action=""
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="hidden"
                      name="form_type"
                      id="form_type"
                      value="Android App Development"
                    />
                    <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                      <div className="d-flex">
                        <div className="w-100 mr-2">
                          <label className="w-100">
                            <span>Name</span>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Name *"
                              className="form-control"
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                        <div className="w-100">
                          <label className="w-100">
                            <span>Email</span>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email id *"
                              className="form-control"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <label className="w-100">
                          <span>Contact</span>
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="Contact No *"
                            className="form-control"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Country</span>
                          <input
                            type="text"
                            name="country"
                            id="country"
                            placeholder="Enter your Country"
                            className="form-control"
                            value={formData.country}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Message</span>
                          <textarea
                            name="message"
                            id="message"
                            placeholder="Enter your message *"
                            className="form-control"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </label>
                      </div>

                      <div className="">
                        <button
                          type="submit"
                          name="submit"
                          className="submit btn w-100 g-recaptcha-btn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="hire-dedicated services pt_90">
        <section className="pt_90 pb-90">
          <div className="hire-dedicated-2 container">
            <div className="pb-4 text-center">
              <h2 className="head-title">
                Why Choose Mannix Infotech Solution?
              </h2>
            </div>
            <div className="row">
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-bars"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">Scalability</h2>
                      <p className="m-0 py-2">
                        Drupal is tremendously scalable. We can help you
                        effortlessly develop your web and scale as per your
                        business spikes and rising demands. We remain as your
                        parallel partner for all your Drupal journey for highly
                        scalable and enterprise-grade CMS solutions.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-bars"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fab fa-searchengin"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">High-grade performance</h2>
                      <p className="m-0 py-2">
                        With extensive experience in diverse industries, we can
                        leverage to deliver excellent services blending up all
                        cutting-edge CMS features and functionalities. Our
                        architects and developers collaborate to meet the goals
                        and solutions of a business.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fab fa-searchengin"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-child"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">Secured Solutions</h2>
                      <p className="m-0 py-2">
                        With increasing cyber crimes and exploited
                        vulnerabilities, we can help your business integrate
                        highly secure CMS solutions that safeguard from exploits
                        while making it great for an enterprise client.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-child"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-chart-line"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">Latest Tech Stack</h2>
                      <p className="m-0 py-2">
                        Leverage your business with the ever-changing modern
                        business ecoopstem. Our team is sharpened with the
                        latest technical skills and spirit to deliver innovative
                        technology solutions to surpass your business goals.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-chart-line"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DrupalDevlopment;
