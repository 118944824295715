import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import { Helmet } from "react-helmet";

const Productphotographynormal = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const dataSets = [
    {
      images: [
        {
          src: "./Images/WBP1.png",
          alt: "White Background Product",
        },
        {
          src: "./Images/WBP2.png",
          alt: "White Background Product",
        },
        {
          src: "./Images/WBP3.png",
          alt: "White Background Product",
        },
        {
          src: "./Images/WBP4.png",
          alt: "White Background Product",
        },
      ],
      text: "White Background Photography",
    },
    {
      images: [
        {
          src: "./Images/SCP1.png",
          alt: "Skin Care Product",
        },
        {
          src: "./Images/SCP2.png",
          alt: "Skin Care Product",
        },
        {
          src: "./Images/SCP3.png",
          alt: "Skin Care Product",
        },
        {
          src: "./Images/SCP4.png",
          alt: "Skin Care Product",
        },
      ],
      text: "Skin Care Product Photography",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === dataSets.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change every 5 seconds

    return () => clearInterval(interval); // Clear interval when component unmounts
  }, [dataSets.length]);

  return (
    <>
      <Helmet>
        <title>
          Best Product Video Maker in Ahmedabad | Top Videography Services
        </title>
        <meta
          name="description"
          content="Top product video maker in Ahmedabad, India. Elevate your brand with high-quality videography. Serving the UK, US, Canada, and Australia."
        />
        <meta
          name="keyword"
          content="best product videography maker Ahmedabad |
 top product video services in Ahmedabad |
high-quality product videography Ahmedabad |
professional product video production Ahmedabad |
affordable product videography services Ahmedabad | 
product videography for businesses Ahmedabad |
custom product video services Ahmedabad |
expert product videography Ahmedabad |
best product video production company Ahmedabad |
high-definition product videography Ahmedabad"
        />

        <link
          rel="canonical"
          href="https://www.mannix.in/product-photography-normal"
        />
      </Helmet>
      <div className="container d-flex justify-content-center align-items-center">
        {/* Image Section - hidden on small screens */}
        <div className="me-5 d-none d-md-block">
          {/* Top row of 2 images */}
          <div className="d-flex gap-2 mb-2 justify-content-center">
            {dataSets[currentIndex].images.slice(0, 2).map((image, idx) => (
              <img
                key={idx}
                src={image.src}
                alt={image.alt}
                className="rounded border p-2"
                style={{ height: "200px", width: "200px" }}
              />
            ))}
          </div>

          {/* Bottom row of 2 images */}
          <div className="d-flex gap-2 justify-content-center">
            {dataSets[currentIndex].images.slice(2, 4).map((image, idx) => (
              <img
                key={idx}
                src={image.src}
                alt={image.alt}
                className="rounded border p-2"
                style={{ height: "200px", width: "200px" }}
              />
            ))}
          </div>
        </div>

        {/* Text Section - always visible */}
        <div className="text-center">
          <h4 className="border-bottom p-md-2 mt-4 mt-md-0">
            We're Experts In
            <span style={{ color: "orange" }}>
              {" "}
              {dataSets[currentIndex].text}
            </span>
          </h4>
          <p className="fs-2 p-md-2 text-center text-danger">
            Product Photography For Online Businesses
          </p>
        </div>
      </div>
      <div className="container  mt-md-5">
        <div className="row align-items-stretch">
          {/* Left Content */}
          <div className="col-md-6 p-3">
            <h4 className="border-bottom p-2">
              Our Product Photographers Focus On Quality
            </h4>
            <p className=" p-2 text-left " style={{ fontSize: "18px" }}>
              For over a decade, our skilled product photographers have captured
              the essence of countless diverse items. Understanding that each
              product is unique, we've honed a tailored approach to deliver
              stunning product photography while keeping costs within reach. Our
              dedicated team specializes in crafting exceptional, high-quality
              images that showcase your products in the best light.
            </p>
          </div>

          {/* Right Content (Carousel) */}
          <div className="col-md-6 p-3">
            <Carousel>
              <Carousel.Item>
                <img
                  src="./Images/WBP4.png"
                  alt="Our Product Photographers Focus On Quality"
                  className="d-block w-100"
                  style={{ height: "300px", objectFit: "cover" }}
                />
                {/* <Carousel.Caption>
                  <h3>Your Caption Here 1</h3>
                  <p>Some description about the image 1.</p>
                </Carousel.Caption> */}
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src="./Images/SCP1.png" // Change this URL to another image
                  alt="Our Product Photographers Focus On Quality"
                  className="d-block w-100"
                  style={{ height: "300px", objectFit: "cover" }}
                />
                {/* <Carousel.Caption>
                  <h3>Your Caption Here 2</h3>
                  <p>Some description about the image 2.</p>
                </Carousel.Caption> */}
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src="./Images/SCP3.png" // Change this URL to another image
                  alt="Our Product Photographers Focus On Quality"
                  className="d-block w-100"
                  style={{ height: "300px", objectFit: "cover" }}
                />
                {/* <Carousel.Caption>
                  <h3>Your Caption Here 3</h3>
                  <p>Some description about the image 3.</p>
                </Carousel.Caption> */}
              </Carousel.Item>
              {/* Add more Carousel.Items here if needed */}
            </Carousel>
          </div>
        </div>
      </div>
      <div className="p-md-5 p-2">
        <p
          className=" rounded text-center fs-md-3 fs-4 p-md-3 p-1"
          style={{ backgroundColor: "#D3D3D3" }}
        >
          Our Product Photography Services
        </p>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 mb-3 d-flex flex-column align-items-center">
            <img
              src="./Images/PS1.png"
              alt="Creative Product Photography"
              className="img-fluid rounded "
              style={{ height: "400px", objectFit: "cover" }} // Ensures image covers the area
            />
            <p className="text-center">Creative Product Photography</p>
          </div>
          <div className="col-12 col-md-6 mb-3 d-flex flex-column align-items-center">
            <img
              src="./Images/PS2.png"
              alt="White Background Product Photography"
              className="img-fluid rounded border"
              style={{ height: "400px", objectFit: "cover" }} // Ensures image covers the area
            />
            <p className="text-center">White Background Product Photography</p>
          </div>
        </div>
      </div>
      <div className="text-center justify-content-center d-md-flex gap-5">
        <div>
          <img
            style={{ height: "250px", width: "250px" }}
            alt="Lifestyle Product Photography"
            className="rounded"
            src="./Images/PS3.png"
          />
          <div>Lifestyle Product Photography</div>
        </div>

        <div>
          <img
            style={{ height: "250px", width: "250px" }}
            alt="Ecommerce Product Photography"
            className="rounded"
            src="./Images/PS4.png"
          />
          <div>Ecommerce Product Photography</div>
        </div>

        <div>
          <img
            style={{ height: "250px", width: "250px" }}
            alt="Amazon Product Photography"
            className="rounded"
            src="./Images/PS5.png"
          />
          <div>Amazon Product Photography</div>
        </div>

        <div>
          <img
            style={{ height: "250px", width: "250px" }}
            alt="Beauty Product Photography"
            className="rounded"
            src="./Images/PS6.png"
          />
          <div>Beauty Product Photography</div>
        </div>
      </div>
      <div className="container">
        <div className="row text-center justify-content-center mt-4 p-2 overflow-hidden">
          <div className="col-12 col-md-4 mb-4 d-flex flex-column align-items-center">
            <img
              style={{ height: "350px", width: "350px" }}
              alt="Clothing Photography"
              className=" mb-2 mb-sm-3" // Add margin below the image
              src="./Images/PS7.png"
            />
            <div>Clothing Photography</div>
          </div>

          <div className="col-12 col-md-4 mb-4 d-flex flex-column align-items-center">
            <img
              style={{ height: "350px", width: "350px" }}
              alt="Commercial Product Photography"
              className=" mb-2 mb-sm-3" // Add margin below the image
              src="./Images/PS8.png"
            />
            <div>Commercial Product Photography</div>
          </div>

          <div className="col-12 col-md-4 mb-4 d-flex flex-column align-items-center">
            <img
              style={{ height: "350px", width: "350px" }}
              alt="Shopify Product Photography"
              className=" mb-2 mb-sm-3" // Add margin below the image
              src="./Images/PS9.png"
            />
            <div>Shopify Product Photography</div>
          </div>
        </div>
      </div>

      <section id="section-amazon-photography" className="py-5">
        <div className="container text-md-start text-center">
          <h2 className="mb-4">Amazon Photography Services</h2>
          <p>
            Everything you need for a top-notch set of listing images on Amazon.
          </p>
          <div className="d-md-flex gap-2">
            <div className="row justify-content-center">
              {/* Images Section */}
              <div className="col-4 col-md-4 mb-4">
                <img
                  className="img-fluid"
                  alt="Amazon Photography Services"
                  style={{ height: "200px", width: "200px" }}
                  src="./Images/AP1.png"
                />
              </div>
              <div className="col-4 col-md-4 mb-4">
                <img
                  className="img-fluid"
                  alt="Amazon Photography Services"
                  style={{ height: "200px", width: "200px" }}
                  src="./Images/AP2.png"
                />
              </div>
              <div className="col-4 col-md-4 mb-4">
                <img
                  className="img-fluid"
                  style={{ height: "200px", width: "200px" }}
                  alt="Amazon Photography Services"
                  src="./Images/AP3.png"
                />
              </div>
            </div>
            <div className="row text-center">
              {/* Text Descriptions Section */}
              <div className="col-12 col-md-8 d-flex flex-column align-items-center">
                <div
                  className="a-text-blurb-box mb-2 p-2"
                  style={{ width: "200px" }}
                >
                  Project Coordination
                </div>
                <div
                  className="a-text-blurb-box mb-2"
                  style={{ width: "200px" }}
                >
                  Graphic Design
                </div>
                <div
                  className="a-text-blurb-box mb-2"
                  style={{ width: "200px" }}
                >
                  Photography
                </div>
              </div>

              <div className="col-12 col-md-4 d-flex flex-column align-items-center mb-3">
                <div
                  className="a-text-blurb-box mb-2"
                  style={{ width: "200px" }}
                >
                  Retouching
                </div>
                <div
                  className="a-text-blurb-box mb-2"
                  style={{ width: "200px" }}
                >
                  Copywriting
                </div>
                <div
                  className="a-text-blurb-box mb-2"
                  style={{ width: "200px" }}
                >
                  Art Direction
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className=" p-3">
          <h4 className="border-bottom p-2 ">
            Our Creative Product Photography Stands Out
          </h4>
          <p className=" p-2 text-left " style={{ fontSize: "17px" }}>
            In the competitive world of ecommerce, a diverse range of visuals is
            essential for a standout product listing. Our creative product
            photography service ensures that your offerings grab attention and
            drive conversions. By incorporating striking props, vibrant
            backgrounds, and engaging lifestyle composites, we bring your
            products to life.
          </p>
        </div>

        <div className="container">
          <div className="row justify-content-center mb-4">
            <div className="col-md-4 col-sm-6 d-flex justify-content-center mb-2">
              <img
                src="./Images/CP1.png"
                alt="Our Creative Product Photography"
                className="img-fluid rounded"
              />
            </div>
            <div className="col-md-4 col-sm-6 d-flex justify-content-center mb-2">
              <img
                src="./Images/CP2.png"
                alt="Our Creative Product Photography"
                className="img-fluid rounded"
              />
            </div>
            <div className="col-md-4 col-sm-6 d-flex justify-content-center mb-2">
              <img
                src="./Images/CP3.png"
                alt="Our Creative Product Photography"
                className="img-fluid rounded"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center mb-4">
            <div className="col-md-4 col-sm-6 d-flex justify-content-center mb-2">
              <img
                src="./Images/CP4.png"
                alt="Our Creative Product Photography"
                className="img-fluid rounded"
              />
            </div>
            <div className="col-md-4 col-sm-6 d-flex justify-content-center mb-2">
              <img
                src="./Images/CP5.png"
                alt="Our Creative Product Photography"
                className="img-fluid rounded"
              />
            </div>
            <div className="col-md-4 col-sm-6 d-flex justify-content-center mb-2">
              <img
                src="./Images/CP6.png"
                alt="Our Creative Product Photography"
                className="img-fluid rounded"
              />
            </div>
          </div>
        </div>
        <div className="container  d-md-block d-none">
          {" "}
          <div className="d-flex justify-content-center gap-2">
            <img
              src="./Images/CP7.png"
              alt="Our Creative Product Photography"
              className="rounded"
              style={{ height: "200px", width: "200px" }}
            />
            <img
              src="./Images/CP8.png"
              alt="Our Creative Product Photography"
              className="rounded"
              style={{ height: "200px", width: "200px" }}
            />
            <img
              src="./Images/CP9.png"
              alt="Our Creative Product Photography"
              className="rounded"
              style={{ height: "200px", width: "200px" }}
            />
            <img
              src="./Images/CP10.png"
              alt="Our Creative Product Photography"
              className="rounded"
              style={{ height: "200px", width: "200px" }}
            />
            <img
              src="./Images/CP11.png"
              alt="Our Creative Product Photography"
              className=" rounded"
              style={{ height: "200px", width: "200px" }}
            />
          </div>
        </div>

        <div className="container d-md-none d-block">
          <div className="row justify-content-center mb-2">
            <div className="col-6 col-md-4 d-flex justify-content-center mb-2">
              <img
                src="./Images/CP7.png"
                alt="Our Creative Product Photography"
                className="rounded img-fluid"
                style={{ height: "150px", width: "150px" }}
              />
            </div>
            <div className="col-6 col-md-4 d-flex justify-content-center mb-2">
              <img
                src="./Images/CP8.png"
                alt="Our Creative Product Photography"
                className="rounded img-fluid"
                style={{ height: "150px", width: "150px" }}
              />
            </div>
            <div className="col-6 col-md-4 d-flex justify-content-center mb-2">
              <img
                src="./Images/CP9.png"
                alt="Our Creative Product Photography"
                className="rounded img-fluid"
                style={{ height: "150px", width: "150px" }}
              />
            </div>
            <div className="col-6 col-md-4 d-flex justify-content-center mb-2">
              <img
                src="./Images/CP10.png"
                alt="Our Creative Product Photography"
                className="rounded img-fluid"
                style={{ height: "150px", width: "150px" }}
              />
            </div>
            <div className="col-6 col-md-4 d-flex justify-content-center mb-2">
              <img
                src="./Images/CP11.png"
                alt="Our Creative Product Photography"
                className="rounded img-fluid"
                style={{ height: "150px", width: "150px" }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Productphotographynormal;
