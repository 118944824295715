import React from "react";
import { Helmet } from "react-helmet";

const FAQ = () => {
  return (
    <div>
      <Helmet>
        <title>FAQ about Mannix Infotech Solutions Pvt. Ltd.</title>
        <meta
          name="description"
          content="Find answers to frequently asked questions about Mannix Infotech Solutions. Learn more about our services and how we can assist your business."
        />
        <meta
          name="keyword"
          content="Mannix Infotech Solutions digital marketing FAQ |
     Frequently asked questions about Mannix Infotech web development |
     Mannix Infotech Solutions e-commerce solutions overview |
      How Mannix Infotech can improve your online presence |
     Mannix Infotech Solutions IT consulting services benefits|
    Frequently asked questions about Mannix Infotech cybersecurity services |
    Mannix Infotech Solutions business intelligence solutions overview|
     How Mannix Infotech can help with data analytics|
    Mannix Infotech Solutions artificial intelligence solutions FAQ "
        />

        <link rel="canonical" href="https://www.mannix.in/faq" />
      </Helmet>

      <div className="container mt-2  m-md-5">
        <h1
          className="text-center fw-bold mb-4 text-uppercase"
          style={{ fontSize: "40px", color: "rgba(0, 0, 0, 0.5)" }}
        >
          Frequently Asked Questions
        </h1>

        <h2 className="text-center">FAQ</h2>
        <h6 className="text-center mb-5">How We Can Help You Today?</h6>
        <div className="row mx-md-5">
          <div className="col-12 col-md-6 mb-4" style={{ width: "520px" }}>
            <h5 className="bg-dark text-white pb-2 p-2 mb-0">
              <i className="me-2 px-2 fa-solid fa-question border border-2 border-white rounded-circle p-1"></i>
              Who is the owner of Mannix Infotech solutions Pvt. Ltd.?
            </h5>
            <p
              className="p-2 pt-2 m-0 mt-0 "
              style={{ backgroundColor: "#bdc3c7" }}
            >
              Directors of Mannix Infotech Solutions Private Limited are MS.
              Mansi Jain, Mr.Harishkumar Mali and Mr. Atul Patel. Infotech
              Solutions Private Limited's Corporate Identification Number is
              (CIN) U62099GJ2024PTC150243.
            </p>
          </div>
          <div className="col-12 col-md-6 mb-4 " style={{ width: "520px" }}>
            <h5 className="bg-dark text-white p-2 pb-2  mb-0">
              <i className="me-2 px-2 fa-solid fa-question border border-2 border-white rounded-circle p-1"></i>
              How long have you been in business?
            </h5>
            <p
              className="p-2 pt-2 m-0 mt-0 "
              style={{ backgroundColor: "#bdc3c7" }}
            >
              We have been in the IT industry since 2023. We started off as a
              data processing unit at that point in time and grew today to a
              Total IT Solutions company with footprints across Globe.{" "}
            </p>
          </div>
          <div className="col-12 col-md-6 pb-4  " style={{ width: "520px" }}>
            <h5 className="bg-dark text-white p-2 pb-2 mb-0">
              <i className="me-2 px-2 fa-solid fa-question border border-2 border-white rounded-circle p-1"></i>{" "}
              What are your payment terms?
            </h5>
            <p
              className="p-2 pt-2 m-0 mt-0 "
              style={{ backgroundColor: "#bdc3c7" }}
            >
              Terms depend on project to project basis. For bigger deals it is
              40:40:20 whereas for smaller ones 50:50. For regular jobs eg. SEO/
              SMO and AMC it is 100% advance quarterly and annually.
            </p>
          </div>
          <div className="col-12 col-md-6 pb-4" style={{ width: "520px" }}>
            <h5 className="bg-dark text-white p-2 pb-2 mb-0">
              <i className="me-2 px-2 fa-solid fa-question border border-2 border-white rounded-circle p-1"></i>
              What is the process Mannix Infotech solutions Pvt. Ltd. follows
              for taking up a project ?
            </h5>
            <p
              className="p-2 pt-2 m-0 mt-0 "
              style={{ backgroundColor: "#bdc3c7" }}
            >
              Mannix Infotech solutions Pvt. Ltd. is a process driven company &
              we strictly follow 5 Step proven process of the industry. Plan -
              Develop - Check - Approval - Deploy We stick to 3 P’s Process +
              Purpose + Principles
            </p>
          </div>
          <div className="col-12 col-md-6 pb-4  " style={{ width: "520px" }}>
            <h5 className="bg-dark text-white p-2 pb-2 mb-0">
              <i className="me-2 px-2 fa-solid fa-question border border-2 border-white rounded-circle p-1"></i>{" "}
              How do we make our payments to Mannix Infotech solutions?
            </h5>
            <p
              className="p-2 pt-2 m-0 mt-0 "
              style={{ backgroundColor: "#bdc3c7" }}
            >
              Domestic clients can make payments easily online thru site using
              credit/ debit cards, Net Banking etc. or you can also choose to
              make direct bank transfer thru NEFT/ RTGS. For International
              clients we accept payments thru PayPal.
            </p>
          </div>
          <div className="col-12 col-md-6 pb-4  " style={{ width: "520px" }}>
            <h5 className="bg-dark text-white p-2 pb-2 mb-0">
              <i className="me-2 px-2 fa-solid fa-question border border-2 border-white rounded-circle p-1"></i>{" "}
              What does 'custom design or development' mean?
            </h5>
            <p
              className="p-2 pt-2 m-0 mt-0 "
              style={{ backgroundColor: "#bdc3c7" }}
            >
              Custom or Tailor made design and development means all the same.
              It ensures that the application is developed as per client’s
              choice. The current & future needs of client’s are taken care off
              at the structuring level itself. Mannix Infotech Solutions
              specializes in providing custom built solutions.
            </p>
          </div>
          <div className="col-12 col-md-6 pb-4  " style={{ width: "520px" }}>
            <h5 className="bg-dark text-white p-2 pb-2 mb-0">
              <i className="me-2 px-2 fa-solid fa-question border border-2 border-white rounded-circle p-1"></i>{" "}
              How do I ensure that my business stays ahead of my competition in
              online visibility?
            </h5>
            <p
              className="p-2 pt-2 m-0 mt-0 "
              style={{ backgroundColor: "#bdc3c7" }}
            >
              Mannix Infotech solutions team experts have all the mechanism of
              studying the strategies your competition is using to promote their
              businesses. We study them to make our own counter strategies. We
              monitor it on regular basis & maneuver it as per the need in order
              to provide good results to our valued clients.
            </p>
          </div>
          <div className="col-12 col-md-6 pb-4  " style={{ width: "520px" }}>
            <h5 className="bg-dark text-white p-2 pb-2 mb-0">
              <i className="me-2 px-2 fa-solid fa-question border border-2 border-white rounded-circle p-1"></i>{" "}
              Will I have ownership of my Website?
            </h5>
            <p
              className="p-2 pt-2 m-0 mt-0 "
              style={{ backgroundColor: "#bdc3c7" }}
            >
              As the website is developed for each client separately hence we
              treat each work as theirs. Hence if the client desires to keep the
              controls and manage themselves, we have no objections in doing so.
              However, in certain cases where Application development is done
              using proprietary codes the payment terms may vary on case to case
              basis.
            </p>
          </div>
          <div className="col-12 col-md-6 pb-4  " style={{ width: "520px" }}>
            <h5 className="bg-dark text-white p-2 pb-2 mb-0">
              <i className="me-2 px-2 fa-solid fa-question border border-2 border-white rounded-circle p-1"></i>{" "}
              What is the difference between Static & Dynamic design?
            </h5>
            <p
              className="p-2 pt-2 m-0 mt-0 "
              style={{ backgroundColor: "#bdc3c7" }}
            >
              Static websites are the basic sites. The content and pictures if
              needed can only be changed by a professional developer/ designers
              only. Whereas the Dynamic website has a mechanism of accessing the
              site thru backend control panel where Admin users can login using
              id and password to make the changes in the site in real time. You
              can change pictures/ contents/ figures or any such thing that’s
              planned to be changed at the time of developing the site.
            </p>
          </div>
          <div className="col-12 col-md-6 pb-4  " style={{ width: "520px" }}>
            <h5 className="bg-dark text-white p-2 pb-2 mb-0">
              <i className="me-2 px-2 fa-solid fa-question border border-2 border-white rounded-circle p-1"></i>{" "}
              Do I have to keep my website with you forever, or can I move it to
              another supplier if I wish?
            </h5>
            <p
              className="p-2 pt-2 m-0 mt-0 "
              style={{ backgroundColor: "#bdc3c7" }}
            >
              We prefer to go for a long term relationships with our clients.
              All our efforts are directed towards it. However we have no issue
              if a client desires to move to any other service provider for any
              reason whatsoever. We provide the required details for the same.
              However we do make sure that the dues are cleared from accounts
              side.
            </p>
          </div>
          <div className="col-12 col-md-6 pb-4  " style={{ width: "520px" }}>
            <h5 className="bg-dark text-white p-2 pb-2 mb-0">
              <i className="me-2 px-2 fa-solid fa-question border border-2 border-white rounded-circle p-1"></i>{" "}
              Will I find my website on the search engines?
            </h5>
            <p
              className="p-2 pt-2 m-0 mt-0 "
              style={{ backgroundColor: "#bdc3c7" }}
            >
              Finding your website on the search engine requires it to be
              promoted well. There are many factors needed for making a website
              search engine friendly. We offer such services for the clients who
              wish their website to come on top pages in searches on selective
              keywords. SEO/ SMO are some of the digital marketing services for
              getting your site on search engines.
            </p>
          </div>
          <div className="col-12 col-md-6 pb-4  " style={{ width: "520px" }}>
            <h5 className="bg-dark text-white p-2 pb-2 mb-0">
              <i className="me-2 px-2 fa-solid fa-question border border-2 border-white rounded-circle p-1"></i>{" "}
              Do you guarantee that my site will always be available?
            </h5>
            <p
              className="p-2 pt-2 m-0 mt-0 "
              style={{ backgroundColor: "#bdc3c7" }}
            >
              There are systems to follow and you will never be lost on the net.
              Our experts can guarantee your good & effective presence on the
              internet. There could be few period when due to search engine
              policy changes your position may vary but our alert team would
              ensure you are back in the game, that’s for sure.
            </p>
          </div>
          <div className="col-12 col-md-6 pb-4  " style={{ width: "520px" }}>
            <h5 className="bg-dark text-white p-2 pb-2 mb-0">
              <i className="me-2 px-2 fa-solid fa-question border border-2 border-white rounded-circle p-1"></i>{" "}
              How do I maintain my website?
            </h5>
            <p
              className="p-2 pt-2 m-0 mt-0 "
              style={{ backgroundColor: "#bdc3c7" }}
            >
              Smaller static websites do not require much maintenance apart from
              timely renewals. However bigger sites or application requires lots
              of maintenance work and hence it requires specialized skills to do
              such maintenances. Mannix Infotech solutions has a full fledged
              team for undertaking Annual Maintenance Contract for such projects
              effectively. You can enquire about it thru submitting your enquiry
              or on call.
            </p>
          </div>
          <div className="col-12 col-md-6 pb-4  " style={{ width: "520px" }}>
            <h5 className="bg-dark text-white p-2 pb-2 mb-0">
              <i className="me-2 px-2 fa-solid fa-question border border-2 border-white rounded-circle p-1"></i>{" "}
              If we required to Redesign my website, what is the process?
            </h5>
            <p
              className="p-2 pt-2 m-0 mt-0 "
              style={{ backgroundColor: "#bdc3c7" }}
            >
              Redesigning is a custom activity and hence depends upon case to
              case basis. The process however is to get in touch with our sales/
              marketing team who will evaluate your needs and study the existing
              systems so that the cost could be calculated. In most cases
              creating a new website is recommended as the technology and trend
              changes from time to time.
            </p>
          </div>
          <div className="col-12 col-md-6 pb-4  " style={{ width: "520px" }}>
            <h5 className="bg-dark text-white p-2 pb-2 mb-0">
              <i className="me-2 px-2 fa-solid fa-question border border-2 border-white rounded-circle p-1"></i>{" "}
              Do you have any kind of Documentation process, before taking any
              project?
            </h5>
            <p
              className="p-2 pt-2 m-0 mt-0 "
              style={{ backgroundColor: "#bdc3c7" }}
            >
              As we follow processes for all our projects. We certainly have
              well defined documentation system for each one separately.
              Starting from proposal & quotation to Purchase Order placement
              then to requirement gathering forms, to making of SRS document and
              signing up of NDA & other agreements or contracts. For regular
              reporting also we follow a system. All major communications is put
              on email for records.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
