import React from "react";
import { Helmet } from "react-helmet";

const Business = () => {
  return (
    <>
      <Helmet>
        <title>
          Grow Your Business with Mannix Infotech Solutions Pvt. Ltd.
        </title>
        <meta
          name="description"
          content="Grow your business with Mannix Infotech Solutions. We provide expert strategies and solutions to help you expand and succeed in the competitive market."
        />
        <meta
          name="keyword"
          content="Business growth consulting services in Ahmedabad |
    Expert business expansion strategies in India |
     Mannix Infotech business success solutions |
      Customized business growth plans provider |
     Business expansion services for startups|
   Expert business consulting services for SMEs |
   Business growth solutions for e-commerce companies|
     Competitive market analysis services provider|	
	Business success strategies for entrepreneurs |
    Mannix Infotech business growth and development services "
        />

        <link rel="canonical" href="https://www.mannix.in/business" />
      </Helmet>

      <div>
        <div>
          <img
            className="d-none d-md-block img-fluid"
            src="../BusinessBanner.jpg"
            alt="BusinessBanner"
            style={{
              width: "100%",
              height: "500px",
              position: "relative",
              // objectFit: "cover",
            }}
          />

          <img
            className="d-md-none d-sm-block img-fluid"
            src="../BusinessBanner.jpg"
            alt="BusinessBanner"
            style={{
              width: "100%",
              height: "300px",
              // objectFit: "cover",
            }}
          />
          <div className="container">
            <div
              className="d-none d-md-block ops_blog_header_content "
              style={{ position: "absolute", top: "400px", left: "30px" }}
            >
              <h1 className="text-danger fs-1">
                <b>Grow Your Business With Us</b>
              </h1>
            </div>
            <div
              className="d-md-none d-sm-block ops_blog_header_content "
              style={{ position: "absolute", top: "300px", right: "30px" }}
            >
              <p className="text-danger " style={{ fontSize: "40px" }}>
                <b>Grow Your Business With Us</b>
              </p>
            </div>
          </div>
        </div>

        <div className="p-3">
          <div className="row d-flex justify-content-center">
            <div className="col-md-6  p-5">
              <img
                src="../BusinessDevelop.jpg"
                alt="BusinessDevelop"
                className="img-fluid"
              />
            </div>
            <div className="col-md-6 p-md-5 p-sm-1 d-none d-md-block">
              <p className="text-danger mb-4 mx-4" style={{ fontSize: "45px" }}>
                <b>We Develop your Business</b>
              </p>
              <ol className="fs-3">
                <li className="mb-2">
                  1. We Help your Business grow by tracking the latest digital
                  trends to craft <b>Marketing Strategies</b>
                </li>
                <li className="mb-2">
                  2. If your new journey in Business life then,
                  <ul>
                    <li className="mb-2">
                      {" "}
                      <i
                        className="fa-solid fa-circle"
                        style={{ fontSize: "10px" }}
                      ></i>{" "}
                      How to <b>choose</b> Products?
                    </li>
                    <li className="mb-2">
                      {" "}
                      <i
                        className="fa-solid fa-circle"
                        style={{ fontSize: "10px" }}
                      ></i>{" "}
                      How to <b>purchase</b> Products?
                    </li>
                    <li className="mb-2">
                      {" "}
                      <i
                        className="fa-solid fa-circle"
                        style={{ fontSize: "10px" }}
                      ></i>{" "}
                      How to sell Products?
                    </li>
                    <li className="mb-2">
                      {" "}
                      <i
                        className="fa-solid fa-circle"
                        style={{ fontSize: "10px" }}
                      ></i>{" "}
                      <b>Where</b> to sell Products?
                    </li>
                    <li className="mb-2">
                      {" "}
                      <i
                        className="fa-solid fa-circle"
                        style={{ fontSize: "10px" }}
                      ></i>{" "}
                      Helps you to choose <b>Highest Profitable Products.</b>
                    </li>
                  </ul>
                </li>
                <li className="mb-2">
                  3. You have already your business then,
                  <ul>
                    <li className="mb-2">
                      {" "}
                      <i
                        className="fa-solid fa-circle"
                        style={{ fontSize: "10px" }}
                      ></i>{" "}
                      We will detect a issues in your grow business & solve it
                      by Marketing fundametals<b>(Digital Marketing)</b>.
                    </li>
                  </ul>
                </li>
                <li className="mb-2">
                  4. We will take your <b>Business online</b>.
                </li>
              </ol>
            </div>
            <div className="col-sm-12 p-0 d-md-none d-sm-block">
              <p
                className="text-danger mb-4 mx-4 mt-4"
                style={{ fontSize: "30px" }}
              >
                <b>We Develop your Business</b>
              </p>
              <ol className="fs-3">
                <li className="mb-2">
                  1. We Help your Business grow by tracking the latest digital
                  trends to craft <b>Marketing Strategies</b>
                </li>
                <li className="mb-2">
                  2. If your new journey in Business life then,
                  <ul>
                    <li className="mb-2">
                      {" "}
                      <i
                        className="fa-solid fa-circle"
                        style={{ fontSize: "10px" }}
                      ></i>{" "}
                      How to <b>choose</b> Products?
                    </li>
                    <li className="mb-2">
                      {" "}
                      <i
                        className="fa-solid fa-circle"
                        style={{ fontSize: "10px" }}
                      ></i>{" "}
                      How to <b>purchase</b> Products?
                    </li>
                    <li className="mb-2">
                      {" "}
                      <i
                        className="fa-solid fa-circle"
                        style={{ fontSize: "10px" }}
                      ></i>{" "}
                      How to sell Products?
                    </li>
                    <li className="mb-2">
                      {" "}
                      <i
                        className="fa-solid fa-circle"
                        style={{ fontSize: "10px" }}
                      ></i>{" "}
                      <b>Where</b> to sell Products?
                    </li>
                    <li className="mb-2">
                      {" "}
                      <i
                        className="fa-solid fa-circle"
                        style={{ fontSize: "10px" }}
                      ></i>{" "}
                      Helps you to choose <b>Highest Profitable Products.</b>
                    </li>
                  </ul>
                </li>
                <li className="mb-2">
                  3. You have already your business then,
                  <ul>
                    <li className="mb-2">
                      {" "}
                      <i
                        className="fa-solid fa-circle"
                        style={{ fontSize: "10px" }}
                      ></i>{" "}
                      We will detect a issues in your grow business & solve it
                      by Marketing fundametals<b>(Digital Marketing)</b>.
                    </li>
                  </ul>
                </li>
                <li className="mb-2">
                  4. We will take your <b>Business online</b>.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Business;
