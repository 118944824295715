import React, { useState } from "react";
import { Helmet } from "react-helmet";

const EcommerceProductPhotography = () => {
  const [selectedItem, setSelectedItem] = useState({
    image:
      "https://cdn.shortpixel.ai/spai/q_glossy+w_527+to_webp+ret_img/www.powproductphotography.com/wp-content/uploads/2023/07/Product-Details-1-scaled.jpg",
    title: "Graphic Design",
    description:
      "We're thrilled to share that we now offer top-notch graphic design services. We have two fantastic options to cater to a variety of client and business needs. Take your pick between our pre-designed template design layout or a custom design layout. It's all about finding the perfect fit for your brand and budget!",
  });

  // Array of image objects with content
  const items = [
    {
      image: "./Images/PD.png",
      alt: "Graphic Design",
      title: "Graphic Design",
      description:
        "We're thrilled to share that we now offer top-notch graphic design services. We have two fantastic options to cater to a variety of client and business needs. Take your pick between our pre-designed template design layout or a custom design layout. It's all about finding the perfect fit for your brand and budget!",
    },
    {
      image: "./Images/PD1.png",
      alt: "Art Direction",
      title: "Art Direction",
      description:
        "Our team of creative professionals is here to assist your company with any art direction needs you may have for your listing images, ranging from product placement to graphics.",
    },
    {
      image: "./Images/PD2.png",
      alt: "Copywriting",
      title: "Copywriting",
      description:
        "We're more than just photography and design enthusiasts – we're skilled writers too! After perfecting the visuals, the final step is communicating your message through captivating copy. You can either provide us with your desired listing content or trust our creative team to work their magic. ",
    },
    {
      image: "./Images/PD3.png",
      alt: "Photography",
      title: "Photography",
      description:
        "We’ve got your product photography covered! We’ll give you a killer hero shot that’ll intrigue customers to your product, AND we’ll cover all the other images you need for a successful listing: additional angles, group shots, detail shots, hand modeling, and lifestyle shots.. what more could you need?",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Best eCommerce Product Photography Services in Ahmedabad 2024
        </title>
        <meta
          name="description"
          content="Enhance your online store with top eCommerce product photography in Ahmedabad, India. Boost sales with stunning images. Serving the UK, US, Canada, and Australia."
        />
        <meta
          name="keyword"
          content="eCommerce product photography services Ahmedabad 2024 |
best product photography for online stores Ahmedabad |
professional eCommerce product images Ahmedabad |
affordable eCommerce product photography Ahmedabad|
high-quality eCommerce product photography Ahmedabad | 
custom eCommerce product photo services Ahmedabad|
top eCommerce product photography company Ahmedabad |
eCommerce product image experts Ahmedabad |
best eCommerce product photography 2024 Ahmedabad |
professional product photography for eCommerce Ahmedabad"
        />
        <link
          rel="canonical"
          href="https://www.mannix.in/ecommerce-product-photography"
        />
      </Helmet>
      <div className="container-fluid m-0 p-0">
        <div className="py-4" style={{ background: "rgb(241 245 249)" }}>
          <div className="container">
            <div
              className="p-4  text-white rounded"
              style={{
                background:
                  "linear-gradient(to right, rgb(219, 21, 14), rgb(246, 229, 141))",
              }}
            >
              <p className="h6">Advanced Services</p>
              <div className="w-100 border border-dark"></div>
              <p className="h2 font-weight-bold">
                Amazon Product Photography Services
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row mx-3 mx-md-12">
          <div className="col-md-6 d-flex justify-content-center">
            <img
              src="./Images/AL.png"
              alt="Amazon Listing
Images"
              className="img-fluid"
            />
          </div>
          <div className="col-md-6">
            <div className="pt-4 pt-md-5">
              <p className="h3 font-weight-bold">
                We Create Amazon Listing <br /> Images That Shine!
              </p>
              <p className="h6 pt-4">
                Take your Amazon product listings to the next level with our
                Amazon Listing Packages.
              </p>
              <p className="pt-4">
                Packages include industry-leading product photography, graphic
                design, copywriting, and more. Let us be your one-stop shop for
                all your Amazon listing needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-md-block d-none m-2">
        {/* First row with one full-width image */}

        {/* Grid for the next two rows with two images per row */}
        <div className="d-flex flex-wrap gap-2">
          <img
            className="img-fluid rounded-lg"
            src="./Images/AL1.png"
            alt="Amazon Listing Images"
          />
          <img
            className="img-fluid rounded-lg"
            src="./Images/AL2.png"
            alt="Amazon Listing Images"
          />
          <img
            className="img-fluid rounded-lg"
            src="./Images/AL3.png"
            alt="Amazon Listing Images"
          />
          <img
            className="img-fluid rounded-lg"
            src="./Images/AL4.png"
            alt="Amazon Listing Images"
          />
          <img
            className="img-fluid rounded-lg"
            src="./Images/AL4.png"
            alt="Amazon Listing Images"
          />
        </div>
      </div>
      <div className="d-md-none m-2">
        {/* First row with one full-width image */}
        <div className="mb-4">
          <img
            className="img-fluid rounded-lg w-100"
            src="./Images/AL1.png"
            alt="Amazon Listing Images"
          />
        </div>

        {/* Grid for the next two rows with two images per row */}
        <div className="row g-2">
          <div className="col-6">
            <img
              className="img-fluid rounded-lg"
              src="./Images/AL2.png"
              alt="Amazon Listing Images"
            />
          </div>
          <div className="col-6">
            <img
              className="img-fluid rounded-lg"
              src="./Images/AL3.png"
              alt="Amazon Listing Images"
            />
          </div>
          <div className="col-6">
            <img
              className="img-fluid rounded-lg"
              src="./Images/AL4.png"
              alt="Amazon Listing Images"
            />
          </div>
          <div className="col-6">
            <img
              className="img-fluid rounded-lg"
              src="./Images/AL4.png"
              alt="Amazon Listing Images"
            />
          </div>
        </div>
      </div>
      <div class="p-4 bg-light">
        <p class="text-center fs-3 fw-semibold pb-4">
          Everything You Need For High Quality Listing Images
        </p>
        <div class="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-6 g-4 pt-3">
          <div class="col d-flex flex-column align-items-center">
            <p class="mt-2 text-center text-muted bg-gradient-custom rounded p-2 w-100">
              Project Coordinate
            </p>
            <img
              src="../Images/cordination.png"
              class="border border-dark p-md-5 p-3 rounded  img-fluid"
              alt="Project Coordination Icon"
            />
          </div>
          <div class="col d-flex flex-column align-items-center">
            <p class="mt-2 text-center text-muted bg-gradient-custom rounded p-2 w-100">
              Graphic Design
            </p>
            <img
              src="../Images/graphic.png"
              class="border border-dark p-3 p-md-5 rounded  img-fluid"
              alt="Graphic Design Icon"
            />
          </div>
          <div class="col d-flex flex-column align-items-center">
            <p class="mt-2 text-center text-muted bg-gradient-custom rounded p-2 w-100">
              Art Direction
            </p>
            <img
              src="../Images/art.png"
              class="border border-dark p-3 p-md-5 rounded  img-fluid"
              alt="Art Direction Icon"
            />
          </div>
          <div class="col d-flex flex-column align-items-center">
            <p class="mt-2 text-center text-muted bg-gradient-custom rounded p-2 w-100">
              Photography
            </p>
            <img
              src="../Images/photography.png"
              class="border border-dark p-3 p-md-5 rounded  img-fluid"
              alt="Photography Icon"
            />
          </div>
          <div class="col d-flex flex-column align-items-center">
            <p class="mt-2 text-center text-muted  bg-gradient-custom rounded p-2 w-100 bg-gradient-custom">
              Retouching
            </p>
            <img
              src="../Images/retouching.png"
              class="border border-dark p-3  p-md-5 rounded  img-fluid"
              alt="Retouching Icon"
            />
          </div>
          <div class="col d-flex flex-column align-items-center">
            <p class="mt-2 text-center text-muted bg-gradient-custom rounded p-2 w-100">
              Copywriting
            </p>
            <img
              src="../Images/copywriting.png"
              class="border border-dark p-3 p-md-5 rounded  img-fluid"
              alt="Copywriting Icon"
            />
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <div className="row">
          <div className="container mt-4">
            <div className="text-center mb-4">
              <p className="fs-3 fw-bold mb-4 text-decoration-underline">
                Your Complete Solution for Exceptional Listing Images
              </p>
            </div>

            <div className="row">
              {/* Left side image */}
              <div className="col-md-6 p-3">
                <img
                  src={selectedItem.image}
                  alt={selectedItem.alt}
                  className="img-fluid rounded-3"
                />
              </div>

              {/* Right side divs */}
              <div className="col-md-6 p-2">
                <div className="d-grid gap-2">
                  {items.map((item, index) => (
                    <div
                      key={index}
                      className={`card border rounded-3 cursor-pointer ${
                        selectedItem.image === item.image ? "bg-light" : ""
                      }`}
                      style={{
                        backgroundColor:
                          selectedItem.image === item.image ? "#F1F5F9" : "",
                      }}
                      onClick={() => setSelectedItem(item)} // Change image and content on click
                    >
                      <div className="card-body">
                        <h5 className="card-title fs-5 fw-bold">
                          {item.title}
                        </h5>
                        <p className="card-text">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EcommerceProductPhotography;
