import React, { useState } from "react";
import { Helmet } from "react-helmet";

const SericeLandingDevlopment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>Landing Page Design & Development in Ahmedabad</title>
        <meta
          name="description"
          content="Professional landing page design and development services in Ahmedabad. Mannix Infotech Pvt. Ltd. creates high-converting landing pages to boost your marketing efforts.
"
        />
        <meta
          name="keywords"
          content="Landing page design and development services in Ahmedabad | Best landing page development company in Ahmedabad | Professional landing page design services | High-converting landing page development agency | Landing page optimization services in Ahmedabad | Expert landing page design and development services | Top-rated landing page development services in Ahmedabad | Custom landing page design and development | Landing page development for lead generation | Ahmedabad landing page design and development experts 
"
        />
        <link
          rel="canonical"
          href="https://www.mannix.in/landingpage-website-development-services"
        />
      </Helmet>
      <div className="hire-dedicated services mt-0">
        <section className="banner-content-1">
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            className="position-absolute top-banner-bg"
            viewBox="0 0 1920 761"
          >
            <g>
              <defs>
                <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
              </defs>
              <clipPath id="SVGID_2_">
                <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
              </clipPath>
              <g className="banner-bg-asw">
                <path
                  className="st1"
                  d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                        c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
                ></path>
                <path
                  className="st2"
                  d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                        c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
                ></path>
                <path
                  className="st1"
                  d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
                ></path>
                <path
                  className="st2"
                  d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
                ></path>
                <circle
                  className="st1"
                  cx="1536.8"
                  cy="661.3"
                  r="56.2"
                ></circle>
                <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
              </g>
            </g>
          </svg>
          <div className="py-5 container">
            <div className="row align-items-center justify-content-around mt-4">
              <div className="col-lg-6">
                <div className="">
                  <h1 className="head-title head-title-without-line m-0 py-3">
                    “What is a landing page?”
                  </h1>
                  <p className="m-0 py-3">
                    When trying to sell your products, the landing page is
                    crucial. It all relies on what your website finder finds
                    there as to whether or not they become a new customer. Can
                    visitors to your landing pages become leads, or can they
                    result in a high rate of rejection? In this regard, our
                    professionals at Mannix Infotech Solutions Pvt.Ltd. are
                    dedicated to helping you and turning your landing pages into
                    a highly effective web development landing page that will
                    increase leads and conversions for your company.
                  </p>
                  <p className="m-0 py-3">
                    A landing page is a specific webpage on a website that is
                    accessed directly from an external source, such as an email.
                    It can be thought of as the highly targeted digital
                    equivalent of a marketing letter, with the aim of
                    stimulating a planned action by connecting with users
                    through targeted copy and convincing them to take necessary
                    action. Landing pages can be optimized for search engines to
                    generate organic search traffic, or they can be used in
                    conjunction with paid advertising through social media or
                    search engines.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 prod-section2">
                <div className="">
                  <form
                    className="register-form"
                    id="register-form"
                    method="post"
                    action=""
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="hidden"
                      name="form_type"
                      id="form_type"
                      value="Android App Development"
                    />
                    <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                      <div className="d-flex">
                        <div className="w-100 mr-2">
                          <label className="w-100">
                            <span>Name</span>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Name *"
                              className="form-control"
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                        <div className="w-100">
                          <label className="w-100">
                            <span>Email</span>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email id *"
                              className="form-control"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <label className="w-100">
                          <span>Contact</span>
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="Contact No *"
                            className="form-control"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Country</span>
                          <input
                            type="text"
                            name="country"
                            id="country"
                            placeholder="Enter your Country"
                            className="form-control"
                            value={formData.country}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Message</span>
                          <textarea
                            name="message"
                            id="message"
                            placeholder="Enter your message *"
                            className="form-control"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </label>
                      </div>

                      <div className="">
                        <button
                          type="submit"
                          name="submit"
                          className="submit btn w-100 g-recaptcha-btn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="container d-flex flex-wrap">
        <div className="col-md-6 col-sm-6 ">
          <div className="theme-title theme_web">
            <h3>
              Let stunning and interactive landing page benefit your business -
            </h3>
            <ul className="list-style-blt">
              <li>
                From individual startups to giant corporations, online success
                depends heavily on the ability of businesses to attract leads
                and establish a strong online presence. This is where the
                landing pages, which is the page on your site that your users
                first land on to get them to buy your required product.
              </li>
              <li>
                See your business website as the hub of all your online business
                activities. This is where you provide the vital information
                people need to become paying customers. This includes things
                like detailed information about a product or service, customer
                support vehicles, purchasing options, all of which are factors
                that drive visitors to convert.
              </li>
              <li>
                Landing pages are like the messengers that go out into the world
                and raise awareness of what you have to offer. The more complex
                your business operations, the more diverse your landing pages
                should be. That's why nearly 70% of B2B companies use them to
                generate new business leads.
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 p-4">
          <div className="theme-title theme_solution">
            <h4>
              Let's explore how these landing pages can assist you in building
              and expanding your company.
            </h4>
            <ul>
              <li>
                {" "}
                <strong>1. We will help you grab attention -</strong>You can
                possess the world's top goods or service. However, it is of no
                use to you or the general public if no one is aware of its
                existence. In order to move visitors to the top of the sales
                funnel, landing pages are essential.
              </li>
              <li>
                <strong>
                  2. We will strategically support business goals -
                </strong>
                Possibly the most significant advantage of landing pages is that
                they help you tailor your approach to suit entirely different
                objectives. You can create these websites to appeal to the most
                sensitive audience, whether you're closing sales, reaching a new
                market, or marketing a new good or service.
              </li>
              <li>
                <strong>
                  3. We will increase content marketing's efficacy -
                </strong>{" "}
                These days, content marketing is one of the hottest topics in
                business. Companies across all industries are investing far more
                in this space, and there are no signs of this trend slowing
                down.
              </li>
              <li>
                <strong>4. We will build your credibility -</strong> Credibility
                is more important in the online business world than money.
                Without it, opportunities are few and money is almost
                nonexistent. One of the most important aspects of communicating
                your USP and influencing purchasing decisions is your ability to
                demonstrate your domain knowledge.
              </li>
            </ul>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-sm-6 col-md-6">
            <div className="">
              {" "}
              <img
                className="img-fluid"
                src="../Images/landingpage.png"
                alt="landingpage"
              />{" "}
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="theme-title text-left">
              <h2>
                Our landing pages ought to instantly convey your authority.
              </h2>
              <p className="text-justify">
                Everyone knows that when it comes to swaying decisions,
                third-party validations—such as recommendations and internet
                reviews—are essential. In light of this, designing your landing
                page with a phrase or remark that provides positive
                reinforcement can be incredibly effective in building your
                authority. Your landing pages can be made more attractive by
                using social proof.
              </p>
              <p className="text-justify">
                Make careful to write clearly and succinctly if you decide to
                take this course. Your landing pages might display several
                testimonials if they are properly developed. A positive picture
                is also a wonderful addition.
              </p>
              <p className="text-justify">
                Another excellent strategy to increase the trustworthiness of
                your landing pages is personalization. Copy that works speaks to
                the reader directly. A user should be able to immediately
                connect with your page when they only take a quick look at it.
                Furthermore, he ought to be aware of their shortcomings and
                worries. Reaching this goal is essential to marketing. Your
                diligence in researching and your ability to take advantage of
                market trends are the reasons for all of this.
              </p>
              <p className="text-justify">
                For instance, several businesses saw a decline in their search
                engine ranks as a result of Google's most recent algorithm
                changes. Creating landing pages that address these issues and
                highlight distinctive solutions would be a fantastic method for
                an SEO company seeking new business to draw in viewers who are
                searching for solutions.
              </p>
              <p className="text-justify">
                In the era of the Internet, competition is more intense than
                ever. When things are going well, brands stand out because of
                their credibility. It must be delivered as soon and clearly as
                feasible when it comes to landing pages.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SericeLandingDevlopment;
