import React from "react";
import { Helmet } from "react-helmet";

const OurGallary = () => {
  return (
    <>
      <Helmet>
        <title>
          Gallery of Mannix Infotech Solutions Pvt. Ltd. | Digital Marketing
          Designs
        </title>
        <meta
          name="keywords"
          content="Digital marketing design inspiration gallery in Ahmedabad | Creative digital marketing agency portfolio examples in Ahmedabad | Innovative digital marketing solutions and services in Ahmedabad | Digital marketing design and development company in Ahmedabad | Successful digital marketing campaigns and solutions in Ahmedabad | Digital marketing project examples and case studies in Ahmedabad | Creative digital marketing design and development agency in Ahmedabad | Innovative digital marketing design and solutions company in Ahmedabad"
        />
        <meta
          name="description"
          content="Explore the gallery of Mannix Infotech Solutions Pvt. Ltd. to see our innovative digital marketing designs. Discover our creative solutions and successful project examples."
        />
        <link rel="canonical" href="https://www.mannix.in/gallery" />=
      </Helmet>

      <div>
        <div
          className=""
          style={{
            background: "url('../GallaryBanner.jpeg')no-repeat",
            // width: "100vw",
            backgroundSize: "cover",
            height: "290px",

            padding: "119px 0",
          }}
        >
          <div className="intern_heading2">
            <div className="container">
              <h1 className="gallery-head text-center">
                "A Gallery Of Artistry And Passion."
              </h1>
            </div>
          </div>
        </div>

        <div className="container mt-5 d-none d-md-block">
          <div className="aboutUs_large_title">
            <h2 className="aboutUs_large_culture">Mannix Family</h2>
          </div>
          <div className="ops_aboutUs_culture_content">
            <div className="col-md-12">
              <img
                className=" lazyloaded img-fluid"
                loading="lazy"
                // height="500px"
                // width="100%"
                src="../gallary.png"
                alt="gallary"
              />
              <h2 className="gallery-head pb-5">One for all. All for one!</h2>
            </div>
          </div>
        </div>

        <div className="container mt-5 d-md-none d-sm-block">
          <div className="aboutUs_large_title">
            <h2 className="aboutUs_large_culturesm">Mannix Family</h2>
          </div>
          <div className="ops_aboutUs_culture_content">
            <div className="col-md-12">
              <img
                className=" lazyloaded img-fluid"
                loading="lazy"
                // height="500px"
                // width="100%"
                src="../gallary.png"
                alt="gallary"
              />
              <h2 className="gallery-head pb-5">One for all. All for one!</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurGallary;
