import React, { useState } from "react";
import { Helmet } from "react-helmet";

const FlyerDesign = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>
          Best Custom Flyer Design Services in Ahmedabad | Mannix Infotech Pvt.
          Ltd.
        </title>
        <meta
          name="description"
          content="Leading custom flyer design services in Ahmedabad by Mannix Infotech Pvt. Ltd. We design eye-catching flyers to effectively promote your business and events."
        />
        <meta
          name="keyword"
          content="Flyer design services in Ahmedabad |
  Best flyer design company in Ahmedabad |
    BrFlyer design services provider in Ahmedabad |
  Expert flyer design services in Ahmedabad |
Top-rated flyer design services in Ahmedabad|
Flyer design services for startups|
Ahmedabad flyer design experts|
Flyer design solutions for businesses|	
Flyer design company in Ahmedabad with expertise in graphic design|
Flyer design services for marketing campaigns"
        />
        <link
          rel="canonical"
          href="https://www.mannix.in/flyer-design-services"
        />
      </Helmet>
      <section className="banner-content-1 pb-5">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className="position-absolute top-banner-bg"
          viewBox="0 0 1920 761"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
            </defs>
            <clipPath id="SVGID_2_">
              <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
            </clipPath>
            <g className="banner-bg-asw">
              <path
                className="st1"
                d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                            c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
              ></path>
              <path
                className="st2"
                d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                            c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
              ></path>
              <path
                className="st1"
                d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
              ></path>
              <path
                className="st2"
                d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
              ></path>
              <circle className="st1" cx="1536.8" cy="661.3" r="56.2"></circle>
              <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
            </g>
          </g>
        </svg>
        <div className="py-5 container">
          <div className="row align-items-center justify-content-around mt-4">
            <div className="col-lg-6">
              <div className="">
                <h1 className="head-title head-title-without-line m-0 py-3">
                  Flyer Design
                </h1>
                <p className="m-0 py-3">
                  Mannix Infotech Solutions Pvt.Ltd. provides innovative
                  creativity in Flyer Design Services and poster design services
                  that envision your brand statement distinctly. We apply our
                  innovation and creative thinking to produce you with an
                  excellent worth proposition in your posters and flyers to come
                  up with the right marketing appeal. Using these items is very
                  vital to making a good impression on the minds of target
                  customers. We must prepare them with the most professional
                  material to achieve the desired business results.
                </p>
              </div>
            </div>
            <div className="col-lg-5 prod-section2">
              <div className="">
                <form
                  className="register-form"
                  id="register-form"
                  method="post"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="form_type"
                    id="form_type"
                    value="Android App Development"
                  />
                  <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <label className="w-100">
                          <span>Name</span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div className="w-100">
                        <label className="w-100">
                          <span>Email</span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email id *"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label className="w-100">
                        <span>Contact</span>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Contact No *"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Country</span>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Message</span>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter your message *"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        name="submit"
                        className="submit btn w-100 g-recaptcha-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="phone_home_sec mt-5 mb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="text-center">
                {" "}
                <img
                  src="../Images/FlyerDesign.png"
                  alt="FlyerDesign"
                  width="80%"
                />{" "}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="p_s_home_text">
                <p className="animate__animated animate__bounce fs-1">
                  {" "}
                  Flyer <span> Design</span>{" "}
                </p>
                <p className="text-justify">
                  {" "}
                  Mannix Infotech Solutions Pvt.Ltd. provides innovative
                  creativity in poster and flyer design services that envision
                  your brand statement distinctly. We apply our innovation and
                  creative thinking to produce you with an excellent worth
                  proposition in your posters and flyers to come up with the
                  right marketing appeal. Using these items is very vital to
                  making a good impression on the minds of target customers. We
                  must prepare them with the most professional material to
                  achieve the desired business results.
                </p>
                <div className="pg_home_btn">
                  {" "}
                  <a href="/">Contact now</a>{" "}
                </div>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="hire-dedicated-2 container py-4">
          <div className="pb-4 text-center">
            <h2 className="head-title">Why hire us instead of others?</h2>
          </div>
          <div className="row justify-content-center">
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-user-circle"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">High-quality Flyer Printing</h2>
                    <p className="m-0 py-2">
                      Our company provide high quality flyer printing.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-user-circle"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-cube"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Quick turnaround time</h2>
                    <p className="m-0 py-2">
                      Our flyers are posted among a pair of days of proof
                      approval using the next-day delivery service.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-cube"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-chart-area"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Affordablet</h2>
                    <p className="m-0 py-2">
                      Studies show that printing leaflets, or flyers are more
                      profitable than other promotional channels For example,
                      DLM response rate statistics show that 48th of customers
                      respond to flyers, 47th respond to marketing, and 47th
                      answer TV ads.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-chart-area"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-palette"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Digital complements</h2>
                    <p className="m-0 py-2">
                      Printed materials can support digital media. For example,
                      a QR code can be linked to a web page to get more
                      information.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-palette"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-ad"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">
                      Ideal for events on short notice
                    </h2>
                    <p className="m-0 py-2">
                      Quickly broadcast your messages about your event or
                      meeting.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-ad"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-icons"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">
                      Success of the easy-to-measure flyer
                    </h2>
                    <p className="m-0 py-2">
                      QR codes, flyer numbers, discount codes will create it a
                      measurable form of promoting.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-icons"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="theme-title theme_solution padding-why2 ">
          <h4>Our expertise for:-</h4>
          <ul className="list-style-blt">
            <li>
              {" "}
              Custom flyer design{" "}
              <p className="text-justify">
                We provide the right animation and graphic illustration for your
                brand that explicitly details your business ideologies to your
                consumer segment. Our designed posters and flyers can adhere to
                the simplest practices of your vertical business and be enforced
                on artistic lines. We are a leading online professional company
                providing high-end flyers, and poster designs all over the
                world. We serve many clients. They are all happy with our
                services..
              </p>
            </li>
            <li>
              {" "}
              Hire a flyer designer{" "}
              <p className="text-justify">
                Creating a professional image is the most important reason for
                hiring a flyer designer. Designers speak the language of colour
                and imagination. A graphic designer can use their expertise to
                speak this message to your target market and make a creative
                brand that your customers will trust.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FlyerDesign;
